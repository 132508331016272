body {
  position: relative;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

.logged-in {

  .anonymous {
    display: none;
  }
}

img {
  display: block;
  margin: auto;

  &.file-icon {
    display: inline;
  }
}

.layout-swap__top {
  display: none;
}

.layout-swap {
  padding-top: 0;
}

.region-header {
  border-bottom: 1px solid color(blue);
  background: color(blue);

  .block {
    padding: 10px 5px;
    width: 100%;
    height: 57px;
    text-align: right;

    a,
    a:link,
    a:visited,
    a:hover {
      color: white;
      text-decoration: none;
      line-height: 1em;
      vertical-align: middle;
      font-size: .85em;
      display: inline-block;
      text-align: center;

      &.findjobs {
        display: none;
      }

      @media all and (min-width: 375px) {
        font-size: .875em;
      }
    }

    a:hover {
      color: #f9f9f9;
      text-decoration: underline;
      border-color: #f9f9f9;
    }

    .signin {
      white-space: nowrap;
      padding-right: 15px;

      span {
        display: none;
      }

      @media all and (min-width: 480px) {

        span {
          display: inline;
          padding-right: 5px;
        }
      }
    }

    .employers {
      padding-right: 5px;

      span {
        display: none;
      }

      @media all and (min-width: 375px) {
        padding-right: 10px;

        span {
          display: inline;
          padding-right: 5px;
        }
      }
    }

    .logo {
      width: 110px;
      height: 35px;
      background-image: url('../images/logo-white.png');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 100px 35px;
      float: left;
    }

    .trial {
      border: 1px solid white;
      padding: 3px;
      width: 67px;
      border-radius: 3px;

      &:hover {
        text-decoration: none;
        background: rgba(255, 255, 255, .2);
      }
    }
  }

  @media all and (min-width: 430px) {

    .block {

      .top-right,
      .top-right:link,
      .top-right:visited,
      .top-right:hover {
        padding: 0 10px;
        width: auto;
        margin-top: 5px;
      }

      .trial,
      .trial:link,
      .trial:visited,
      .trial:hover {
        padding: 5px;
        // margin-left: 15px;
      }
    }
  }

  @media all and (min-width: 560px) {

    .block {
      padding: 8px 10px 10px;

      a,
      a:link,
      a:visited,
      a:hover {
        font-size: .875em;
      }

      .top-right,
      .top-right:link,
      .top-right:visited,
      .top-right:hover {
        margin-top: 8px;

        &.findjobs {
          display: inline-block;
        }
      }

      .logo {
        width: 130px;
        height: 40px;
        background-size: 130px 40px;
      }
    }
  }

  @media all and (min-width: 1024px) {

    .block {
      height: 72px;
      padding: 10px;

      .top-right,
      .top-right:link,
      .top-right:visited,
      .top-right:hover {
        font-size: 1em;
        margin-top: 3px;
        vertical-align: middle;
      }

      .trial,
      .trial:link,
      .trial:visited,
      .trial:hover {
        white-space: nowrap;
        padding: 5px 10px;
        line-height: 32px;
        border-width: 2px;
      }

      .logo {
        // width: 160px;
        // height: 48px;
        // background-size: 160px 48px;
        // float: left;
        margin: 6px;
      }
    }
  }
}

.layout-center,
.header {
  z-index: 10;
}

.region-highlighted {
  z-index: -10;
}

.page-node-7,
.front {

  .region-bottom {
    margin-top: 0;
  }

  h1 {
    display: none;
  }

  .layout-center {
    max-width: 100%;
    padding: 0;
    margin: 0;

    .layout-3col {
      margin: 0;
      padding: 0;

      .layout-3col__full {
        padding: 0;
      }
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .region-header {
    background: none;
    border: 0;

    .block {
      background: none;
      border: 0;

      a,
      a:link,
      a:visited,
      a:hover {
        color: white;
      }

      .logo {
        background-image: url('../images/logo-white.png');

        @media all and (min-width: 1024px) {
          width: 202px;
          height: 60px;
          background-size: 202px 60px;
          margin: 0;
        }
      }

      .trial {
        border-color: white;
      }
    }
  }
}

.region-hero {
  position: relative;
}

.views-field-field-hero-desktop {
  display: none;
}

@media all and (min-width: 480px) {

  .views-field-field-hero-mobile {
    display: none;
  }

  .views-field-field-hero-desktop {
    display: block;
  }
}

.block__title {
  font-size: 24px;

  @media all and (min-width: 375px) {
    font-size: 32px;
  }
}

#block-block-5 {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  text-align: center;
  background: none;

  @media all and (min-width: 366px) {
    top: 57%;
  }

  @media all and (min-width: 413px) {
    top: 55%;
  }

  @media all and (min-width: 461px) {
    top: 52%;
  }

  @media all and (min-width: 480px) {
    top: 80%;
  }

  @media all and (min-width: 520px) {
    top: 75%;
  }

  @media all and (min-width: 580px) {
    top: 50%;
    margin-top: 60px;
  }

  a,
  a:link,
  a:visited,
  a:hover {
    // color: white;
    background: color(yellow);

    &::before {
      display: none;
      content: '\f0f6';
      font-family: FontAwesome;
      padding-right: 5px;
    }
  }
}

#block-views-exp-nonprofit-jobs-page,
#block-views-exp-nonprofit-jobs-index-page,
.local-jobs .view-filters form {
  position: absolute;
  top: 25%;
  left: 2%;
  width: 96%;
  z-index: 10;

  @media all and (min-width: 480px) {
    top: 15%;
  }

  @media all and (min-width: 580px) {
    top: 50%;
    margin-top: -100px;
  }

  .block__title {
    color: white;
    text-align: center;
    margin-bottom: 1rem;

    @media all and (min-width: 375px) {
      margin-bottom: 1.5rem;
    }

    @media all and (min-width: 480px) and (max-width: 580px) {
      margin-bottom: .5rem;
    }
  }

  #edit-secondary-wrapper {
    display: none;
  }

  .form-autocomplete {
    background: #fff;
  }

  .views-exposed-widgets {
    text-align: center;
    width: 300px;
    margin: auto;
  }

  .views-exposed-widget {
    padding: 0;
    border-radius: 0;

    input[type='text'],
    input[type='textarea'],
    input[type='submit'] {
      -webkit-appearance: none;
      border-radius: 0;
      margin: 0;
    }

    &.views-widget-filter-combine input,
    &.views-widget-filter-search_api_views_fulltext input {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &.views-widget-filter-field_location_latlon input {
      border-bottom-left-radius: 3px;
    }

    &.views-submit-button input {
      border-bottom-right-radius: 3px;
    }

    @media all and (min-width: 580px) {

      &.views-widget-filter-combine input,
      &.views-widget-filter-search_api_views_fulltext input {
        border-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.views-widget-filter-field_location_latlon input {
        border-radius: 0;
      }

      &.views-submit-button input {
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .views-exposed-form .views-exposed-widget .form-submit {
    margin: 0;
    border: 0;
    background-color: color(orange);
    background-image: url('../images/search.png');
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 50px;
    line-height: 50px;
    text-indent: -999em;
    width: 50px;

    &#edit-reset {
      background: none;
      text-indent: 0;
      display: none;
    }
  }

  input.form-text {
    line-height: 50px;
    height: 50px;
    border: 0;
    box-shadow: 0;
    padding: 0 10px;
    margin: 0;
    width: 300px;
    display: inline-block;

    &#edit-location {
      width: 250px;
      border-top: 1px solid #ddd;
      // border-left: 1px solid #ddd; for linear layout
    }
  }

  @media all and (min-width: 375px) {

    .views-exposed-widgets {
      width: 350px;
    }

    input.form-text {
      width: 350px;

      &#edit-location {
        width: 300px;
      }
    }
  }

  @media all and (min-width: 580px) {

    .views-exposed-widgets {
      width: 550px;
    }

    input.form-text {
      width: 300px;

      &#edit-location {
        width: 200px;
        border-left: 1px solid #ddd;
        border-top: 0;
      }
    }
  }

  @media all and (min-width: 700px) {

    .views-exposed-widgets {
      width: 650px;
    }

    input.form-text {
      width: 350px;

      &#edit-location {
        width: 250px;
      }
    }
  }
}

.page-nonprofit-jobs-search,
.page-nonprofit-jobs-search2,
.section-users,
.section-user,
.page-taxonomy-term,
.page-nonprofit-jobs.page-views {

  .region-hero {
    margin: 1rem auto;
  }

  #block-views-exp-nonprofit-jobs-page,
  #block-views-exp-nonprofit-jobs-index-page,
  .local-jobs .view-filters form {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    margin-top: 0;

    .filters {
      position: absolute;
      left: 50%;
      bottom: -32px;
      margin-left: -145px;

      @media all and (min-width: 375px) {
        margin-left: -170px;
      }

      @media all and (min-width: 580px) {
        margin-left: -270px;
      }

      @media all and (min-width: 700px) {
        margin-left: -320px;
        bottom: -39px;
      }

      &::before {
        content: '\f1de';
        font-family: FontAwesome;
        padding-right: 5px;
      }

      &.open {
        color: color(orange);

        &::before {
          content: 'X';
          font-family: inherit;
        }
      }
    }

    .views-exposed-widgets {
      position: relative;

      @media all and (min-width: 700px) {
        width: 100%;
        text-align: center;

        .views-widget-filter-combine,
        .views-widget-filter-search_api_views_fulltext,
        .views-widget-filter-field_location_latlon,
        .views-submit-button {
          float: none;
          position: relative;
          display: inline-block;
          left: auto;
          top: auto;
        }

        .views-widget-filter-field_location_latlon,
        .views-submit-button {
          margin-left: -4px;
        }
      }
    }

    .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
    .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
    .views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
      position: absolute;
      top: 50px;
      left: 250px;

      @media all and (min-width: 375px) {
        left: 300px;
      }

      @media all and (min-width: 580px) {
        left: 500px;
        top: 0;
      }

      @media all and (min-width: 700px) {
        position: relative;
        left: auto;
        top: 5px;
      }
    }

    .views-exposed-form .views-exposed-widget .form-submit#edit-reset {
      display: block;
      position: absolute;
      top: 112px;
      right: 10px;
      width: auto;
      height: auto;
      line-height: 1em;
      margin-left: -20.5px;
      color: color('grey');
      padding: 0;
      // z-index: -10;

      @media all and (min-width: 580px) {
        top: 62px;
      }

      @media all and (min-width: 700px) {
        top: 73px;
        right: calc(50% - 315px);
      }
    }

    #edit-secondary-wrapper {
      display: block;
      text-align: center;
      width: 300px;
      margin-top: .5rem;
      font-size: .875rem;
      background: white;

      .fieldset-wrapper {
        background: #f7f7f7;
        padding: 20px;
        border: 1px solid color(grey-extra-light);
        text-align: center;

        .bef-secondary-options {
          text-align: left;
        }
      }

      .form-checkboxes,
      .bef-select-as-links {
        padding-top: 10px;
      }

      .bef-secondary-options .form-item .form-item .form-item,
      .bef-checkboxes .form-item {
        display: inline-block;
        width: auto;
        padding: 1px;
        margin: 5px 0;

        a:link,
        a:visited,
        a:hover,
        a.active {
          background: white;
          padding: 5px 10px;
          border-radius: 20px;
          color: color('grey');
        }

        a.active,
        a:hover {
          background: color(darkblue);
          color: white;
        }
      }

      .bef-checkboxes .form-item {
        padding: 5px 10px;
        background: white;
        border-radius: 20px;
        color: color('grey');
        cursor: pointer;

        input[type='checkbox'] {
          display: none;
        }

        label {
          cursor: pointer;
        }

        &.highlight {
          background: color(blue);
          color: white;

          label {
            color: white;
          }
        }
      }

      @media all and (min-width: 375px) {
        width: 350px;
      }

      @media all and (min-width: 580px) {
        width: 550px;
      }

      @media all and (min-width: 700px) {
        width: 90%;
        position: absolute;
        top: 60px;
        left: 5%;

        .applyfilter:nth-child(2) {
          display: none;
        }
      }

      legend {
        display: block;
        width: 300px;
        font-size: 1rem;
        text-align: left;
        // background: white;
        z-index: 10;
        margin-left: 0;
        padding: 0;
        margin-left: calc(50% - 150px + 10px);

        @media all and (min-width: 375px) {
          width: 350px;
          margin-left: calc(50% - 175px + 10px);
        }

        @media all and (min-width: 580px) {
          width: 550px;
          margin-left: calc(50% - 275px + 10px);
        }

        @media all and (min-width: 700px) {
          width: 650px;
          margin-left: calc(50% - 325px + 10px);
        }

        .fieldset-legend {
          display: block;
        }
      }

      #edit-secondary {
        border: 0;
        padding: 0;
      }

      .bef-secondary-options > .form-item {
        margin-bottom: 1.5rem;

        > label {
          border-bottom: 1px solid color(grey-light);
          padding-bottom: 3px;
          margin-bottom: 5px;
        }

        .form-item {

          a,
          a:link,
          a:visited,
          a:hover {
            text-decoration: none;
            padding: 2px 5px;
          }

          a.active,
          a:hover {
            background: color(darkblue);
            color: white;
            margin-left: 5px;
          }
        }
      }

      .applyfilter {
        text-decoration: none;
        font-size: 1rem;
        color: color(orange);
        width: 95px;
        display: block;
        margin: auto;
      }

      .collapsible .fieldset-legend {
        background: none;
        padding: 0;
      }

      .collapsible {

        .fieldset-title {
          text-decoration: none;
          color: color(orange);
          white-space: nowrap;

          &::before {
            content: 'Apply';
            padding-right: 3px;
            display: inline-block;
          }
        }
      }

      .collapsible.collapsed {

        .fieldset-title {
          color: color(darkblue);
          background: none;

          &::before {
            content: '\f1de';
            font-family: FontAwesome;
          }
        }

        legend::after {
          display: none;
        }
      }
    }

    .form-text,
    input#edit-location.form-text {
      border: 1px solid color(grey-light);

      @media all and (min-width: 580px) {
        border-right: 0;
      }
    }

    input#edit-combine.form-text,
    input#edit-keyword.form-text {
      border-bottom: 0;

      @media all and (min-width: 580px) {
        border-bottom: 1px solid color(grey-light);
      }
    }

    .block__title {
      display: none;
    }
  }
}

.section-users,
.section-user {

  #block-views-exp-nonprofit-jobs-page,
  #block-views-exp-nonprofit-jobs-index-page {

    .views-reset-button {
      display: none;
    }
  }
}


.page-nonprofit-jobs-search,
.page-nonprofit-jobs-search2,
.page-taxonomy-term,
.page-nonprofit-jobs.page-views {

  .region-highlighted {
    background: #f7f7f7;
    padding: 20px;

    .block-facetapi {
      padding: 10px 0;
      font-size: .875rem;

      .block__title {
        border-bottom: 1px solid #ccc;
        padding-bottom: 3px;
        margin-bottom: 5px;
        margin-top: 0;
        font-size: 1rem;
        font-weight: 600px;
        text-align: left;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          display: inline-block;
          width: auto;
          padding: 1px;
          margin: 5px 0;

          a,
          a:link,
          a:visited,
          a:hover {
            padding: 5px 10px;
            background: white;
            border-radius: 20px;
            color: #999;
            display: inline-block;
            font-size: .875rem;
          }

          a:hover {
            color: white;
            background: color(blue);
          }

          &.active {
            background: color(blue);
            color: white;
            border-radius: 20px;
            position: relative;
            padding: 5px 10px;

            a,
            a:link,
            a:visited,
            a:hover {
              display: inline-block;
              background: none;
              z-index: 1;
              position: absolute;
              padding: 0;
              display: block;
              color: white;
              font-size: 0;
              color: transparent;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .view-empty {
    background: white;
    position: relative;

    .messages {
      min-height: 110px;
      box-sizing: border-box;
      position: relative;

      .dismiss {
        display: none;
      }
    }
  }

  .layout-center,
  .layout-3col__full,
  .layout-3col {
    padding: 0;
    margin: 0 auto;
  }

  .layout-center {
    max-width: 1300px;
    padding-top: 30px;
  }

  h1 {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .breadcrumb {
    display: none;
  }

  .view-nonprofit-jobs,
  .view-nonprofit-jobs-index,
  .view-area-of-focus-taxonomy,
  .view-job-category-taxonomy,
  .local-jobs {

    .view-header {
      text-align: center;
      padding-bottom: 1rem;
    }

    .view-content {
      float: none;
      border-top: 1px solid color(grey-extra-light);
    }

    .attachment {
      display: none;
      width: calc( 100% - 320px );
      padding: 10px;

      h2 {
        margin: 0;

        a,
        a:link,
        a:visited,
        a:hover {
          color: black;
        }
      }
    }

    @media all and (max-width: 767px) {

      .view-content {
        float: none;
      }
    }

    @media all and (min-width: 768px) {

      .attachment {
        background: color(grey-extra-light);

        .view-nonprofit-jobs,
        .view-nonprofit-jobs-index,
        .local-jobs {
          padding: 20px 10px 30px;
          background: white;
          border: 1px solid color(grey-light);
        }

        header {
          margin: 0 0 0 110px;
        }

        .view-org-logo-for-job-listing {
          margin-top: -30px;
        }
      }

      .view-content,
      .item-list {
        width: 320px;
        float: left;
      }

      > .attachment {
        float: right;
        display: block;

        .view-content {
          float: none;
          border: 0;
          width: 100%;
        }

        .node-job-posting {
          display: none;
        }
      }
    }

    @media all and (min-width: 1024px) {

      .attachment {
        width: calc( 100% - 400px );
      }

      .view-content,
      .item-list {
        width: 400px;
      }
    }

    h1 {
      margin-bottom: 10px;
    }

    .views-row {
      line-height: 1.3rem;
      font-size: .875rem;

      .node-job-posting {
        font-size: 1rem;
        line-height: 1.5rem;

        @media all and (min-width: 770px) and (max-width: 980px) {

          .callstoaction {
            float: none;
            clear: both;
          }

          .keyinfo {
            width: 100%;
            float: none;
          }
        }

        .views-row {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }

      .job,
      .job:link,
      .job:visited,
      .job:hover {
        color: black;
        display: block;
        cursor: pointer;
        z-index: 0;
        padding: 10px;
        border-bottom: 1px solid color(grey-extra-light);
      }

      .job:hover,
      .job.active {
        // background: color(grey-extra-light);
        background: #f1f1f1;
      }

      .job.active {
        border-right: 0;
      }

      &.views-row-last {
        border: 0;
      }

      .job-title {
        color: color(darkblue);
        font-size: 1rem;
      }

      .logo {
        border: 1px solid color(grey-extra-light);
        background: white;
        width: 80px;
        height: 80px;
        float: left;
        margin: 0 10px 20px 0;
        font: 0/0 a;
        position: relative;
        border-radius: 18px;
        box-sizing: border-box;
        overflow: hidden;

        .org-initial {
          font-size: 40px;
          font-family: 'Open Sans', sans-serif;
          position: absolute;
          width: 78px;
          height: 78px;
          line-height: 78px;
          top: 0;
          left: 0;
          color: white;
          text-align: center;
          background: color(orange);
          border-radius: 18px;

          &:nth-of-type(odd) {
            background: color(orange);
          }

          &:nth-of-type(even) {
            background: color(blue);
          }

          &:nth-of-type(3),
          &:nth-of-type(6),
          &:nth-of-type(9),
          &:nth-of-type(12),
          &:nth-of-type(15),
          &:nth-of-type(18),
          &:nth-of-type(21),
          &:nth-of-type(24) {
            background: color(yellow);
          }
        }

        &::before {
          content: ' ';
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }

        .logo-img {
          max-width: 100%;
          width: 100%;
          height: auto;
          max-height: 100%;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: middle;
          font: 16px/1 Arial sans-serif;
        }

        img {
          max-height: 78px;
          max-width: 78px;
          width: auto;
        }
      }

      .job-location {
        color: color(grey-dark);
      }

      .job-date {
        color: color('grey');
        font-size: .75rem;
        float: right;
      }

      .job-type {
        color: color('grey');
        font-size: .75rem;
      }

      .easy-apply-1 {
        color: black;
        padding: 0 3px;
        font-size: .75rem;
        float: right;
        margin-left: 10px;
      }

      .easy-apply-2 {
        display: none;
      }
    }
  }
}

#autocomplete {
  li {
    cursor: pointer;
    padding: 5px 10px;
    text-align: left;

    a,
    a:link,
    a:visited {
      color: color(black);
      text-decoration: none;
      text-align: left;
    }

    a:hover {
      color: white;
      background: color('orange');
    }
  }
}

.autocomplete,
.form-autocomplete,
div.autocomplete-deluxe-multiple {
  border: 1px solid #ddd;
  box-shadow: 0;
  background-color: white;
}

.autocomplete-deluxe-container {
  background-color: white;
}

.autocomplete-deluxe-item-delete {
  background: none;
  width: 0;
  right: 10px;
  top: 2px;
  text-decoration: none;

  &::before {
    content: 'x';
    color: color(grey-dark);
    width: 12px;
    display: block;
    font-size: 12px;
    text-decoration: none;
  }
}

#edit-field-how-to-apply {
  display: none;
}

#block-system-help {
  max-width: 740px;
  margin: auto;
  text-align: center;
}

.node-form {

  .form-managed-file {
    background: white;
    padding: 15px 5px 5px;
    max-width: 600px;
  }

  tr.draggable td {
    padding: 5px;
  }

  .showcover {
    cursor: pointer;
  }

  .form-radios {

    .form-type-radio {
      border: 1px solid transparent;
      padding: 3px;
      border-radius: 3px;

      &.highlight {
        border-color: color(blue);
      }
    }
  }

  #edit-field-cover-photo-template {

    .form-type-radio {
      display: inline-block;
      width: 210px;
      margin: 2px;

      input {
        display: none;
      }
    }
  }

  .description {
    color: color('grey');
    font-size: .875rem;
  }

  .form-wrapper {
    margin: 2em 0;
  }
}

.form-field-type-text-long .filter-wrapper {
  padding: 0;
}

form .field-multiple-table tbody .draggable.odd {
  background-color: white;
}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

.field-add-more-submit,
.field-add-more-submit:link,
.field-add-more-submit:visited,
.field-add-more-submit:hover {
  background: none;
  color: color(darkblue);
  border: 0;

  &::before {
    content: '+';
    display: inline;
  }
}

#job-posting-node-form {

  .form-item-title label::after {
    content: 'Include the short and official title of this position like "Executive Director"';
    font-size: .875rem;
    color: color('grey');
    font-weight: normal;
    display: block;
  }
}

.flag-save-job {
  float: right;

  .flag-throbber {
    display: none;
  }

  .flag {
    font-size: 0;
    visibility: hidden;
    display: block;
    position: relative;
    width: 30px;
    height: 30px;

    &::before {
      content: '\f08a';
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;
      font-family: FontAwesome;
      color: color(orange);
      display: block;
      width: 30px;
      font-size: 1rem;
      height: 30px;
      text-align: center;
      line-height: 30px;
      text-indent: 0;
    }
  }

  .flag.unflag-action::before {
    content: '\f004';
  }
}

.node-job-posting {

  margin: auto;
  max-width: 700px;

  .callstoaction {
    width: 100%;
    display: block;
    text-align: center;
    clear: both;
    margin: 20px 0;
  }

  .links .flag-save-job {
    display: none;
  }

  .view-job-application-button {
    display: inline-block;
    margin: 0 5px;
    vertical-align: top;

    .button {
      margin: 0;
    }
  }

  #howapply {
    display: none;
  }

  .flag-outer-save-job {
    display: inline-block;
    margin: 0 5px;
    padding-top: 1px;
    vertical-align: top;
  }

  .flag-save-job {
    float: none;
    display: inline-block;

    .flag,
    .flag:link,
    .flag:visited,
    .flag:hover {
      visibility: visible;
      display: block;
      border: 1px solid color(orange);
      border-radius: 3px;
      padding: 5px 8px 5px 25px;
      width: auto;
      vertical-align: middle;
      line-height: 24px;
      height: 34px;
      font-size: 1rem;
      color: color(orange);

      &::before {
        top: 2px;
      }
    }
  }

  .field-name-field-brief-description {
    clear: both;
  }

  .field-type-text,
  .field-type-list-text {

    .field-items {

      .field-item {

        &::before {
          content: '\2022';
          color: color(orange);
          padding-right: 5px;
          padding-left: 10px;
        }
      }
    }
  }

  .field-type-taxonomy-term-reference {

    .field-items {
      padding-top: 10px;
    }
  }

  .field .field-label {
    font-size: 24px;
    font-weight: 600;
    color: color(orange);
    margin-top: 2rem;
    margin-bottom: .5rem;
  }

  .field-name-field-type-of-employment {

    .field-items {
      padding-top: 0;
    }
  }

  .view-org-logo-for-job-listing {
    float: left;
    width: 100px;
    height: 100px;
    margin: 0 10px 10px 0;
    overflow: hidden;

    .views-row .logo {
      height: 100px;
      width: 100px;
      border-radius: 25px;
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      font: 0/0 a;
      border: 1px solid #f3f3f3;

      &::before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }

      .logo-img {
        max-width: 100%;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        font: 16px/1 Arial sans-serif;

        img {
          max-height: 98px;
          max-width: 98px;
          width: auto;
          display: block;
        }
      }

      .org-initial {
        display: inline-block;
        height: 98px;
        width: 98px;
        line-height: 98px;
        border-radius: 25px;
        font-size: 70px;
        font-family: 'Open Sans', sans-serif;
        color: white;
        text-align: center;
        background: color(orange);
        border-radius: 18px;

        &:nth-of-type(odd) {
          background: color(orange);
        }

        &:nth-of-type(even) {
          background: color(blue);
        }

        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(9),
        &:nth-of-type(12),
        &:nth-of-type(15),
        &:nth-of-type(18),
        &:nth-of-type(21),
        &:nth-of-type(24) {
          background: color(yellow);
        }
      }
    }

    img,
    a {
      display: block;
    }
  }

  @media all and (min-width: 500px) {

    .callstoaction {
      float: right;
      width: auto;
      margin-top: 0;
      clear: none;
    }
  }

  @media all and (min-width: 660px) {

    .keyinfo {
      float: left;
      width: calc( 100% - 220px );
    }
  }

  .field-name-field-occupational-category::before {
    content: 'Similar Jobs Nationwide';
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #e96803;
    margin-top: 2rem;
    margin-bottom: .5rem;
  }
}

.page-views .node-job-posting .view-org-logo-for-job-listing .views-row .logo .logo-img img {
  max-width: 78px;
  max-height: 78px;
}

.listing-stats {
  background: color(grey-extra-light);
  padding: 20px;
  margin-top: 40px;
  text-align: center;

  h3 {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 24px;

    &::after {
      content: 'Come back later to see live stats like visits,\00a0 saves,\00a0 and applications received.';
      font-size: .875rem;
      color: color('grey');
      font-weight: 400;
      display: block;
    }
  }

  .field-name-field-views,
  .view-saves-per-job,
  .view-job-applications-count,
  .field {
    width: auto;
    margin: 5px;
    background: color(grey-light);
    padding: 5px;
    display: inline-block;

    .field-label,
    .field-items {
      font-size: 1rem;
      margin: 0;
      color: black;
      display: inline-block;
      font-weight: 400;
      padding: 0 10px;
    }

    .field-items {
      background: color(grey-extra-light);
    }
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  padding: 0;

  a,
  a:link,
  a:visited,
  a:hover {
    padding: .25em;
    color: color(darkblue);
  }

  a:hover {
    color: white;
    background: color(darkblue);
  }
}

.node-type-job-application {

  ul.tabs {
    display: none;
  }
}

.node-job-application {
  margin: auto;
  max-width: 740px;
  padding: 0;

  .comment-form {

    #edit-author--2 {
      display: none;
    }
  }

  article.comment {
    padding: 10px 10px 5px 10px;
    margin-bottom: 30px;
    background: #f3f3f3;
    border-radius: 20px;
    position: relative;
    margin-left: 10px;
    margin-right: 50px;

    &::before {
      position: absolute;
      bottom: 20px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #f3f3f3;
    }

    &.comment-by-node-author {
      background: #ff3f3f3;
    }

    &.comment-by-viewer {
      background: color(blue);
      color: white;
      margin-left: 50px;
      margin-right: 10px;

      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        bottom: 20px;
        left: 100%;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid color(blue);
      }


      a,
      a:link,
      a:visited,
      a:hover {
        color: white;
        text-decoration: underline;
      }

      .username {
        visibility: hidden;
        font-size: 0;

        &::before {
          content: 'You';
          padding-right: 1px;
          visibility: visible;
          font-size: 1rem;
        }
      }
    }

    .submitted {
      margin: 0;
    }

    .comment__title,
    .links.inline,
    .comment__permalink {
      display: none;
    }

    .field-name-comment-body {
      padding: 0;
      background: none;
    }
  }
}

ul.tabs {
  margin-top: 0;
  text-align: left;
  width: auto;
  background: none;
  border: 0;
  position: fixed;
  bottom: 3px;
  left: 3px;
  z-index: 100;
  padding-right: 150px;

  &.tabs--secondary {
    position: relative;
    left: auto;
    top: auto;
  }

  .tabs__tab {
    float: left;
    background: none;
    margin: 3px 3px 0;
    border: 0;
    border-radius: 0;
    display: inline-block;

    &.replicate {
      display: none;
    }
  }

  .tabs__tab.is-active {
    border: 0;
    display: none;
  }

  .tabs__tab-link,
  .tabs__tab-link:link,
  .tabs__tab-link:visited,
  .tabs__tab-link:hover {
    color: color(white);
    background: color(orange);
    padding: 0 10px;
    letter-spacing: 0;
    transition: 0;
    text-shadow: none;
    border-radius: 3px;
    display: block;
    border: 1px solid white;
  }

  .tabs__tab-link:hover {
    background: color(blue);
  }

  .tabs__tab-link.is-active,
  .tabs__tab-link--is-active {
    background: none;
    display: none;
  }
}

.user-is-author.node-type-job-posting ul.tabs .tabs__tab.replicate {
  display: inline-block;
}

.page-node h1 {
  margin-bottom: 10px;
}

.page-node main h1::before {
  color: color(grey-light);
  font-size: .875rem;
  text-align: center;
  display: block;
  content: '';
  font-weight: 400;
  line-height: 1em;
}

.node-type-job-application  main h1::before {
  content: 'Job Application';
}

.node-type-job-posting  main h1::before {
  content: 'Job Listing';
}

.messages button.dismiss {
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  margin: 0;
  top: .2em;
  right: .2em;
  background: none;
  border-radius: 0;

  &::before {
    color: black;
    text-shadow: 0;
  }
}

.messages,
.crm-block .help,
.crm-block #help,
.crm-container div.messages {
  border: 0;
  padding: 20px;
  max-width: 600px;
  width: auto;
  display: block;
  margin: 1.5rem auto;

  h3 {
    margin: 0;
  }

  &.messages--status {
    background: #bce4de;
  }

  &.messages--error {
    background: color(error-bg);
  }

  &.messages--warning {
    background: #ffe79d;
  }

  .messages__icon {
    display: none;
  }
}

.crm-container div.messages {

  .icon,
  .inform-icon {
    display: none;
  }

  &.status {
    background: #ffe79d;
  }
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background: #bce4de;
  color: black;
}

#colorbox {
  background: white;
  overflow: visible !important;

  header.header {
    display: none;
  }

  main h1 {
    margin: 20px 0 0;
    font-size: 24px;

    &::before {
      display: none;
    }
  }

  #user-login,
  #user-register-form,
  #user-pass {
    padding-top: 170px;
    background: none;

    &::before {
      content: 'Sign into your account';
      font-size: 24px;
      font-weight: 600;
      color: color(orange);
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 20px;
      left: 0;
    }

    .hybridauth-widget-wrapper {
      top: 70px;
    }
  }

  #user-register-form::before {
    content: 'Create a new account';
  }

  #user-pass {
    padding-top: 40px;

    &::before {
      content: 'Reset your password';
    }
  }

  .node-job_application-form {
    padding: 20px 20px 40px;

    &::before {
      content: 'Submit your job application';
      font-size: 24px;
      font-weight: 600;
      color: color(orange);
      display: block;
      text-align: center;
    }

    #edit-field-job {
      display: none;
    }

    .form-wrapper {
      margin: 1rem 0 0;
    }

    #edit-field-email-und-0-email {
      max-width: 274px;
    }

    > div:first-of-type::before {
      display: block;
      width: 100%;
      content: 'Please fill out the form below and we\'ll send your job application straight to the organization’s recruiting team.';
      text-align: center;
      margin-bottom: 10px;
      margin-top: 5px;
      color: color('grey');
      font-size: .875rem;
    }

    #edit-field-first-name,
    #edit-field-last-name {

      @media all and (min-width: 480px) {
        display: inline-block;
        width: 49%;

        .form-item {
          margin: 0;
        }
      }
    }
  }
}

body #job-application-node-form {

  #edit-field-source {
    display: none;
  }

  .form-wrapper.form-field-name-field-resume {
    background: color(grey-extra-light);
    margin-top: -20px;
    padding: 10px;

    .form-item {
      margin-top: 0;
    }
  }

  .form-field-name-field-resume-on-file {

    .description {

      p {
        margin: 10px 0 0;
      }
    }
  }

  .replace-resume {
    cursor: pointer;
  }
}

.logged-in {

  #job-application-node-form {

    .form-wrapper.form-field-name-field-resume {
      display: none;

      label::before {
        content: 'New';
        padding-right: 3px;
      }
    }
  }
}

.not-logged-in {

  #job-application-node-form {

    .form-wrapper.form-field-name-field-resume {
      margin-top: 20px;
    }
  }
}

#howapply-inner {
  margin: auto;
  max-width: 500px;
  padding: 20px;

  h3 {
    margin-bottom: .5em;
    margin-top: .5rem;
    color: color(orange);
  }
}

.field-type-file {

  .file-widget {
    background: color(grey-light);
    padding: 10px;
  }

  .description {
    color: color('grey');

    strong {
      font-weight: normal;
    }
  }
}

.form-managed-file .form-file {
  margin-bottom: 10px;
}

.hybridauth-widget-wrapper {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 400px;
  width: 100%;

  &::after {
    content: 'Or sign in below';
    color: color('grey');
    border-bottom: 1px solid color(grey-extra-light);
    display: block;
    margin: 10px 10px 0;
  }

  li {
    margin: 5px 2px;
  }

  .hybridauth-icon {
    display: block;
    font-family: 1rem;
    line-height: 32px;
    height: 32px;
    filter: none;
    border-radius: 3px;
    opacity: 1;
    width: auto;
    padding: 0;
    color: white;
    text-align: left;

    &::before {
      font-family: FontAwesome;
      display: inline-block;
      font-size: 1.3rem;
      width: 32px;
      text-align: center;
    }

    &::after {
      font-family: .875rem;
      vertical-align: middle;
      display: inline-block;
      margin-top: -5px;
      text-align: center;
      width: 188px;
    }

    &.facebook {
      background: #3b5998;
      line-height: 32px;

      &::before {
        content: '\f09a';
        border-right: 1px solid white;
      }

      &::after {
        content: 'Sign In with Facebook';
      }
    }

    &.google {
      background: color(red);

      &::before {
        content: '\f1a0';
        border-right: 1px solid white;
      }

      &::after {
        content: 'Sign In with Google';
      }
    }
  }
}

.page-user-edit {

  #user-profile-form {
    background: color(grey-extra-light);
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }

  #edit-timezone {
    padding: 0;

    legend {
      display: none;
    }
  }
}

.page-user-hybridauth {

  main table {
    max-width: 800px;
    margin: auto;
    width: 100%;

    th {
      background: color(grey-extra-light);
      border: 1px solid white;
      padding: 5px;
    }

    .empty.message {
      font-size: 0;
      visibility: hidden;
      padding-top: 0;
      width: 100%;

      &::after {
        content: 'You don\'t have any connected accounts yet.';
        font-size: 1rem;
        visibility: visible;
        text-align: center;
        background: color(error-bg);
        padding: 20px;
        margin: 0 auto;
        max-width: 500px;
        display: block;
      }
    }
  }

  h1 {
    visibility: hidden;
    font-size: 0;
    margin-bottom: 0;
    line-height: 1rem;

    &::before {
      content: 'My Social Logins';
      visibility: visible;
      display: block;
      font-size: 32px;
      margin: 30px 0 0;
    }

    &::after {
      content: 'This allows you to login with your Facebook or Google accounts';
      font-size: 1rem;
      padding: 10px;
      visibility: visible;
      display: block;
      font-weight: 400;
      margin: 0;
    }
  }

  .hybridauth-widget-wrapper {
    position: relative;
    top: auto;
    text-align: center;
    left: auto;
    width: auto;

    .item-list h3 {
      font-size: 0;
      visibility: hidden;

      &::after {
        content: 'Add a connected account';
        font-size: 1.25rem;
        visibility: visible;
      }
    }

    &::after {
      display: none;
    }
  }
}

#user-login,
#user-register-form,
#user-pass {
  padding: 80px 20px 20px;
  margin: auto;
  max-width: 400px;
  position: relative;

  .form-item-persistent-login {
    display: none;
  }

  .form-type-password,
  .form-item-name {

    .description {
      display: none;
    }
  }

  .forgotpswd {
    text-align: right;
  }

  .newacct {
    border-top: 1px solid color(grey-extra-light);
    margin-top: 10px;
    padding-top: 10px;
    text-align: center;
  }
}

#user-register-form .signinform {
  border-top: 1px solid color(grey-extra-light);
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
}

.page-user {

  .profile {
    display: none;
  }

  .region-header .block {

    .signin {
      display: none;
    }
  }

  #user-login {
    background: color(grey-extra-light);
    padding: 120px 20px 20px;

    .hybridauth-widget-wrapper {
      top: 20px;

      &::after {
        border-color: color(grey-light);
      }
    }
  }

  main {
    text-align: center;

    .block {
      width: auto;
      display: block;
      text-align: left;
      margin: 0 0 40px;
      vertical-align: top;
      padding: 0;
      text-align: center;

      &::after {
        content: '';
        width: 100%;
        display: table;
        clear: both;
      }

      .block__title {
        margin: 0;
        color: color(orange);
        font-size: 20px;
        padding: 10px;
        line-height: 1em;
      }

      .views-row {
        display: inline-block;
        text-align: left;
        vertical-align: top;
        width: auto;
        margin: 2.5px 1px;

        a,
        a:link,
        a:visited,
        a:hover {
          background: color(grey-extra-light);
          padding: 10px 20px;
          display: block;
          width: auto;
          color: black;

          .title {
            color: color(blue);
          }
        }

        a:hover {
          background: #ddd;
        }
      }
    }
  }
}

.page-user-register,
.page-user-password,
.page-user {

  h1 {
    margin-bottom: 20px;
  }

  ul.tabs {
    display: none;
  }
}

.page-user-register,
.page-user-password {

  main {
    text-align: left;
  }

  .region-header .block {

    .signin {
      display: inline-block;
    }
  }
}

.page-user-register #user-register-form {
  padding-top: 140px;
}

#user-register-form,
#user-pass {
  background: color(grey-extra-light);
  padding: 100px 20px 20px;

  #edit-actions {
    margin-top: 40px;
  }

  .hybridauth-widget-wrapper {
    top: 30px;

    &::after {
      border-color: color(grey-light);
      color: color(grey-dark);
      content: 'Or create a new account below';
    }

    .hybridauth-icon.facebook::after {
      content: 'Register with Facebook';
    }

    .hybridauth-icon.google::after {
      content: 'Register with Google';
    }
  }
}

#user-pass {
  max-width: 400px;
  padding: 20px;
}

#user-register-form {

  #edit-timezone {
    display: none;
  }

  .password-strength,
  .field-name-field-registration-source {
    display: none;
  }

  .password-parent {
    width: auto;
  }

  .confirm-parent {
    width: auto;
    display: flex;
    flex-direction: column;

    label {
      order: 1;
    }

    #edit-pass-pass2 {
      order: 2;
    }

    div.password-confirm {
      float: none;
      margin-top: 0;

      order: 3;

      .ok,
      .error {
        padding: 0 10px;
      }
    }
  }

  div.password-suggestions {
    border: 0;
    font-size: .75rem;
    color: color('grey');
    width: auto;

    ul {
      margin-top: 10px;
    }
  }

  .form-type-password-confirm,
  .form-item-mail {

    .description {
      display: none;
    }
  }
}

.node,
.node-form {
  margin: auto;
  max-width: 740px;
  padding: 0;

  .field-group-fieldset,
  .form-field-type-image fieldset {
    background: #f7f7f7;
    border: 0;
    margin: 1.5rem 0;
    padding: 20px 30px;

    legend {
      background: color(yellow);
      color: white;
      padding: 5px 15px;
    }

    .field {
      margin: 1rem 0;

      .field-item {

        p {
          margin-top: 0;
        }
      }

      &.field-label-inline {

        .field-label {
          display: block;
        }

        @media all and (min-width: 480px) {

          .field-label {
            width: 100px;
            display: inline-block;
            vertical-align: top;
          }

          .field-items {
            display: inline-block;
            width: calc( 100% - 120px );
          }
        }
      }
    }
  }

  .comment__form-title {
    font-size: 24px;
    color: color(orange);
    margin: 2rem 0 .5rem;
  }

  .link-field-url {

    .form-item {
      margin: 0;
    }
  }
}

.node-type-organization {

  ul.tabs {
    text-align: left;
  }

  h1 {
    display: none;

    &.field-content,
    &.views-field {
      display: block;
      text-align: left;
    }
  }

  &.page-node-edit {

    h1 {
      display: block;
    }

    ul.tabs {
      text-align: center;
    }
  }
}

#block-views-organization-header-block {
  max-width: 1133px;
  margin: auto;
  display: block;
  padding-bottom: 5px;
  overflow: hidden;

  .logo {
    background: white;
    font: 0/0 a;

    .logo-img {
      max-width: 100%;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      font: 16px/1 Arial sans-serif;

      img {
        max-height: 100%;
        width: auto;
      }
    }

    .org-initial {
      font-size: 40px;
      font-family: 'Open Sans', sans-serif;
      position: absolute;
      width: 70px;
      height: 70px;
      line-height: 70px;
      top: -1px;
      left: -1px;
      color: white;
      text-align: center;
      background: color(orange);

      @media all and (min-width: 700px) {
        width: 100px;
        height: 100px;
        line-height: 100px;
      }

      &:nth-of-type(odd) {
        background: color(orange);
      }

      &:nth-of-type(even) {
        background: color(blue);
      }

      &:nth-of-type(3),
      &:nth-of-type(6),
      &:nth-of-type(9),
      &:nth-of-type(12),
      &:nth-of-type(15),
      &:nth-of-type(18),
      &:nth-of-type(21),
      &:nth-of-type(24) {
        background: color(yellow);
      }
    }

    &::before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .views-field-title {
    margin-top: 40px;
    text-align: center;
    font-weight: 600;
    padding: 0 10px;
    font-size: 1.3rem;
    line-height: 1.2em;
  }

  .views-field-field-cover-photo {
    width: 100%;
    display: block;
    z-index: -1;

    @media all and (max-width: 400px) {
      width: 160%;
      margin-left: -30%;
      margin-right: -30%;
    }

    @media all and (min-width: 401px) and (max-width: 700px) {
      width: 140%;
      margin-left: -20%;
      margin-right: -20%;
    }

    img {
      z-index: -1;
    }
  }

  .views-field-field-logo {
    z-index: 100;
    margin: 0 auto;
    display: block;
    width: 70px;
    position: relative;

    span.logo {
      z-index: 100;
      position: absolute;
      top: -35px;
      left: 0;
      display: block;
      background: white;
      border: 1px solid color(grey-light);
      padding: 5px;
      width: 70px;
      height: 70px;

      .logo-img img {
        max-width: 58px;
        max-height: 58px;
      }
    }
  }

  @media all and (min-width: 700px) {
    background: #f7f7f7;
    padding-bottom: 10px;

    .views-field-title {
      margin-left: calc( 2.4% + 120px );
      text-align: left;
      font-size: 1.5rem;
      margin-top: 20px;
    }

    .views-field-field-logo {
      margin-left: 2.4%;
      width: 100px;

      span.logo {
        padding: 10px;
        top: -40px;
        width: 100px;
        height: 100px;

        .logo-img img {
          max-width: 78px;
          max-height: 78px;
        }
      }
    }
  }

  @media all and (min-width: 1024px) {

    .views-field-title {
      font-size: 2rem;
    }
  }
}

.node-organization {
  max-width: 100%;

  .field-name-field-logo {
    float: left;
    margin: 20px 20px 0 0;
    width: 100px;
  }

  h1.view-organization-header {
    display: block;
    margin-left: 120px;
  }

  .group-overview {
    clear: both;

    .field {

      &.field-label-inline {

        @media all and (min-width: 480px) {

          .field-label {
            width: 200px;
          }

          .field-items {
            width: calc( 100% - 220px );
          }
        }
      }
    }
  }

  .field-name-field-photos {

    .field-items {
      text-align: center;

      @media all and (min-width: 650px) {
        text-align: left;
      }

      .field-item {
        width: 165px;
        display: inline-block;
        margin: 2px;
        vertical-align: top;
      }
    }
  }
}

#block-views-available-jobs-block {

  .view-empty {
    padding: 10px 10px 20px;
    text-align: center;
  }

  .job,
  .job:link,
  .job:visited,
  .job:hover {
    cursor: pointer;
    border-bottom: 1px solid white;
    color: black;
    padding: 5px 10px;
    display: block;

    &:first-of-type {
      border-top: 1px solid white;
    }

    .job-title {
      color: color(blue);
    }

    .job-location {
      font-size: .875rem;
    }

    .job-type,
    .job-date {
      color: #999;
      font-size: .75rem;
    }

    .job-date {
      float: right;
    }

    .easy-apply-1 {
      color: black;
      padding: 0 3px;
      background: #f1f1f1;
      font-size: .75rem;
      float: right;
      margin-left: 10px;
    }

    .easy-apply-2 {
      display: none;
    }
  }

  .job:hover {
    background: color(grey-extra-light);
  }
}

.layout-3col__right-sidebar {
  margin-top: 0;

  .pager {
    padding-bottom: 20px;
  }

  .view-organization-video {
    padding: 10px;

    .views-field-field-video {

      a,
      a:link,
      a:visited
      a:hover {
        display: block;
        position: relative;
        overflow: hidden;

        img {
          margin-top: -35px;
          margin-bottom: -35px;
        }

        &::before {
          content: '\f04b';
          border: 1px solid white;
          border-radius: 50%;
          font-family: FontAwesome;
          font-size: 30px;
          padding-left: 5px;
          position: absolute;
          width: 45px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          top: 50%;
          left: 50%;
          margin-left: -25px;
          margin-top: -25px;
          color: white;
          opacity: .7;
        }
      }

      a:hover {

        &::before {
          color: #c4302b;
          opacity: 1;
          border-color: #c4302b;
        }
      }
    }
  }

  .block {
    position: relative;
    margin-top: 41px;
    padding-top: 20px;
    background: #f7f7f7;
  }

  .block__title {
    background: color(yellow);
    line-height: 1.5rem;
    width: auto;
    color: white;
    margin: 0;
    font-size: 1rem;
    display: inline-block;
    position: absolute;
    top: -17px;
    left: 25px;
    font-weight: 400;
    padding: 5px 15px;
  }

  .view-organization-social-media {
    padding: 10px;
    text-align: center;
  }
}

.node .group-social {
  text-align: center;
  display: inline-block;
  min-width: 250px;

  .field-item,
  .field-items,
  .field {
    margin: 0;
    display: inline-block;
  }
}

.social,
.social:link,
.social:visited,
.social:hover,
.group-social a,
.group-social a:link,
.group-social a:visited,
.group-social a:hover {
  display: inline-block;
  margin: 10px;
  text-align: center;
  line-height: 40px;
  transform: scale(1);
  transition-duration: .3s;
  font-size: 0;
  visibility: hidden;

  &::before {
    font-family: FontAwesome;
    color: white;
    display: inline-block;
    font-size: 25px;
    visibility: visible;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.social:hover,
.group-social a:hover {
  transform: scale(1.2);
  transition-duration: .3s;
}

.facebook,
.facebook:link,
.facebook:visited,
.facebook:hover,
.group-social .field-name-field-facebook a,
.group-social .field-name-field-facebook a:link,
.group-social .field-name-field-facebook a:visited,
.group-social .field-name-field-facebook a:hover {
  line-height: 43px;

  &::before {
    content: '\f09a';
    background: #3b5998;
  }
}

.twitter,
.twitter:link,
.twitter:visited,
.twitter:hover,
.group-social .field-name-field-twitter a,
.group-social .field-name-field-twitter a:link,
.group-social .field-name-field-twitter a:visited,
.group-social .field-name-field-twitter a:hover {
  line-height: 43px;

  &::before {
    content: '\f099';
    background: #4099ff;
  }
}

.linkedin,
.linkedin:link,
.linkedin:visited,
.linkedin:hover,
.group-social .field-name-field-linkedin a,
.group-social .field-name-field-linkedin a:link,
.group-social .field-name-field-linkedin a:visited,
.group-social .field-name-field-linkedin a:hover {

  &::before {
    content: '\f0e1';
    background: #007bb6;
  }
}

.youtube,
.youtube:link,
.youtube:visited,
.youtube:hover,
.group-social .field-name-field-youtube a,
.group-social .field-name-field-youtube a:link,
.group-social .field-name-field-youtube a:visited,
.group-social .field-name-field-youtube a:hover {

  &::before {
    content: '\f167';
    background: #c4302b;
  }
}

.field-type-taxonomy-term-reference {

  .field-items {

    .field-item {

      a,
      a:link,
      a:visited,
      a:hover {
        background: #ededed;
        border-radius: 20px;
        padding: 5px 15px;
        margin: 5px;
        display: inline-block;
      }

      a:hover {
        color: white;
        background: color(blue);
      }
    }
  }
}

main {

  .block__title {
    text-align: center;
    font-size: 24px;
  }

  .block {
    padding: 20px 0;
  }

  @media all and (min-width: 600px) {

    .block__title {
      font-size: 32px;
    }

    .block {
      padding: 40px 0;
    }
  }

  .region-help {

    .block {
      padding: 0;
    }
  }
}

#block-views-nonprofits-hiring-now-block {

  .view-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;
  }

  .views-row {
    flex-basis: 30%;

    &:nth-of-type(4),
    &:nth-of-type(5) {
      display: none;
    }

    a,
    a:link,
    a:visited,
    a:hover {
      display: block;
      height: 100%;
      border: 1px solid color(grey-extra-light);
      font: 0/0 a;
      position: relative;
      overflow: hidden;
      text-align: center;

      &::before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }

      img {
        max-width: 90%;
        margin: 5%;
        width: auto;
        height: auto;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        font: 16px/1 Arial sans-serif;
        max-height: 100%;
      }
    }

    a:hover {
      border-color: color(blue);
    }

    @media all and (min-width: 600px) {
      flex-basis: 17.6%;

      a,
      a:link,
      a:visited,
      a:hover {

        img {
        margin: 10%;
        max-width: 80%;
        }
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        display: block;
      }
    }
  }
}

.more-link {
  text-align: center;
  margin-top: 30px;

  a {
    background: color(yellow);
  }
}

#block-views-nonprofits-hiring-now-block {
  max-width: 1000px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#block-block-6,
.node-3,
.node-218,
.node-2674,
.node-3852 {
  background: color(grey-extra-light);
  padding: 0;

  .block__title {
    margin: 0 0 20px;
    padding: 30px 20px;
    background: #e9e9e9;
  }

  &::after {
    clear: both;
    display: table;
    content: '';
    width: 100%;
  }

  .inner-block-6 {
    max-width: 1000px;
    margin: auto;
  }

  .block-6-text {
    max-width: 600px;
    padding: 0 10px;
    margin: 30px auto 0;

    div {
      text-align: center;
      max-width: 300px;
      margin: auto;
    }

    h4 {
      font-size: 1.2rem;
      margin-top: 0;
      text-align: center;
    }
  }

  .recruiterimg,
  .careerpageimg {
    position: relative;
    border-radius: 3px;
    border: 1px solid color(grey-light);
    box-shadow: 0 0 3px color(grey-light);
    max-width: 300px;
    margin: auto;
    display: block;
  }

  .buttons {
    text-align: center;
  }

  .recruiter-container {
    background: #e9e9e9;
    width: 100%;
    margin-top: 30px;
    padding: 30px 0;
  }

  .recruiterdash {
    max-width: 600px;
  }

  @media all and (min-width: 400px) {

    .block-6-text {

      div {
        max-width: 100%;
      }
    }
  }

  @media all and (min-width: 900px) {

    .recruiterdash,
    .recruiterimg,
    .careerpageimg,
    .block-6-text {
      max-width: 50%;
    }

    .buttons {
      text-align: left;
    }

    .recruiterimg,
    .careerpageimg,
    .block-6-text,
    .recruiter-container {
      float: left;
    }

    .block-6-text {
      padding-left: 40px;
      margin: 0;

      h4 {
        text-align: left;
        font-size: 1.5rem;
      }
    }

    .recruiterdash {
      padding-left: 20px;
      padding-right: 20px;

      div {
        text-align: left;
      }
    }

    .recruiterimg {
      float: right;
    }
  }

  @media all and (min-width: 1024px) {

    .block-6-text {
      padding-top: 60px;
    }

    .recruiterdash {
      padding-left: 0;
    }
  }
}

.page-node-3,
.page-node-16,
.page-node-218,
.page-node-378,
.page-node-2674,
.page-node-3852 {

  main h1 {
    display: none;
  }

  .layout-center,
  .layout-3col,
  .layout-3col__full {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  .node {
    background: none;
    max-width: 100%;

    #block-block-6 {
      margin-top: 40px;
    }
  }
}

.page-node-16,
#block-block-18,
#block-block-26 {
  background: color(grey-extra-light);

  .prices {
    padding-top: 50px;

    &::before {
      content: 'Pricing';
      display: block;
      font-size: 32px;
      text-align: center;
      margin-bottom: 60px;
    }
  }

  &.role-anonymous-user .prices::before {
    height: 50px;
    line-height: 50px;
    background: color('grey');
    color: white;
  }

  &.role-Active-Trial,
  &.role-Trial-Expired,
  &.role-Job-Poster,
  &.role-Pay-As-You-Go,
  &.role-Growing-Team,
  &.role-Big-Team,
  &.role-Job-Poster-0-Credits {

    .plans-header {
      display: none;
    }
  }

  .features {
    text-align: center;
    margin-top: 30px;

    h2 {
      background: color(grey-light);
      margin: 0;
      padding: 10px;
      color: black;
      font-weight: 400;
    }

    ul {
      margin: 20px auto;
      width: 100%;
      padding: 0 20px 0 50px;
      list-style-position: inside;
      display: inline-block;
      text-align: left;
      vertical-align: top;

      h3 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 10px;
        color: color(orange);
        margin-left: -10%;
      }

      @media all and (min-width: 850px) {
        max-width: 31%;
        margin: .5%;
        background: white;
        padding: 20px 20px 20px 50px;
        font-size: .875rem;

        &:first-of-type {
          margin-left: 1%;
        }

        &:last-of-type {
          margin-right: 1%;
        }
      }

      @media all and (min-width: 1000px) {
        max-width: 30%;
        margin: 1%;
        font-size: 1rem;

        &:first-of-type {
          margin-left: 2%;
        }

        &:last-of-type {
          margin-right: 2%;
        }
      }
    }

    li {
      margin: 20px 0;
      list-style: none;

      &::before {
        content: '\f00c';
        color: color(orange);
        padding-right: 10px;
        font-family: 'FontAwesome';
        margin-left: -27px;
      }      
    }
  }
}

.page-node-16.role-anonymous-user .prices {
  padding-top: 30px;

  &::before {
    background: none;
    color: black;
  }
}

.intro-text {
  max-width: 700px;
  margin: auto;
}

.view-testimonial {
  color: white;

  .view-content {
    max-width: 900px;
    background: color(yellow);
    margin: auto;
    width: 280px;

    &::after {
      display: table;
      width: 100%;
      clear: both;
      content: '';
    }

    img {
      height: 280px;
      width: 280px;
      display: inline-block;
      vertical-align: top;
    }

    .credit {
      padding: 0 20px 20px;
      display: inline-block;
      font-size: .875rem;
      width: 280px;
      line-height: 1.1em;

      span {
        display: block;
      }

      .organization {
        font-weight: 700;
      }
    }

    .quote {
      font-size: 1.2rem;
      width: 280px;
      font-weight: 300;
      line-height: 1.3em;
      color: white;
      padding: 20px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      &::before {
        content: '\f10d';
        padding-right: 5px;
        font-family: FontAwesome;
        font-size: 1.1rem;
      }

      &::after {
        content: '\f10e';
        font-family: FontAwesome;
        font-size: 1.1rem;
        padding-left: 0;
      }
    }

    @media all and (min-width: 480px) {
      width: 440px;

      img {
        width: 250px;
        height: 250px;
        float: left;
      }

      .credit,
      .quote {
        padding: 10px;
        width: 190px;
        float: left;
      }

      .credit {
        padding-top: 0;
      }

      .quote {
        font-size: 1.1rem;
        line-height: 1.2em;
      }
    }

    @media all and (min-width: 500px) {
      width: 460px;

      .credit,
      .quote {
        padding: 15px 20px 10px;
        width: 210px;
      }

      .credit {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    @media all and (min-width: 600px) {
      width: 540px;

      img {
        width: 270px;
        height: 270px;
        float: left;
      }

      .credit,
      .quote {
        padding: 20px;
        padding-bottom: 10px;
        width: 270px;
        float: left;
      }

      .credit {
        padding-top: 0;
      }

      .quote {
        font-size: 1.19rem;
        line-height: 1.5em;
      }
    }

    @media all and (min-width: 700px) {
      width: 660px;

      img {
        width: 300px;
        height: 300px;
      }

      .credit,
      .quote {
        width: 360px;
        padding: 30px;
        padding-bottom: 10px;
      }

      .quote {
        font-size: 1.4rem;
        line-height: 1.4em;
      }

      .credit {
        padding-top: 0;
      }
    }

    @media all and (min-width: 940px) {
      width: 900px;

      img {
        width: 300px;
        height: 300px;
      }

      .credit,
      .quote {
        width: 600px;
      }

      .credit {
        padding: 0 50px;
        font-size: 1rem;

        span {
          display: inline-block;
        }

        .jobtitle {

          &::before {
            content: '-';
            padding: 0 5px;
          }
        }
      }

      .quote {
        padding: 60px 50px 30px;
        font-size: 1.8rem;

        &::before {
          font-size: 1.5rem;
          position: absolute;
          top: 50px;
          padding-right: 0;
          left: 20px;
        }

        &::after {
          font-size: 1.5rem;
          padding-left: 10px;
        }
      }
    }
  }
}

.not-logged-in .authenticated,
.logged-in .anonymous {
  display: none !important;
}

.region-bottom {
  margin-top: 50px;
}

#block-block-7 {
  background: color(blue);
  padding: 10px 10px 50px;
  font-size: .875rem;
  color: white;

  &::after {
    content: '';
    width: 100%;
    display: table;
    clear: both;
  }

  a,
  a:link,
  a:visited,
  a:hover {
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }

  .footer-menu {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;

    a,
    a:link,
    a:visited,
    a:hover {
      display: inline-block;
      padding: 3px 10px;
      border-radius: 3px;
      margin: 2px 1px;
      background: white;
      color: color(blue);
      text-decoration: none;
    }
  }

  .logo {
    background-image: url('../images/logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 45px;
    display: block;
    margin: 0 auto 10px;
  }

  .footer-text {
    text-align: center;
    line-height: 1.4em;

    span {
      display: block;
    }
  }

  .socialmedia {
    text-align: center;
    margin-bottom: 10px;

    .social,
    .social:link,
    .social:visited,
    .social:hover {
      text-decoration: none;
      color: color(blue);

      &::before {
        color: color(blue);
      }
    }

    .facebook,
    .twitter,
    .linkedin,
    .youtube {
      background: white;
    }
  }

  @media all and (min-width: 400px) {

    .footer-menu {
      clear: both;

      a,
      a:link,
      a:visited,
      a:hover {
        background: none;
        color: white;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .socialmedia {
      float: right;
    }

    .logo {
      margin: 20px 0;
    }

    .footer-text {
      clear: both;

      span {
        display: inline-block;

        &.footer-text-1 {
          display: block;
        }
      }

      .address-2 {

        &::before {
          content: ',';
          padding-right: 5px;
        }

        &::after {
          content: '-';
          padding: 0 5px;
        }
      }
    }
  }

  @media all and (min-width: 768px) {
    padding: 20px 20px 80px;

    .footer-menu {
      float: right;
      width: auto;
    }

    .socialmedia {
      float: right;
      clear: right;
    }

    .footer-text {
      text-align: left;
      float: left;
      clear: none;
    }

    .logo {
      margin-top: 10px;
    }
  }

  .facebook,
  .twitter,
  .linkedin,
  .youtube {
    background: white;

    &:link,
    &:visited,
    &:hover {

      &::before {
        background: white;
      }
    }
  }
}

.not-logged-in,
.role-Job_Seeker {

  #block-block-7 {
    padding-bottom: 30px;
  }
}

.section-user {

  #block-block-7 {
    padding-bottom: 120px;
  }
}

.employer-header,
.plans-header,
.affiliate-header {
  text-align: center;
  padding: 200px 20px 50px;
  background-image: url('../images/business-people-meeting-office.jpg');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-size: 1.1rem;

  h2 {
    font-size: 1.8rem;
    margin: 0 auto 1rem;
    line-height: 1.3em;
    max-width: 860px;

    span:nth-of-type(1) {
      padding-right: 10px;
    }

    @media all and (min-width: 580px) {

      span {
        display: block;
        padding-right: 0;
      }
    }
  }

@media all and (min-width: 480px) {
    background-size: cover;

    h2 {
      font-size: 2.3rem;
    }
  }

  @media all and (min-width: 1024px) {
    font-size: 1.2rem;

    .button {
      padding: 10px 25px;
    }

    h2 {
      font-size: 3rem;
    }
  }
}

.plans-header {
  background-image: url('../images/trial-photo.jpg');
}

.affiliate-header {
  background-image: url('../images/partnerships.jpg');
}

.employer-header,
.plans-header,
.features {

  h2 span {
    display: block;
    padding-right: 0;
  }

  @media all and (min-width: 1024px) {
    background-position: center -200px;

    h2 span {
      display: inline-block;

      &:nth-of-type(1) {
        padding-right: 10px;
      }
    }
  }
}

.plans-header {
  background-position: center 0px;

  @media all and (min-width: 1024px) {
    background-position: center -100px;
  }
}

.centered {
  text-align: center;
}

#edit-field-don-t-fill-this-out-if-you,
.field-name-field-don-t-fill-this-out-if-you {
  display: none;
}

.prices {
  padding: 70px 0 0;
  text-align: center;

  .price-option,
  .price-option:link,
  .price-option:visited,
  .price-option:hover {
    position: relative;
    color: black;
    display: inline-block;
    width: 100%;
    max-width: 300px;
    margin: 15px auto;
    vertical-align: top;

    .price-container {
      border: 1px solid color(grey-light);
      background: white;
      margin-bottom: 5px;
      padding: 10px;
    }

    sup,
    sub {
      font-size: .875rem;
    }

    .price-buy {
      margin: 20px auto 0;
    }

    @media all and (min-width: 680px) {
      margin: 15px;

      .price-container {
        padding: 20px;
      }
    }

    .price {
      font-size: 2.5rem;
      display: block;
      font-weight: 300;
      padding: 30px 0;
    }

    .price-name {
      font-size: 1.5rem;
      line-height: 1.5em;
      font-weight: 300;
      display: block;
    }

    .discount {
      color: color('grey');
      font-size: .875em;
      display: block;
    }

    .description {
      display: block;
      color: color('grey');
      margin: 0 0 10px;
    }
  }

  .price-option:hover {

    .price-container {
      border-color: color(blue);
    }

    .button {
      background-color: color(blue);
    }
  }

  .price-1,
  .price-1:link,
  .price-1:visited,
  .price-1:hover {

    &::before {
      content: 'Most Popular';
      text-transform: uppercase;
      background: color(blue);
      color: white;
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: block;
      position: absolute;
      top: -30px;
    }

    .price-container {
      border-color: color(blue);
    }
  }
}

#crm-container.crm-public {

  p,
  h1,
  h2,
  h3,
  h4,
  a {
    font-family: inherit;
  }

  .help,
  #help {
    font-size: 1rem;
    color: black;
    line-height: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    background: none;
    font-weight: 400;
    color: black;
    padding: 0;
    margin: 0;
  }

  .crm-not-you-message {
    display: none;
  }

  form {
    padding: 0;

    select {
      height: auto;
      line-height: inherit;
    }

    .price_set-section .one_job_listing-section {
      text-align: center;
      margin-bottom: 0;

      .clear {
        margin-bottom: 10px;
      }
    }
  }

  .crm-contribution-main-form-block {

    .membership-group {

      fieldset {
        padding: 5px 25px;

        &::before {
          content: 'Your Order';
          font-family: inherit;
          font-size: 1rem;
          color: white;
          background-color: color(yellow);
          padding: 5px 15px;
          font-weight: normal;
          position: relative;
          top: -18px;
        }
      }

      .membership_amount-section {
        margin: 0;

        .membership_amount-content label {
          display: block;

          &::after {
            content: '3 job listings - $199/month';
            font-size: 1rem;
            visibility: visible;
            color: white;
          }

          .crm-price-amount-label,
          .crm-price-amount-amount,
          .crm-price-amount-label-separator {
            display: none;
          }
        }

        .label {
          display: none;
        }

        .auto-renew {
          margin: 0;
        }

        #force_renew {
          font-size: 0;
          visibility: hidden;

          &::before {
            text-align: center;
            display: block;
            content: 'This plan will renew automatically every month. You can cancel at any time.';
            visibility: visible;
            color: color('grey');
            font-size: .875rem;
          }
        }
      }
    }

    .email-5-section {
      display: none;
    }

    #priceset-div {
      margin-bottom: 25px;
    }

    &.crm-contribution-page-id-3 {

      .membership-group {

        fieldset {
          padding-bottom: 10px;
        }

        .membership_amount-section {

          .membership_amount-content {

            .membership_amount-row1 label::after {
              content: 'Unlimited job listings - $499/month';
            }

            .membership_amount-row2 label::after {
              content: 'Unlimited job listings - $4,999/year';
            }
          }
        }
      }
    }
  }

  .crm-contribution-thankyou-form-block {

    &.crm-contribution-page-id-2 {

      > .header-dark,
      #membership-listings,
      .amount_display-group .crm-auto-renew-cancel-info,
      .amount_display-group strong:nth-of-type(3) {
        display: none;
      }
    }

    .help {
      display: none;
    }

    .crm-group .header-dark {
      font-size: 1.5rem;
    }

    .contributor_email-group {
      margin-top: 0;
      padding-top: 0;

      .header-dark {
        display: none;
      }
    }
  }
}

.morelistings {
  cursor: pointer;
}

#block-block-9 {
  text-align: center;
  font-size: .75rem;
  display: table;
  width: 100%;
  max-width: 600px;
  margin: 30px auto 0;

  @media all and (min-width: 480px) {
    font-size: .875rem;
  }

  span {
    height: 40px;
    display: table-cell;
    min-width: 80px;
    padding: 5px;
    line-height: 1.3em;
    color: color('grey');
    border-top: 3px solid color(grey-light);
    margin: 1px;
    vertical-align: top;

    @media all and (min-width: 480px) {
      padding: 5px 15px;
    }

    &.step1::before {
      content: '1. Register';
    }

    &.step2::before {
      content: '2. Create an employer profile';
    }

    &.step3::before {
      content: '3. Start posting jobs';
    }

    &.step4 {
      display: none;
    }
  }
}

.page-user-register-trial,
.page-user-register-plan,
.page-user-login-purchase,
.page-user-register-purchase {

  #block-block-9 {

    span.step1 {
      border-color: color(yellow);
    }
  }
}

.page-user-login-purchase {

  .hybridauth-widget-wrapper {
    display: none;
  }

  #user-login {
    padding-top: 10px;
  }

  #block-block-9 {

    span.step1::before {
      content: '1. Sign In';
    }
  }
}

.page-user-register-purchase {

  #block-block-9 {

    span.step1::before {
      content: '1. Register';
    }
  }
}

.page-node-add-organization-trial,
.page-civicrm-contribute,
.page-node-16 {

  #block-block-9 {

    span.step1,
    span.step2 {
      border-color: color(yellow);
    }
  }
}

.page-node-add-job-posting-trial,
.page-node-add-job-posting-plan,
.civicrm-thank-you-page {

  #block-block-9 {

    span.step1,
    span.step2,
    span.step3 {
      border-color: color(yellow);
    }
  }
}

.page-civicrm-contribute,
.page-node-add-job-posting-plan,
.page-user-register-plan,
.page-user-login-purchase,
.page-user-register-purchase,
.page-node-16 {

  #block-block-9 {

    span.step2::before {
      content: '2. Purchase';
    }
  }
}

.page-node-add-job-posting-plan {

  #block-block-9 {

    span.step3::before {
      content: '3. Post Jobs';
    }
  }
}

.page-user-register-trial .edit-field-linkedin {
  display: none;
}

#block-block-10,
#block-block-20 {
  position: fixed;
  right: 3px;
  bottom: 3px;

  a,
  a:link,
  a:visited,
  a:hover {
    padding: 10px 15px 10px 35px;
    border: 1px solid white;
    font-size: 1.2rem;
    background: color(orange);
    position: relative;
    margin: 0;

    &::before {
      content: '+';
      font-weight: 300;
      font-size: 1.8rem;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  a:hover {
    background: color(blue);
  }

  .button {
    margin: 0;
  }
}

.not-logged-in #block-block-20 {

  a,
  a:link,
  a:visited,
  a:hover {
    padding: 10px 15px;

    &::before {
      display: none;
    }
  }
}

#block-views-expired-listing-block {
  background: color(grey-extra-light);
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;

  .attachment .view {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin: 10px;

    h3 {
      margin-bottom: 5px;
    }

    .views-row {
      padding: 10px;
      background: white;
      margin: 5px;
    }
  }
}

.page-taxonomy-term {

  .taxonomy-term-description {
    max-width: 740px;
    margin: 0 auto 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    p {
      margin: 0 0 20px;
    }
  }

  main {
    text-align: center;

    .block-views {
      border: 0;
      display: inline-block;
      background: color(grey-extra-light);
      float: none;
      width: 100%;
      max-width: 100%;
      vertical-align: top;
      padding: 0 10px 20px;
      margin-top: 40px;

      @media all and (min-width: 1180px) {
        max-width: 390px;
        margin-top: 0;
      }

      .view,
      .view .view-content {
        max-width: 100%;
        width: 100%;
      }

      .block__title {
        margin: 20px 0 0;
        font-size: 1.25rem;
        line-height: 2.25rem;
        font-weight: 700;
        padding-bottom: 1rem;

        @media all and (min-width: 1180px) {
          margin-top: 0;
        }
      }

      .view-content {
        padding: 0;
        text-align: center;

        .views-row {
          display: inline-block;
          max-width: 100%;
          width: auto;
          vertical-align: top;
          text-align: left;

          @media all and (min-width: 1180px) {
            max-width: 100%;
            display: block;
          }

          a,
          a:link,
          a:visited,
          a:hover {
            display: block;
            margin: 3px;
            color: black;
            border: 1px solid color(grey-light);
            padding: 5px 15px;
            float: left;

            @media all and (min-width: 1180px) {
              border-bottom: 0;
              margin: 0;
              float: none;
            }
          }

          a:hover {
            background: color(blue);
            color: white;
          }
        }

        .views-row:last-of-type {

          a,
          a:link,
          a:visited,
          a:hover {
            border-bottom: 1px solid color(grey-light);
          }
        }
      }
    }

    .view {
      width: auto;
      display: inline-block;
      vertical-align: top;

      @media all and (min-width: 768px) {
        width: 718px;
      }

      @media all and (min-width: 850px) {
        width: 806px;
      }

      .job,
      .job:link,
      .job:visited,
      .job:hover {
        border: 1px solid color(grey-extra-light);
        border-bottom: 0;
        text-align: left;
      }

      .views-row:last-of-type .job {
        border-bottom: 1px solid color(grey-extra-light);
      }

      .view-content,
      .attachment {
        border: 0;
        display: inline-block;
        float: none;
        width: 100%;
        max-width: 400px;
        margin: auto;
        vertical-align: top;
        background: none;
        padding: 0 10px;

        @media all and (min-width: 768px) {
          max-width: 356px;
          margin: 0;
        }

        @media all and (min-width: 850px) {
          max-width: 400px;
        }
      }

      .view-header {
        max-width: 400px;
        margin: auto;

        @media all and (min-width: 768px) {
          max-width: 356px;
          margin: 0;
        }

        @media all and (min-width: 850px) {
          max-width: 400px;
        }

        h3 {
          margin: 0;
        }
      }

      .attachment {
        padding: 0;
        margin-top: 40px;

        @media all and (min-width: 768px) {
          margin-top: -50px;
        }

        .view {
          width: 100%;
        }

        .view-content .view-content {
          padding: 0;
        }

        .job {

          .logo {
            margin-bottom: 0;
          }

          &::after {
            content: '';
            width: 100%;
            clear: both;
            display: table;
          }
        }
      }
    }

    h1,
    .feed-icon {
      display: none;
    }
  }

  .region-hero {
    margin: 0;

    .block {
      position: relative;
      overflow: hidden;

      img {
        filter: brightness(70%);
        margin-left: -40%;
        margin-right: -40%;
        width: 180%;
        max-width: 180%;
      }

      h1 {
        text-align: center;
        margin: 0;
        font-size: 20px;
        line-height: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 1;
        margin-top: -16px;
        color: white;
      }

      @media all and (min-width: 768px) {

        img {
          margin: 0;
          width: 100%;
          max-width: 100%;
        }

        h1 {
          font-size: 32px;
          line-height: 32px;
          height: 32px;
        }
      }
    }
  }
}

.section-job-category,
.section-job-function {

  main {
    h1 {
      display: block;
      margin-bottom: 30px;
    }
  }
}

.view-company-info-for-job {
  margin-top: 40px;

  h2.title {
    text-align: center;
    border-top: 3px solid color(grey-extra-light);
  }

  .node {

    header,
    .node-readmore {
      display: none;
    }

    fieldset .field {

      .field-label {
        font-size: 1rem;
        color: black;
        margin-top: 0;
      }
    }

    .field-name-field-area-of-focus {

      .field-item {
        display: inline-block;
        margin: 3px;
      }
    }

    .group-overview {

      .field {
        display: inline-block;
        max-width: 320px;
        width: 100%;
        margin: 10px 20px 10px 0;
        vertical-align: top;

        .field-label {
          display: inline;

          &::after {
            content: ':';
            margin-left: -3px;
          }
        }

        .field-items {
          display: inline;
          padding-left: 10px;

          .field-item {
            display: inline;
          }
        }
      }

      .field .field-items .field-item::before {
        display: none;
      }
    }

    .group-video {

      .field-name-field-video {

        a,
        a:link,
        a:visited
        a:hover {
          display: block;
          position: relative;
          overflow: hidden;

          img {
            margin-top: -6.6%;
            margin-bottom: -6.6%;
            width: 100%;
          }

          &::before {
            content: '\f04b';
            border: 1px solid white;
            border-radius: 50%;
            font-family: FontAwesome;
            font-size: 30px;
            padding-left: 5px;
            position: absolute;
            width: 45px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            top: 50%;
            left: 50%;
            margin-left: -25px;
            margin-top: -25px;
            color: white;
            opacity: .7;
          }
        }

        a:hover {

          &::before {
            color: #c4302b;
            opacity: 1;
            border-color: #c4302b;
          }
        }
      }
    }
  }
}

.view-user-profile.view-display-id-page_1 {
  margin-bottom: 10px;

  &::after {
    content: '';
    width: 100%;
    display: table;
    clear: both;
  }

  > .view-content {
    background: color(grey-extra-light);
    padding: 20px;
    text-align: center;
    max-width: 650px;
    margin: auto;

    &::after {
      content: '';
      width: 100%;
      display: table;
      clear: both;
    }

    .name {
      margin-bottom: 10px;

      h3 {
        margin: 0;
      }
    }

    span {
      padding-right: 10px;
      display: inline-block;
      margin: 3px 0;
      margin-right: 5px;
      border-right: 1px solid color(grey-light);

      &:last-of-type {
        border: 0;
      }
    }
  }
}

.views-table {
  width: 100%;

  thead {
    background: color(grey-light);

    th {
      text-align: left;
      border: 1px solid white;
      padding: 5px 10px;

      a,
      a:link,
      a:visited
      a:hover {
        color: black;

        img {
          display: inline;
          filter: brightness(0%);
        }
      }

      a:hover {
        color: color(darkblue);
      }
    }
  }

  tbody {

    td {
      padding: 5px;
      text-align: left;
    }

    tr.even {
      background: color(grey-extra-light);
    }
  }
}

.view-user-widgets {

  .views-exposed-form {

    .views-exposed-widgets {
      text-align: center;
    }

    .views-exposed-widget {
      padding-right: 5px;
      float: none;
      text-align: left;
      display: inline-block;

      #edit-nid {
        width: 100px;
      }

      input[type='text'] {
        height: 30px;
        padding-left: 5px;
      }

      .form-submit {
        margin-top: 0;
      }
    }

    .views-reset-button,
    .views-submit-button {
      padding-top: .35em;
      padding-right: 5px;
    }

    .views-reset-button {

      a {
        background: color(grey-extra-light);
        color: black;
      }
    }
  }

  .view-empty {
    background: #ffe79d;
    padding: 20px;
    max-width: 650px;
    margin: 20px auto;
  }

  &.jobs-posted {

    .view-footer {
      text-align: left;
      font-size: .875rem;
      color: color('grey');
    }

    .howapply-2 {
      font-size: 0;
      visibility: hidden;

      &::after {
        content: 'N/A *';
        font-size: 1rem;
        visibility: visible;
      }
    }

    .applications {
      cursor: pointer;
    }

    .views-field-created-1 {

      .month,
      .months,
      .year,
      .years,
      .Inactive {

        &::after {
          content: 'Inactive';
          color: color('grey');
        }

        &.Active::after {
          content: 'Inactive';
          color: color('grey');
        }
      }

      .week,
      .weeks,
      .day,
      .days,
      .second,
      .seconds,
      .minute,
      .minutes
      .hour,
      .hours,
      .Active {

        &::after {
          content: 'Active';
        }


        &.Inactive::after {
          content: 'Inactive';
          color: color('grey');
        }
      }
    }
  }
}

.role-Employer {

  &.page-user {

    #block-views-user-widgets-block,
    #block-views-user-widgets-block-1 {
      display: none;
    }
  }
}

.role-Job-Seeker {

  &.page-user {

    #block-views-user-widgets-block-2,
    #block-views-user-widgets-block-3 {
      display: none;
    }
  }
}

div.autocomplete-deluxe-multiple {
  width: 100%;
  max-width: 500px;
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='telephone'],
input[type='file'],
select,
textarea {
  width: 100%;
  max-width: 100%;

  @media all and (min-width: 768px) {
    width: auto;
  }
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='telephone'],
input[type='password'] {
  -webkit-appearance: none;
  border: 0;
  border: 1px solid #ddd;
}

input.form-submit {
  width: auto;
}

.form-field-type-image {
  overflow: hidden;
}

.page-node-3852 {

  h2.block__title {
    text-align: center;
  }

  .node-3852 {

    .inner-block-6 {
      border-bottom: 10px solid #f1f1f1;
    }

    @media all and (min-width: 900px) {

      .careerpageimg {
        max-width: 40%;
      }

      .block-6-text {
        max-width: 55%;
      }
    }
  }
}
