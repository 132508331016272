.crm-container {

  a,
  a:link,
  a:visited,
  a:hover {
    color: color(darkblue);
  }
}

#crm-container.crm-public form {
  border-radius: 0;
  padding: 0;
  background: transparent;
  max-width: 700px;
  margin: auto;

  @media screen and (min-width: 480px) {
    padding: 1rem;
  }

  .billing_country_id-5-section {
    display: none;
  }

  fieldset {
    background-color: color(grey-extra-light);
    border: 0;

    fieldset.date-combo {
      padding: 0;
    }
  }

  label,
  .label label {
    font-weight: bold;
    font-size: 1rem;
    color: black;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin-bottom: 4px;
  }

  input[type='text'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  input[type='email'],
  input[type='password'],
  input[type='number'] {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    width: 100%;
    background-color: white;
    border: 1px solid color(grey-light);
    max-width: 400px;
    padding-left: .5rem;
    font-size: 1rem;
    -webkit-appearance: none;
  }

  input[type='password'],
  input[type='text'],
  input[type='url'],
  input[type='email'] {
    max-width: 300px;
  }

  input[type='tel'],
  input[type='number'] {
    max-width: 150px;
  }

  .webform-component--location--state,
  .webform-component--location--zip-code,
  .webform-component--cost {
    input[type='text'] {
      max-width: 150px;
    }
  }

  .select2-focusser {
    max-width: 100%;
  }

  #cvv2 {
    width: 100px;
  }

  .select2-container .select2-choice {
    height: 40px;
  }

  textarea {
    border-radius: 0;
    line-height: 1.5em;
    width: 100%;
    background-color: white;
    border: 1px solid color(grey-extra-light);
    padding-left: .5rem;
    height: auto;
  }

  select {
    height: 40px;
    line-height: 40px;
    width: auto;
    max-width: 280px;

    @media all and (min-width: 500px) {
      width: auto;
      max-width: 100%;

      &.crm-select2,
      &.crm-form-date {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .form-radios .form-item,
  .form-checkboxes .form-item {
    display: inline-block;
    width: 49%;
    min-width: 120px;
    margin: .3rem 0 0;
    vertical-align: top;

    label.option {
      font-weight: normal;
    }
  }

  .webform-component-time .form-radios .form-item {
    display: inline;
    width: 100%;
  }

  .form-radios,
  .webform-container-inline .form-radios {
    display: inline-block;
    min-width: 120px;
  }

  input.year,
  select.year,
  select.month,
  select.day {
    width: 31%;
    max-width: 120px;
  }

  select.minute,
  select.hour {
    width: 46%;
    max-width: 120px;
  }

  .form-item-field-cost-und-0-value {

    .form-text,
    .field-prefix {
      display: inline-block;
    }
  }

  .field-widget-link-field {
    margin: 1.2rem 0;

    .form-item {
      margin: 0;
    }
  }

  #edit-actions {
    background: none;
    text-align: center;
  }

  .field-type-addressfield {
    .form-item-field-location-und-0-country {
      display: none;
    }
  }

  label[for='edit-field-start-date-and-time-und-0-value2'] {
    padding: 5px;
    background: color(grey-light);
    margin: 5px 0;
  }

  .date-padding {

    .form-item {
      margin-bottom: 10px;
    }
  }

  .webform-component-fieldset,
  .form-wrapper,
  fieldset {
    background: color(grey-extra-light);

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea {
      background: white;
    }

    legend {
      border: 0;
      background: color(yellow);
      color: white;
      font-size: 1rem;
    }

    fieldset {
      border: 1px solid #ccc;
    }
  }

  .crm-section {

    .label,
    .label label {
      font-size: 1rem;
      float: none;
      color: black;
      line-height: 1.5em;
    }
  }

  .price_set-section {

    .price-set-option-content {

      label {
        font-weight: normal;

        .crm-price-amount-label {
          font-weight: normal;
        }
      }
    }

    .section-pricesetTotal {
      background: white;
      margin: 10px 0;
      text-align: center;
      padding: 10px;

      label,
      .label label,
      .label {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .label {
      margin-bottom: 10px;
    }

    .crm-section {
      margin: 30px 0;
    }

    .one_job_listing-section {
      margin-top: 0;

      .label {
        display: none;
      }
    }

    .crm-form-radio {
      display: none;
    }

    .price-set-option-content {
      background: color(grey-light);
      padding: 5px 15px;
      text-align: center;
      display: block;
      margin: 2px;

      &.highlight {
        background: color(blue);

        label {
          color: white;
        }
      }

      label {
        padding-right: 0;
      }
    }
  }

  .additional_jobs-section {
    text-align: center;

    #price_3 {
      width: 100px;
    }

    .label label {
      display: block;
      text-align: center;
    }
  }

  .crm-contribution-page-id-6 {

    .buy_job_listings-section {
      margin: 0 0 30px;
    }

    .buy_job_listings-content {
      text-align: center;

      .price-set-option-content label {
        display: block;
      }

      .price-set-row {
        max-width: 320px;
        display: inline-block;
        width: 100%;
        margin-bottom: 3px;
      }
    }
  }
}

.select2-drop,
.select2-container {
  max-width: 238px !important;
}

#crm-container.crm-public {
  padding: 0;
  max-width: 1200px;
  margin: 20px auto;

  div#printer-friendly {
    display: none;
  }

  fieldset,
  #priceset-div,
  .is_recur-section,
  .email-5-section,
  .amount_display-group,
  .contributor_email-group {
    background-color: color(grey-extra-light);
    padding: 25px;
    margin-top: 25px;
  }

  .billing_name_address-group,
  .credit_card-group {
    margin-top: 25px;
    background-color: color(grey-extra-light);
  }

  #priceset-div {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 50px;

    &::before {
      content: 'Your Order';
      font-family: inherit;
      font-size: 1rem;
      color: white;
      background-color: color(yellow);
      padding: 5px 15px;
      font-weight: normal;
      position: relative;
      top: -18px;
    }
  }

  .is_recur-section {
    margin-top: 0;
    padding-top: 0;
  }

  .email-5-section {
    margin-top: 0;
    padding: 0;
  }

  .is_recur-section .label {
    display: none;
  }

  fieldset {
    margin-bottom: 20px;
    border: 0;
  }

  fieldset legend {
    font-size: 1rem;
    color: white;
    background-color: color(yellow);
    font-weight: normal;
    font-family: inherit;
    padding: 5px 15px;
  }

  tbody tr {
    border-bottom: 0;

    &:nth-child(2) {
      background-color: transparent;
    }
  }

  img {
    height: auto;
  }

  input,
  #civicrm-menu input {
    box-sizing: border-box;
  }

  .crm-actionlinks-top::after {
    content: '';
    width: 100%;
    display: table;
    clear: both;
  }

  label {
    padding-right: 14px;
  }

  .credit_card_info-group,
  .crm-section {
    max-width: 100%;
    margin: 0 auto 5px;

    .label,
    .label label {
      width: 100%;
      text-align: left;
      font-size: 1.8rem;
      margin-top: 1rem;
      padding: 0;
      color: color(orange);
      background: none;
    }

    .content {
      margin-left: 0;
    }
  }

  #payment_information label {
    padding-right: 1px;
  }

  .crm-submit-buttons {
    text-align: center;
    display: table;
  }

  .crm-group .header-dark {
    background-color: transparent;
    line-height: 1.5em;
    color: color(orange);
    font-size: 2rem;
    font-weight: normal;
    padding: 0;
    margin: 1em 0 .2em;
    border-radius: 0;
  }

  i.crm-i {
    display: none;
  }

  span.crm-button {
    float: none;
    padding: 0;
    border-radius: 0;
    text-shadow: none;
    background: none;
    border: 0;
    display: block;
    width: 100%;
    text-align: center;
  }

  .ui-dialog-buttonset .ui-button,
  a.button,
  a.crm-invoiceButton,
  a.crm-invoiceButton:link,
  a.button:link,
  a.button:visited,
  input.crm-form-submit {
    border-radius: 0;
    -webkit-appearance: none;
    display: inline-block;
    float: none;
    padding: 5px 15px;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    color: white;
    border: 0;
    font-weight: 300;
    font-size: 1.1rem;
    text-shadow: none;
    background: color(orange);
    margin: 5px auto;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: white;
      background-color: color(blue);
    }
  }

  input.crm-form-submit.cancel,
  .crm-button_qf_Confirm_back,
  a.button.cancel,
  a.button.cancel:link,
  a.button.cancel:visited,
  a.button.cancel:hover {
    background-color: color(grey-extra-light);
    color: black;
    margin-left: 9px;
  }

  .crm-button_qf_Confirm_back input.crm-form-submit.cancel {
    background: none;

    &:hover {
      color: white;
    }
  }

  .crm-button.crm-icon-button {
    padding: 0;
  }

  .crm-button-icon {
    margin-top: 5px;
    display: none;
  }

  input#frequency_interval {
    max-width: 54px;
  }

  input.crm-form-text,
  input.dateplugin {
    height: 40px;
    line-height: 40px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 5px;
    width: initial;
  }

  div.display-block {
    margin: 1em 0;
  }

  .crm-contribution-thankyou-form-block {

    .header-dark {
      margin-top: 0;
    }

    .billing_name_address-group,
    .credit_card-group {
      margin: 0;
      padding: 25px;
    }
  }
}

.crm-block {

  .help,
  #help {
    background: #bce4de;
  }

  .warning {
    background: #ffe79d;
  }

  .error {
    background: color(error-bg);
  }
}
