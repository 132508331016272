#block-search-api-saved-searches-jobs {
  display: none;
}

#search-api-saved-searches-save-form-wrapper {
  padding: 40px 20px 10px;
  text-align: center;

  &::before {
    content: 'Get notifications about new job listings delivered to your inbox.';
    color: color(orange);
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 1.2em;
  }

  #search-api-saved-searches-save-form {

    .form-item-name label::before {
      content: 'Job Alert ';
    }
  }

  input[type='text'] {
    width: 100%;
  }
}

#search-api-saved-searches-search-edit-form {
  margin: auto;
  max-width: 600px;
  padding: 20px;
  background: color(grey-extra-light);
}

.page-nonprofit-jobs-search .region-highlighted,
.page-nonprofit-jobs-search .region-highlighted.closed,
.page-nonprofit-jobs.page-views .region-highlighted,
.page-nonprofit-jobs.page-views .region-highlighted.closed {
  display: none;
}

.page-nonprofit-jobs-search .region-highlighted.open,
.page-nonprofit-jobs.page-views .region-highlighted.open {
  height: auto;
  display: block;
}

#block-views-nonprofit-jobs-index-block-1,
#block-views-localized-search-block-1 {
  display: none;
  width: calc( 100% - 320px );
  padding: 30px;

  .view-content {
    background: none;
    width: 100%;
    float: none;
    border: 0;
  }

  .view-filters,
  .block__title {
    display: none;
  }

  @media all and (min-width: 768px) {
    // background: color(grey-extra-light);
    background: #f1f1f1;
    float: right;
    display: block;

    .view-nonprofit-jobs-index,
    .view-localized-search {
      padding: 20px 10px 30px;
      background: white;
      border: 1px solid color(grey-light);
    }

    header {
      margin: 0 0 0 110px;

      h2 {
        margin: 0;

        a {
          color: black;
        }
      }
    }

    .view-org-logo-for-job-listing {
      margin-top: -30px;
    }
  }

  @media all and (min-width: 1024px) {
    width: calc( 100% - 400px );
  }
}

.page-nonprofit-jobs-search .view-nonprofit-jobs-index,
.page-nonprofit-jobs.page-views .local-jobs {

  @media all and (min-width: 768px) {
    position: relative;

    .item-list {
      position: absolute;
      top: 10px;
    }
  }
}

.node-job-posting {

  .field-name-field-location,
  .view-joblocation-address {
    display: none;
  }

  .field-name-field-job-category {
    margin-top: 2rem;

    .field-label,
    .field-items {
      font-size: 1rem;
      padding: 0;
      margin: 0;
      color: black;
      vertical-align: middle;
      display: inline-block;
    }

    .field-items .field-item {
      display: inline-block;
    }

    .field-label {
      padding-top: 

      &::after {
        content: ':';
        padding-right: 5px;
      }
    }
  }
}

.node-job-posting .listing-stats,
.field-name-field-industry,
.field-name-field-hiring-organization {
  display: none;
}

.user-is-author {

  #block-views-expired-listing-block {
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    min-height: 50px;
    height: auto;
    max-width: 740px;
    margin: auto;

    .block__title,
    .view,
    .attachment {
      display: none;
    }

    &::before {
      content: 'This job is inactive.';
      font-weight: 700;
      visibility: visible;
      font-size: 1.5rem;
      line-height: 1.5em;
      text-align: center;
      display: block;
      background-color: color(error-bg);
      padding: 20px 20px 0;
    }

    &::after {
      content: 'The \'Listing Status\' of this job is set to \'Inactive\' or it has already reached its 30-day lifespan. You can still clone this job using the \'Replicate\' button on the bottom left hand corner of the screen (if your current plan allows for more jobs to be posted). Notice that you\'ll need to change the listing status of the cloned job to \'Active\'.';
      font-weight: 400;
      visibility: visible;
      font-size: 1rem;
      line-height: 1.5em;
      text-align: center;
      display: block;
      padding: 0 20px 20px;
      background-color: color(error-bg);  
    }
  }

  .node-job-posting .listing-stats {
    display: block;
  }
}

.page-user-hybridauth .hybridauth-widget-wrapper {
  width: 100%;
  max-width: 100%;
}

.page-user-hybridauth,
.page-user-saved-searches,
.page-user-edit,
.page-user-cases,
.page-user {

  main table {
    max-width: 800px;
    margin: auto;
    width: 100%;
    position: relative;

    .empty.message {
      text-align: center;
      background: #fff0f0;
      padding: 20px;
      margin: 0 auto;
      max-width: 500px;
    }

    th {
      background: #f3f3f3;
      border: 1px solid white;
      padding: 5px;
    }

    @media all and (max-width: 600px) {
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      display: block;
      overflow-x: auto;
      background: color(grey-light);
      padding: 10px;

      thead,
      tbody {
        background: white;
      }

      th {
        white-space: nowrap;
        padding: 5px 20px;
      }

      td {
        padding: 0 5px;
      }
    }
  }
}

.page-user-hybridauth,
.page-user-saved-searches,
.page-user-edit,
.page-user-cases {

  #block-views-user-widgets-block,
  #block-views-user-widgets-block-1,
  #block-views-user-widgets-block-2,
  #block-views-user-widgets-block-3,
  #block-views-user-widgets-block-4,
  #block-views-user-widgets-block-5 {
    display: none;
  }

  ul.tabs {
    display: block;
  }

  main {
    text-align: left;
  }
}

.page-user main table {
  max-width: 100%;
}

.page-user-hybridauth {

  h1 {
    font-size: 0;
    line-height: 0;

    &::before {
      font-size: 32px;
      line-height: 1.3em;
      content: 'My Social Logins';
      padding-bottom: 20px;
    }

    &::after {
      line-height: 1.3em;
    }
  }
}

.page-user-saved-searches {

  h1 {
    font-size: 0;
    line-height: 0;

    &::before {
      font-size: 32px;
      line-height: 1.3em;
      content: 'My Job Alerts';
      padding-bottom: 20px;
    }
  }
}

#search-api-saved-searches-search-disable-form,
#search-api-saved-searches-search-delete-form {
  max-width: 600px;
  margin: auto;
  text-align: center;
  background: color(grey-extra-light);
  padding: 20px;
}

.page-search-api-saved-search-activate main {

  p {
    max-width: 600px;
    padding: 20px;
    background: color(grey-extra-light);
    margin: auto;
  }

  h1 {
    font-size: 0;
    line-height: 0;

    &::after {
      font-size: 32px;
      line-height: 1.2em;
      content: 'Your job alert has been activated.';
    }
  }
}

html {
  background: color(blue);
}

body {
  background: white;
}

.node-webform {
  background: color(grey-extra-light);
  padding: 20px;
}

.webform-component--url {
  display: none;
}

.logged-in {

  .webform-client-form {

    .webform-component--civicrm-1-contact-1-contact-first-name,
    .webform-component--civicrm-1-contact-1-contact-last-name,
    .webform-component--civicrm-1-contact-1-email-email,
    .webform-component--url {
      display: none;
    }
  }
}

.page-user-cases-activities {

  h1 {

    &::before {
      content: 'Support Ticket';
      font-size: .875rem;
      line-height: 1em;
      display: block;
      font-weight: 400;
      color: color(grey-light);
    }
  }

  .view-my-support-tickets {
    max-width: 700px;
    margin: auto;

    .view-header {
      background: color(grey-extra-light);
      padding: 20px;
      text-align: center;

      span {
        display: inline-block;
        margin: 1px;
        padding: 5px;
        background: white;
      }
    }

    .view-footer {
      text-align: center;
      margin: 10px auto;
    }

    .views-row {
      padding: 20px;
      border: 1px solid color(grey-extra-light);

      .views-field-activity-date-time {
        float: right;
        color: color('grey');
      }

      .source {
        float: left;
        color: color('grey');
      }

      .views-field-details {
        margin: 0;

        &::before {
          content: '';
          width: 100%;
          display: table;
          clear: both;
        }
      }

      &.views-row-even {
        background: color(grey-extra-light);
      }
    }
  }
}

#block-webform-client-block-119 {
  max-width: 700px;
  margin: 30px auto;
  padding: 20px;
  background: color(grey-extra-light);

  textarea {
    border: 0;
  }
}

.page-user .region-header .block .signin {
  display: inline-block;
}

.page-nonprofits-hiring-now {

  .layout-center {
    max-width: 100%;
    padding: 0;
    margin: 0;

    .layout-3col {
      margin: 0;
      padding: 0;

      .layout-3col__full {
        padding: 0;
      }
    }
  }

  .view-nonprofits-hiring-now {

    .view-content {
      display: block;
      text-align: center;

      .views-row {
        max-width: 400px;
        margin: 5px;
        display: inline-block;
        width: 90%;
        vertical-align: top;

        @media all and (min-width: 420px) {
          width: 100%;
        }

        a,
        a:link,
        a:visited,
        a:hover {
          height: 100%;
          min-height: 120px;
          color: black;
          border: 1px solid color(grey-extra-light);
          border-radius: 5px;
          padding: 10px;
          text-align: left;
          display: block;
          height: auto;
          font-size: 1rem;
          line-height: 1.3rem;
          font-family: inherit;
          color: color('grey');

          &::before {
            display: none;
          }

          h3 {
            color: black;
            font-size: 1.2rem;
            margin: 0;
            line-height: 1.2em;
            font-weight: 400;
          }

          span {
            display: block;
            margin-bottom: 3px;
            font-size: .875rem;
          }

          .location {
            display: inline;
          }

          .label {
            color: black;
            display: inline;
            margin-right: 5px;
          }

          .logo {
            background: white;
            font: 0/0 a;
            float: left;
            width: 70px;
            display: block;
            height: 70px;
            position: relative;
            border: 1px solid color(grey-extra-light);
            margin: 0 10px 5px 0;

            .logo-img {
              max-width: 100%;
              width: 100%;
              height: auto;
              box-sizing: border-box;
              display: inline-block;
              vertical-align: middle;
              font: 16px/1 Arial sans-serif;
            }

            .org-initial {
              font-size: 40px;
              font-family: 'Open Sans', sans-serif;
              position: absolute;
              width: 70px;
              height: 70px;
              line-height: 70px;
              top: -1px;
              left: -1px;
              color: white;
              text-align: center;
              background: color(orange);

              &:nth-of-type(odd) {
                background: color(orange);
              }

              &:nth-of-type(even) {
                background: color(blue);
              }

              &:nth-of-type(3),
              &:nth-of-type(6),
              &:nth-of-type(9),
              &:nth-of-type(12),
              &:nth-of-type(15),
              &:nth-of-type(18),
              &:nth-of-type(21),
              &:nth-of-type(24) {
                background: color(yellow);
              }
            }

            &::before {
              content: ' ';
              display: inline-block;
              vertical-align: middle;
              height: 100%;
            }
          }
        }

        a:hover {
          border-color: color(darkblue);

          h3 {
            color: color(darkblue);
          }
        }
      }
    }
  }
}

#block-views-exp-help-center-page {
  text-align: center;
  padding: 70px 20px 20px;
  background-image: url('../images/customerservice.jpg');
  background-size: 190% auto;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-size: 1.1rem;

  .block__title {
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 24px;
  }

  .views-exposed-widgets {
    max-width: 400px;
    margin: auto;
  }

  @media all and (min-width: 480px) {
    background-size: 160% auto;
    padding: 90px 20px 20px;
  }

  @media all and (min-width: 600px) {
    padding: 80px 20px 120px;
  }

  @media all and (min-width: 880px) {
    background-size: 150% auto;
    padding: 80px 20px 120px;

    .views-exposed-widgets {
      max-width: 500px;
    }

    .block__title {
      line-height: 2.25rem;
      margin-top: 1.5rem;
      font-size: 32px;
      margin-bottom: 1.5rem;
    }
  }

  @media all and (min-width: 1000px) {
    background-size: 130% auto;
  }

  @media all and (min-width: 1200px) {
    background-size: 120% auto;
  }

  @media all and (min-width: 1400px) {
    background-size: 100% auto;
  }

  #edit-keys-wrapper {
    width: 100%;
    padding: 0;
    max-width: calc( 100% - 51px );
    float: left;

    input {
      line-height: 50px;
      width: 100%;
      border: 0;
      height: 50px;
      padding-left: 15px;
    }
  }

  .views-submit-button {
    padding: 0;
    float: left;

    input {
      margin: 0;
      background-image: url('../images/search.png');
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center center;
      height: 50px;
      line-height: 50px;
      text-indent: -999em;
      width: 50px;
    }
  }
}

.view-help-center {
  max-width: 740px;
  margin: auto;

  .views-row {
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid color(grey-extra-light);
  }
}

#block-block-12 {
  position: fixed;
  right: 10px;
  top: 100px;
  text-align: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  color: color('grey');
  font-size: .875rem;
  line-height: 1.2rem;
  border: 1px solid color(grey-light);
  display: none;

  @media all and (min-width: 1000px) {
    display: inline-block;
  }
}

.admin-menu {

  #block-block-12 {
    top: 129px;
  }
}

.page-user main .block .views-row {
  max-width: 320px;
  width: 100%;
}

.view-user-profile {

  .purpose {
    color: color('grey');
    font-size: .875rem;
    margin-bottom: 10px;
    display: none;
  }
}

.role-Employer .view-user-profile .purpose.employer,
.role-Job-Seeker .view-user-profile .purpose.jobseeker {
  display: block;
}

.role-Job-Seeker.page-user #block-views-user-widgets-block-4 {
  display: none;
}

#block-views-user-profile-block-1,
#block-views-user-profile-block-2,
#block-views-user-profile-block-4 {

  .view-content {
    padding: 0;
    background: none;

    .views-row {
      max-width: 100%;

      .messages {
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
      }

      .button,
      .button:link,
      .button:visited,
      .button:hover {
        background: color(orange);
        display: inline-block;
        color: white;
        padding: 5px 15px;
      }

      .button:hover {
        background: color(blue);
      }
    }
  }
}

.webform-confirmation {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

#block-views-nonprofits-hiring-now-block,
#block-views-nonprofits-hiring-now-block-2 {

  .more-link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

#block-block-6,
.node-3,
.node-218,
.node-2674,
.node-3852 {
  padding: 0 20px 20px;
  background: #f3f3f3;

  .block__title {
    background: none;
    margin-bottom: 0;
  }

  .block-6-text {

    @media all and (min-width: 900px) {
      padding-top: 30px;
    }
  }

  .recruiter-container {
    background: none;
    margin-top: 0;
  }

  .inner-block-6 {
    background: white;
    padding: 20px;
    max-width: 1100px;

    h4 {
      text-align: center;
    }

    &::after {
      display: table;
      content: '';
      width: 100%;
      clear: both;
    }
  }

  .recruiterdash .buttons {
    text-align: center;
  }
}

#block-block-7 .footer-text {

  .address-2::after {
    display: none;
  }

  .text-links {
    display: block;
  }
}

.page-node-85.page-node-done {

  main h1 {
    visibility: hidden;
    line-height: 0;
    font-size: 0;

    &::after {
      visibility: visible;
      line-height: 1em;
      font-size: 2rem;
      content: 'Thank You';
    }
  }

  main .links {
    text-align: center;
  }
}

.pager--infinite-scroll {
  float: left;
  width: 100px;
  clear: left;
}

.filtertags {
  max-width: 300px;
  margin: auto;

  @media all and (min-width: 375px) {
    max-width: 350px;
  }

  @media all and (min-width: 580px) {
    max-width: 550px;
  }

  @media all and (min-width: 700px) {
    max-width: 650px;
  }

  .filter,
  .filter:link,
  .filter:visited,
  .filter:hover {
    color: black;
    background: color(grey-extra-light);
    padding: 2px 8px;
    display: inline-block;
    margin: 2px 1px;
    cursor: pointer;
    font-size: .875rem;

    &::after {
      content: 'X';
      padding-left: 3px;
      color: color('grey');
    }
  }
}

body .node-form .form-wrapper.form-field-type-file {

  .form-type-managed-file {
    margin-bottom: 0;
  }
}

.node .fieldset-wrapper .field-type-file {

  .file a {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    word-break: break-all;
    max-width: calc( 100% - 18px );
    display: inline-block;
  }
}

.node-webform.node-195 {
  max-width: 640px;

  .webform-client-form {

    .webform-component-textfield,
    .webform-component-email {
      display: inline-block;
      max-width: 295px;
      width: 100%;
      text-align: left;
      margin: 1rem 0;

      input {
        width: 100%;
      }
    }
  }
}

.node-organization-form {

  .form-wrapper {
    margin: 1.7rem 0;
  }

  .form-field-name-field-logo {

    .description {
      visibility: hidden;
      font-size: 0;
      line-height: 0;

      &::after {
        content: 'Minimum size is 200px by 200px.';
        visibility: visible;
        font-size: .875rem;
        line-height: 1.2rem;
        padding-top: 5px;
      }
    }
  }
}

.page-user-register-trial,
.page-user-register-purchase {

  #edit-field-linkedin {
    display: none;
  }

  .hybridauth-widget-wrapper {
    display: none;
  }

  #user-register-form {
    padding-top: 5px;
  }

  h1::after {
    content: 'A no-commitment 14-day free trial will be automatically activated for you.';
    font-size: 1rem;
    display: block;
    padding-top: 0;
    font-weight: 400;
  }
}

.page-user-register-purchase {

  h1::after {
    display: none;
  }
}

.node-job-posting .field-name-field-city-state .field-items .field-item::before {
  display: none;
}

.node-book,
.node-page {

  .field-name-body {

    h2 {
      color: color(orange);
      margin: 1.5rem 0 .5rem;
    }

    h3 {
      margin: 1.5rem 0 .5rem;
    }

    p {
      margin: .5rem 0 1.5rem;
    }
  }
}

.node-16,
.node-3,
.node-218,
.node-378,
.node-2674,
.node-3852 {
  padding: 0;

  .field-name-body {

    h2 {
      color: white;
      margin: 0 auto 1rem;
    }
  }

  .features.anonymous h2 {
    background: none;
    color: black;
  }

  #block-block-6 {

    h2 {
      color: black;
    }
  }
}

#block-block-13 {
  margin: auto;
  margin-top: 30px;
  max-width: 700px;
  padding: 20px;
  background: #f9f9f9;

  .block__title {
    color: black;
  }

  ul {
    text-align: left;

    li {
      margin: .5rem 0;
    }
  }
}

.node .book-navigation {

  .page-links {
    display: none;
  }
}

#search-api-saved-searches-save-form::before {
  content: 'Notifications will be based on the search you just performed';
  font-size: 1rem;
  color: black;
  padding-top: 20px;
  font-style: italic;
  display: block;
  max-width: 300px;
  margin: auto;
}

.layout-3col__left-sidebar {

  #block-book-explorer-book-explorer {
    background: color(blue);
    padding: 20px;

    .block__title {
      margin: 0 0 .5rem;
    }

    .book-title,
    .book-title:link,
    .book-title:visited
    .book-title:hover {
      color: white;
      font-size: 24px;
    }

    a,
    a:link,
    a:visited,
    a:hover {
      color: white;
    }

    .menu__link.active,
    .menu__link.active:link,
    .menu__link.active:visited,
    .menu__link.active:hover {
      color: black;
    }
  }
}

.footer-text-2 {

  .text-links {
    padding-top: 10px;
  }
}

.book-explorer {

  ul.menu li {
    background: none;
    border-top: 1px solid rgba(255, 255, 255, .2);
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .book-explorer-toggle {
    background: none;
    position: relative;
    width: 0;
    height: 1.5rem;
    margin-top: 7px;

    &::before {
      content: '\f055';
      font-family: FontAwesome;
      display: block;
      width: 16px;
      font-size: 16px;
      color: white;
    }

    &.book-explorer-expanded::before {
      content: '\f146';
    }
  }
}

.view-company-info-for-job h2.title {
  padding-top: 1.5rem;
  margin-bottom: 0;
}

.page-node-3,
.page-node-3852 {

  .region-bottom {
    margin-top: 0;
  }

  .top-right.employers {
    display: none;
  }
}

#block-views-nonprofits-hiring-now-block-1,
#block-views-nonprofits-hiring-now-block-2 {
  max-width: 1100px;
  margin: auto;

  .view-content {
    text-align: center;
  }

  .views-row {
    display: inline-block;
    width: 90px;
    margin: 14px;
    height: 100px;
    vertical-align: top;
    font: 0/0 a;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    img {
      width: auto;
      height: auto;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      font: 16px/1 Arial sans-serif;
    }
  }
}

.page-node-3 #block-views-testimonial-block-1,
.page-node-3852 #block-views-testimonial-block-1 {
  padding-top: 0;

  .view-testimonial {
    color: black;

    .view-content {
      background: #f3f3f3;

      .credit .jobtitle {

        &::after {
          content: ',';
          padding-right: 5px;
        }
      }

      .quote {
        color: black;

        &::before,
        &::after {
          color: color(orange);
        }
      }
    }
  }
}

#block-block-14 {
  padding-bottom: 0;

  .button {
    transform: scale(1.1);
    padding: 10px 25px;
    margin-bottom: 20px;
  }
}

body .node-3,
body .node-2674,
body .node-3852 {

  @media all and (min-width: 1100px) {

    #block-block-6 .inner-block-6 .block-6-text {
      padding-left: 50px;
      padding-right: 30px;
    }

    #block-block-6 .inner-block-6 .recruiterdash {
      padding-right: 50px;
      padding-left: 30px;
    }
  }

  li {
    margin: 8px 0;
    list-style: none;

    &::before {
      content: '\f00c';
      color: color(orange);
      padding-right: 10px;
      font-family: 'FontAwesome';
      margin-left: -27px;
    }
  }

  .intro-text {
    font-size: 1.2rem;
    line-height: 1.8em;
    max-width: 840px;
    font-weight: 300;
    text-align: center;
    padding: 30px 20px 0;

    span {
      padding-right: 5px;
    }

    @media all and (min-width: 900px) {

      span {
        display: block;
        padding: 0;
        margin-bottom: .5rem;
      }
    }
  }
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main {

  .price_set-section .additional_jobs-section {
    margin: 0;

    .label {
      margin: 5px 0 0;
    }

    label {
      margin-top: 0;
    }
  }

  .price_set-section .section-pricesetTotal {
    margin: 10px 0 20px;
  }

  .additional_jobs-content {
    padding-top: 0;
  }

  .payment_options-group {

    .payment_processor-section {

      .label {
        display: none;
      }
    }
  }

  .billing_mode-group {
    padding-top: 10px;

    .crm-section {
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;

      .creditcard {
        max-width: 180px;
      }

      #cvv2 {
        width: 70px;
      }

      .crm-error {
        background: none;
        color: black;
      }

      input.error,
      .select2-container.error {
        border: 1px solid red;
        background: #FBE3E4;
      }

      .crm-marker {
        display: none;
      }

      .content .crm-error {
        display: block;
        width: 100%;
      }
    }

    .credit_card_type-section {
      width: 120px;
      padding-top: 20px;

      .crm-credit_card_type-icons a {
        margin: 3px;
      }
    }

    .credit_card_number-section {
      margin-right: 10px;
    }

    .cvv2-section {
      width: 130px;

      .description {
        visibility: hidden;
        font-size: 0;
        line-height: 0;

        &::after {
          background: url('../images/ccv2.jpg') no-repeat left top;
          width: 100px;
          height: 40px;
          display: block;
          background-size: contain;
          content: '';
          visibility: visible;
        }
      }
    }
  }

  .billing_name_address-group {

    .crm-section {
      display: inline-block;
      vertical-align: top;

      .content .crm-error {
        display: block;
        width: 100%;
      }

      .crm-error {
        background: none;
        color: black;
      }

      input.error,
      .select2-container.error {
        border: 1px solid red;
        background: #FBE3E4;
      }

      .crm-marker {
        display: none;
      }
    }

    .billing_middle_name-section,
    .billing_country_id-5-section {
      display: none;
    }

    .billing_first_name-section,
    .billing_last_name-section {

      .label {

        label {
          visibility: hidden;
          font-size: 0;
          line-height: 0;

          &::after {
            content: 'First Name';
            visibility: visible;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .billing_last_name-section .label label::after {
      content: 'Last Name';
    }
  }

  .crm-form-submit {
    transform: scale(1.1);
    padding: 10px 25px;
  }

  .billing_state_province_id-5-section {
    width: 100%;
    max-width: 300px;
  }
}

#crm-container.crm-public .crm-contribution-page-id-2 {

  #allow_auto_renew {
    display: none;
  }

  .membership-group .membership_amount-section {
    margin-bottom: 20px;
  }
}

#crm-container.crm-public .crm-contribution-thankyou-form-block {
  padding-bottom: 20px;
  background: #f3f3f3;

  .billing_name_address-group,
  .contributor_email-group {
    padding: 0 25px 5px;
  }

  .help {
    display: block;
    margin: 0;
    border-radius: 0;
    max-width: 700px;
    padding-top: 1px;

    p,
    div {
      margin: 1rem 0;
    }
  }

  #thankyou_text {
    margin-bottom: 0;

    .help {
      padding-bottom: 0;
      padding-top: 20px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .amount_display-group {
    padding-bottom: 10px;
  }
}

#colorbox {

  #user-login,
  #user-register-form,
  #user-pass {
    padding-bottom: 0;
  }
}

.view-nonprofit-jobs-index .view-header .button {
  background: color(yellow);
}

.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .local-jobs .views-row .easy-apply-1 {
  color: color('grey');
}

#block-views-expired-listing-block {

  .attachment .view {

    .view-header h3,
    .view-footer {
      text-align: center;
    }

    .view-footer {
      margin-top: 20px;
    }

    .view-content {
    text-align: center;

      .views-row {
        display: inline-block;
        margin: 4px 2px;
        text-align: left;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.node-form #edit-field-cover-photo-template .form-type-radio {
  padding: 1px;
  border-radius: 0px;
  width: 220px;
  margin: 0 1px;
}

.page-user main .block .views-row .messages {
  width: 100%;
  max-width: 650px;

  .button,
  .button:link,
  .button:visited,
  .button:hover {
    padding: 5px 15px;
    background: color(orange);
    color: white;
    display: inline-block;
  }

  .button:hover {
    background: color(blue);
  }
}

.page-user main .block .view-user-profile.view-display-id-block_3 .views-row {
  max-width: 100%;
}

#replicate-ui-confirm {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.role-Employer.page-user #block-views-exp-nonprofit-jobs-index-page {
  display: none;
}

#block-views-contact-from-help-block {
  padding-top: 0;
}

.page-taxonomy-term.vocabulary-job_function {

  main h1 {
    display: block;
    margin-bottom: 1.5rem;

    &::before {
      content: 'Nonprofit';
      padding-right: 7px;
    }

    &::after {
      content: 'Jobs';
      padding-left: 7px;
    }
  }
}

.page-taxonomy-term.vocabulary-area_of_focus {

  h1 {

    &::after {
      content: 'Jobs';
      padding-left: 7px;
    }
  }
}

.node-job-posting {

  .field-name-field-skills,
  .field-name-field-requirements-or-qualificat {

    .field-items {
      padding-left: 30px;

      .field-item {
        margin: 10px 0;

        &::before {
          margin-left: -20px;
          padding-left: 0;
          padding-right: 5px;
          font-family: FontAwesome;
          content: '\f00c';
        }
      }
    }
  }

  .field-name-field-how-to-apply {
    overflow: hidden;
    max-width: 100%;
  }
}

.field {

  .field-item {

    ul,
    ul li {
      list-style: none;
    }

    ul li {
      margin: 10px 0;

      &::before {
        margin-left: -20px;
        padding-left: 0;
        padding-right: 5px;
        font-family: FontAwesome;
        content: '\f00c';
        color: color('orange');
      }

      li::before {
        content: '\f1db';
      }
    }
  }
}

#user-profile-form {

  @media all and (max-width: 850px) {

    .form-type-password {
      width: 100%;
      overflow: hidden;

      .password-strength {
        display: none;
      }

      .password-confirm {
        margin-top: 0;
      }
    }
  }
}

.node-type-organization {
  #block-block-16 {
    display: none;

    @media all and (min-width: 1001px) {
      display: block;
    }
  }

  #block-block-17 {
    background: none;

    @media all and (min-width: 1001px) {
      display: none;
    }
  }
}

.page-nonprofit-jobs-region.page-views {

  h1 {
    margin-top: 90px;
  }

  .local-jobs {

    .view-filters {
      display: block;
      position: absolute;
      width: 100%;
      top: -150px;
    }
  }
}

.view-job-function-sidebar,
.view-areas-of-focus-sidebar {

  .views-row .active {
    background: #cccccc;
    color: white;
  }
}

.webform-component-civicrm-contact ul.token-input-list {
  border-color: #ddd;
  max-width: 500px;
}

.webform-component-textarea textarea {
  border: 1px solid #ddd;
}

.node-webform.node-400 {

  @media all and (min-width: 768px) {

    .webform-component--civicrm-1-contact-1-contact-first-name,
    .webform-component--civicrm-1-contact-1-contact-last-name,
    .webform-component--civicrm-1-contact-1-phone-phone,
    .webform-component--civicrm-1-contact-1-phone-phone-ext,
    .webform-component--civicrm-1-contact-1-email-email,
    .webform-component--civicrm-1-contact-1-contact-job-title {
      display: inline-block;
      max-width: 49%;
      vertical-align: top;
    }
  }
}

#block-block-18,
#block-block-26 {
  padding: 0;

  .features h2 {
    background: none;
  }
}

.page-node-3.role-anonymous-user,
.page-node-3852.role-anonymous-user {

  #block-block-18,
  #block-block-26 {
    padding-bottom: 30px;

    hr {
      display: none;
    }

    .prices::before {
      height: 50px;
      line-height: 50px;
      background: #999999;
      color: white;
    }
  }
}

#block-block-26 {
  padding-top: 40px;
  background: #e9e9e9;

  .prices {
    padding-top: 0;

    &::before {
      display: none;
    }
  }
}

#block-block-19 {
  padding: 0;
}

.page-node-2674 {

  .employer-header {
    background-image: url(/sites/all/themes/natlnp/images/jobseekers.jpg);

    @media all and (min-width: 700px) {
      background-position: center -100px;
    }

    @media all and (min-width: 800px) {
      background-position: center -200px;
    }

    @media all and (min-width: 1000px) {
      background-position: center -300px;
    }

    @media all and (min-width: 1150px) {
      background-position: center -400px;
    }
  }

  #block-block-6 {
    text-align: center;
    padding-bottom: 40px;

    .block-6-container {
    
      .inner-block-6,
      .inner-block-6:link,
      .inner-block-6:visited,
      .inner-block-6:hover {
        display: block;
        color: black;
        vertical-align: top;
        background: white;
        margin-bottom: 10px;

          h4 {
            margin-bottom: .5rem;
          }

          .block-6-text {
            margin-bottom: 10px;
          }
      }

      .inner-block-6:hover {
        border-color: color(blue);
      }

      .block-6-text {
        padding-left: 10px;
        padding-top: 10px;
        max-width: 100%;
        float: none;
      }

      @media all and (min-width: 900px) { 
        display: flex;
        align-items: self-start;
        justify-content: space-evenly;

        .inner-block-6,
        .inner-block-6:link,
        .inner-block-6:visited,
        .inner-block-6:hover {
          flex-basis: 30%;
          align-self: stretch;
          margin-bottom: 0;
          margin: 0;
          color: black;
          border: 1px solid white;
        }

        .inner-block-6:hover {
          border-color: color(blue);
        }
      }
    }
  }

  .region-bottom {
    margin-top: 0;
  }
}

.node-article {

  h2 {
    color: color(orange);
  }

  .field-name-field-resources-for {

    .field-item {
      display: inline;
    }
  }
}

#block-block-23 {
  margin: 0 auto;
  max-width: 600px;
  background: white;

  iframe {
    margin: auto;
  }
}

.vocabulary-resources main {

  h1 {
    display: block;
    margin-bottom: 30px;

    &::before {
      content: 'Resources for';
      margin-right: .4em;
    }
  }

  .view-resources-taxonomy {

    .view-content {
      max-width: 100%;
      padding: 0 20px;

      a,
      a:link,
      a:visited
      a:hover {
        display: block;
        color: black;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid color(grey-light);

        @media all and (min-width: 850px) {
          text-align: left;
        }

        img {
          max-width: 350px;
          width: 100%;
          display: inline-block;
          margin: auto;
          vertical-align: top;
        }

        .resource-content {
          display: inline-block;
          text-align: left;
          width: 100%;
          vertical-align: top;
          margin-top: 10px;

          p {
            margin: 0 0 10px;
          }

          h2 {
            color: color(orange);
            margin: 0 0 5px;
          }

          @media all and (min-width: 850px) {
            width: calc( 100% - 380px);
            margin-left: 25px;
            margin-top: 0;
          }
        }
      }

      a:hover {
        border-color: color(blue);

        .resource-content h2 {
         color: color(blue);
        }
      }
    }
  }
}

#block-views-resources-taxonomy-block-1,
#block-views-resources-taxonomy-block-2 {
  background: #f3f3f3;

  .view-resources-taxonomy {
    max-width: 1200px;
    margin: auto;

    .view-footer {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .views-row {

      margin: 0 auto 20px;
      max-width: 400px;
      background: white;
      border: 1px solid #f3f3f3;

      a,
      a:link,
      a:visited,
      a:hover {
        display: block;
        height: 100%;
        color: black;
        

        h2 {
          color: color(orange);
          margin: 10px 0 5px;
        }

        p {
          margin: 0 0 10px;
        }

        .resource-content {
          padding: 0 10px;
        }

        img {
          width: 100%;
          display: block;
        }
      }
    }

    .views-row:hover {
      border-color: color(blue);

      h2 {
        color: color(blue);
      }
    }

    @media all and (min-width: 900px) {

      .view-content {
        display: flex;
        align-items: self-start;
        justify-content: space-evenly;
      }

      .views-row {
        flex-basis: 30%;
        align-self: stretch;
        margin: 0;
        max-width: 30%;
      }
    }
  }
}

.node-type-job-posting {

  #block-block-16 {
    padding: 0;

    p.centered {
      margin: 0 auto;
    }
  }

  #block-views-exp-nonprofit-jobs-index-page {
    top: 20px;
    margin-top: 0;

    h2.block__title {
      display: none;
    }
  }

  .layout-center {
    margin-top: 160px;
  }

  input#edit-keyword.form-text {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  input.form-text#edit-location {
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  @media all and (min-width: 580px) {

    .layout-center {
      margin-top: 100px;
    }

    #block-views-exp-nonprofit-jobs-index-page {

      input#edit-keyword.form-text {
        border-right: 0;
        border-bottom: 1px solid #ccc;
      }

      input.form-text#edit-location {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
      }
    }
  }
}

.view-company-info-for-job .field-name-field-website {
  margin-right: 5px;

  .field-label,
  .field-items {
    display: inline-block;
    padding-left: 0;
  }

  .field-label {
    padding-right: 10px;
  }

  .field-items {

    a {
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
    }
  }
}

.stepsgraph {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 10px 40px;

  .step {
    background: color(blue);
    border-radius: 50%;
    width: 150px;
    height: 150px; 
    display: inline-block;
    margin: 10px;
    position: relative;
    vertical-align: top;

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 150px;
    }

    &::before {
      content: '1';
      line-height: 120px; 
      font-size: 120px;
      text-align: center;
      position: absolute;
      top: 50%;
      margin-top: -60px;
      left: 0;
      display: block;
      width: 100%;
      height: 120px;
      color: white;
      opacity: .13;
    }

    &.graph2 {
      // background: color(yellow);

      &::before {
        content: '2';
      }
    }

    &.graph3 {
      // background: color(yellow);

      &::before {
        content: '3';
      }
    }

    &.graph4 {
      // background: color(orange);

      &::before {
        content: '4';
      }
    }

    span {
      line-height: 1em;
      font-size: 1.3em;
      padding: 0 5px;
      font-weight: 700;
      transform: translateY(-50%);
      top: 50%;
      position: relative;
      display: inline-block;
      color: white;
    }
  }
}

#edit-field-trial {
  display: none;
}

#block-block-28 {
  position: absolute;
  top: 120px;
  width: 100%;

  @media all and (min-width: 580px) {
    top: 70px;
  }
}