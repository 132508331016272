@charset "UTF-8";
/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Open Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #2ab8c6;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #2ab8c6;
}

a:active {
  color: #e96803;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fe9c20;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #f3f3f3;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'],
.more-link a {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #e96803;
  border: 0;
  margin: 5px;
  border-radius: 3px;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'],
.more-link a {
  display: inline-block;
  padding: 5px 15px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
.more-link a::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
.more-link a:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active,
.more-link a:hover,
.more-link a:focus,
.more-link a:active {
  text-decoration: none;
  color: #fff;
  background: #2dbfcd;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'],
.more-link a[disabled] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover,
.more-link a[disabled]:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus,
.more-link a[disabled]:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active,
.more-link a[disabled]:active {
  color: #999999;
}

.form-actions {
  text-align: center;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #f3f3f3;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #f3f3f3;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #2ab8c6;
}

.clearfix::before,
.header::before,
.tabs::before {
  content: '';
  display: table;
}

.clearfix::after,
.header::after,
.tabs::after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffdfa;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #2dbfcd;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #2dbfcd;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: white;
  color: #2dbfcd;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: white;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fe9c20;
}

.messages--warning path, .messages.warning path {
  fill: #fe9c20;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #f3f3f3;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #f3f3f3 \0/ie;
  background-image: linear-gradient(to top, #f3f3f3 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #f3f3f3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #f3f3f3 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #f3f3f3;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #2dbfcd;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #f3f3f3;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #f3f3f3;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #f3f3f3;
  background-color: #f3f3f3;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #e2e2e2;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: white;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #2dbfcd url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #f3f3f3;
  border: 1px solid #f3f3f3;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffdfa;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff2e1;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.crm-container a,
.crm-container a:link,
.crm-container a:visited,
.crm-container a:hover {
  color: #2ab8c6;
}

#crm-container.crm-public form {
  border-radius: 0;
  padding: 0;
  background: transparent;
  max-width: 700px;
  margin: auto;
}

@media screen and (min-width: 480px) {
  #crm-container.crm-public form {
    padding: 1rem;
  }
}

#crm-container.crm-public form .billing_country_id-5-section {
  display: none;
}

#crm-container.crm-public form fieldset {
  background-color: #f3f3f3;
  border: 0;
}

#crm-container.crm-public form fieldset fieldset.date-combo {
  padding: 0;
}

#crm-container.crm-public form label,
#crm-container.crm-public form .label label {
  font-weight: bold;
  font-size: 1rem;
  color: black;
}

#crm-container.crm-public form button,
#crm-container.crm-public form input,
#crm-container.crm-public form optgroup,
#crm-container.crm-public form select,
#crm-container.crm-public form textarea {
  margin-bottom: 4px;
}

#crm-container.crm-public form input[type='text'],
#crm-container.crm-public form input[type='search'],
#crm-container.crm-public form input[type='tel'],
#crm-container.crm-public form input[type='url'],
#crm-container.crm-public form input[type='email'],
#crm-container.crm-public form input[type='password'],
#crm-container.crm-public form input[type='number'] {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  width: 100%;
  background-color: white;
  border: 1px solid #cccccc;
  max-width: 400px;
  padding-left: .5rem;
  font-size: 1rem;
  -webkit-appearance: none;
}

#crm-container.crm-public form input[type='password'],
#crm-container.crm-public form input[type='text'],
#crm-container.crm-public form input[type='url'],
#crm-container.crm-public form input[type='email'] {
  max-width: 300px;
}

#crm-container.crm-public form input[type='tel'],
#crm-container.crm-public form input[type='number'] {
  max-width: 150px;
}

#crm-container.crm-public form .webform-component--location--state input[type='text'],
#crm-container.crm-public form .webform-component--location--zip-code input[type='text'],
#crm-container.crm-public form .webform-component--cost input[type='text'] {
  max-width: 150px;
}

#crm-container.crm-public form .select2-focusser {
  max-width: 100%;
}

#crm-container.crm-public form #cvv2 {
  width: 100px;
}

#crm-container.crm-public form .select2-container .select2-choice {
  height: 40px;
}

#crm-container.crm-public form textarea {
  border-radius: 0;
  line-height: 1.5em;
  width: 100%;
  background-color: white;
  border: 1px solid #f3f3f3;
  padding-left: .5rem;
  height: auto;
}

#crm-container.crm-public form select {
  height: 40px;
  line-height: 40px;
  width: auto;
  max-width: 280px;
}

@media all and (min-width: 500px) {
  #crm-container.crm-public form select {
    width: auto;
    max-width: 100%;
  }
  #crm-container.crm-public form select.crm-select2, #crm-container.crm-public form select.crm-form-date {
    width: auto;
    max-width: 100%;
  }
}

#crm-container.crm-public form .form-radios .form-item,
#crm-container.crm-public form .form-checkboxes .form-item {
  display: inline-block;
  width: 49%;
  min-width: 120px;
  margin: .3rem 0 0;
  vertical-align: top;
}

#crm-container.crm-public form .form-radios .form-item label.option,
#crm-container.crm-public form .form-checkboxes .form-item label.option {
  font-weight: normal;
}

#crm-container.crm-public form .webform-component-time .form-radios .form-item {
  display: inline;
  width: 100%;
}

#crm-container.crm-public form .form-radios,
#crm-container.crm-public form .webform-container-inline .form-radios {
  display: inline-block;
  min-width: 120px;
}

#crm-container.crm-public form input.year,
#crm-container.crm-public form select.year,
#crm-container.crm-public form select.month,
#crm-container.crm-public form select.day {
  width: 31%;
  max-width: 120px;
}

#crm-container.crm-public form select.minute,
#crm-container.crm-public form select.hour {
  width: 46%;
  max-width: 120px;
}

#crm-container.crm-public form .form-item-field-cost-und-0-value .form-text,
#crm-container.crm-public form .form-item-field-cost-und-0-value .field-prefix {
  display: inline-block;
}

#crm-container.crm-public form .field-widget-link-field {
  margin: 1.2rem 0;
}

#crm-container.crm-public form .field-widget-link-field .form-item {
  margin: 0;
}

#crm-container.crm-public form #edit-actions {
  background: none;
  text-align: center;
}

#crm-container.crm-public form .field-type-addressfield .form-item-field-location-und-0-country {
  display: none;
}

#crm-container.crm-public form label[for='edit-field-start-date-and-time-und-0-value2'] {
  padding: 5px;
  background: #cccccc;
  margin: 5px 0;
}

#crm-container.crm-public form .date-padding .form-item {
  margin-bottom: 10px;
}

#crm-container.crm-public form .webform-component-fieldset,
#crm-container.crm-public form .form-wrapper,
#crm-container.crm-public form fieldset {
  background: #f3f3f3;
}

#crm-container.crm-public form .webform-component-fieldset input[type='text'],
#crm-container.crm-public form .webform-component-fieldset input[type='email'],
#crm-container.crm-public form .webform-component-fieldset input[type='tel'],
#crm-container.crm-public form .webform-component-fieldset textarea,
#crm-container.crm-public form .form-wrapper input[type='text'],
#crm-container.crm-public form .form-wrapper input[type='email'],
#crm-container.crm-public form .form-wrapper input[type='tel'],
#crm-container.crm-public form .form-wrapper textarea,
#crm-container.crm-public form fieldset input[type='text'],
#crm-container.crm-public form fieldset input[type='email'],
#crm-container.crm-public form fieldset input[type='tel'],
#crm-container.crm-public form fieldset textarea {
  background: white;
}

#crm-container.crm-public form .webform-component-fieldset legend,
#crm-container.crm-public form .form-wrapper legend,
#crm-container.crm-public form fieldset legend {
  border: 0;
  background: #fe9c20;
  color: white;
  font-size: 1rem;
}

#crm-container.crm-public form .webform-component-fieldset fieldset,
#crm-container.crm-public form .form-wrapper fieldset,
#crm-container.crm-public form fieldset fieldset {
  border: 1px solid #ccc;
}

#crm-container.crm-public form .crm-section .label,
#crm-container.crm-public form .crm-section .label label {
  font-size: 1rem;
  float: none;
  color: black;
  line-height: 1.5em;
}

#crm-container.crm-public form .price_set-section .price-set-option-content label {
  font-weight: normal;
}

#crm-container.crm-public form .price_set-section .price-set-option-content label .crm-price-amount-label {
  font-weight: normal;
}

#crm-container.crm-public form .price_set-section .section-pricesetTotal {
  background: white;
  margin: 10px 0;
  text-align: center;
  padding: 10px;
}

#crm-container.crm-public form .price_set-section .section-pricesetTotal label,
#crm-container.crm-public form .price_set-section .section-pricesetTotal .label label,
#crm-container.crm-public form .price_set-section .section-pricesetTotal .label {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

#crm-container.crm-public form .price_set-section .label {
  margin-bottom: 10px;
}

#crm-container.crm-public form .price_set-section .crm-section {
  margin: 30px 0;
}

#crm-container.crm-public form .price_set-section .one_job_listing-section {
  margin-top: 0;
}

#crm-container.crm-public form .price_set-section .one_job_listing-section .label {
  display: none;
}

#crm-container.crm-public form .price_set-section .crm-form-radio {
  display: none;
}

#crm-container.crm-public form .price_set-section .price-set-option-content {
  background: #cccccc;
  padding: 5px 15px;
  text-align: center;
  display: block;
  margin: 2px;
}

#crm-container.crm-public form .price_set-section .price-set-option-content.highlight {
  background: #2dbfcd;
}

#crm-container.crm-public form .price_set-section .price-set-option-content.highlight label {
  color: white;
}

#crm-container.crm-public form .price_set-section .price-set-option-content label {
  padding-right: 0;
}

#crm-container.crm-public form .additional_jobs-section {
  text-align: center;
}

#crm-container.crm-public form .additional_jobs-section #price_3 {
  width: 100px;
}

#crm-container.crm-public form .additional_jobs-section .label label {
  display: block;
  text-align: center;
}

#crm-container.crm-public form .crm-contribution-page-id-6 .buy_job_listings-section {
  margin: 0 0 30px;
}

#crm-container.crm-public form .crm-contribution-page-id-6 .buy_job_listings-content {
  text-align: center;
}

#crm-container.crm-public form .crm-contribution-page-id-6 .buy_job_listings-content .price-set-option-content label {
  display: block;
}

#crm-container.crm-public form .crm-contribution-page-id-6 .buy_job_listings-content .price-set-row {
  max-width: 320px;
  display: inline-block;
  width: 100%;
  margin-bottom: 3px;
}

.select2-drop,
.select2-container {
  max-width: 238px !important;
}

#crm-container.crm-public {
  padding: 0;
  max-width: 1200px;
  margin: 20px auto;
}

#crm-container.crm-public div#printer-friendly {
  display: none;
}

#crm-container.crm-public fieldset,
#crm-container.crm-public #priceset-div,
#crm-container.crm-public .is_recur-section,
#crm-container.crm-public .email-5-section,
#crm-container.crm-public .amount_display-group,
#crm-container.crm-public .contributor_email-group {
  background-color: #f3f3f3;
  padding: 25px;
  margin-top: 25px;
}

#crm-container.crm-public .billing_name_address-group,
#crm-container.crm-public .credit_card-group {
  margin-top: 25px;
  background-color: #f3f3f3;
}

#crm-container.crm-public #priceset-div {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 50px;
}

#crm-container.crm-public #priceset-div::before {
  content: 'Your Order';
  font-family: inherit;
  font-size: 1rem;
  color: white;
  background-color: #fe9c20;
  padding: 5px 15px;
  font-weight: normal;
  position: relative;
  top: -18px;
}

#crm-container.crm-public .is_recur-section {
  margin-top: 0;
  padding-top: 0;
}

#crm-container.crm-public .email-5-section {
  margin-top: 0;
  padding: 0;
}

#crm-container.crm-public .is_recur-section .label {
  display: none;
}

#crm-container.crm-public fieldset {
  margin-bottom: 20px;
  border: 0;
}

#crm-container.crm-public fieldset legend {
  font-size: 1rem;
  color: white;
  background-color: #fe9c20;
  font-weight: normal;
  font-family: inherit;
  padding: 5px 15px;
}

#crm-container.crm-public tbody tr {
  border-bottom: 0;
}

#crm-container.crm-public tbody tr:nth-child(2) {
  background-color: transparent;
}

#crm-container.crm-public img {
  height: auto;
}

#crm-container.crm-public input,
#crm-container.crm-public #civicrm-menu input {
  box-sizing: border-box;
}

#crm-container.crm-public .crm-actionlinks-top::after {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

#crm-container.crm-public label {
  padding-right: 14px;
}

#crm-container.crm-public .credit_card_info-group,
#crm-container.crm-public .crm-section {
  max-width: 100%;
  margin: 0 auto 5px;
}

#crm-container.crm-public .credit_card_info-group .label,
#crm-container.crm-public .credit_card_info-group .label label,
#crm-container.crm-public .crm-section .label,
#crm-container.crm-public .crm-section .label label {
  width: 100%;
  text-align: left;
  font-size: 1.8rem;
  margin-top: 1rem;
  padding: 0;
  color: #e96803;
  background: none;
}

#crm-container.crm-public .credit_card_info-group .content,
#crm-container.crm-public .crm-section .content {
  margin-left: 0;
}

#crm-container.crm-public #payment_information label {
  padding-right: 1px;
}

#crm-container.crm-public .crm-submit-buttons {
  text-align: center;
  display: table;
}

#crm-container.crm-public .crm-group .header-dark {
  background-color: transparent;
  line-height: 1.5em;
  color: #e96803;
  font-size: 2rem;
  font-weight: normal;
  padding: 0;
  margin: 1em 0 .2em;
  border-radius: 0;
}

#crm-container.crm-public i.crm-i {
  display: none;
}

#crm-container.crm-public span.crm-button {
  float: none;
  padding: 0;
  border-radius: 0;
  text-shadow: none;
  background: none;
  border: 0;
  display: block;
  width: 100%;
  text-align: center;
}

#crm-container.crm-public .ui-dialog-buttonset .ui-button,
#crm-container.crm-public a.button,
#crm-container.crm-public a.crm-invoiceButton,
#crm-container.crm-public a.crm-invoiceButton:link,
#crm-container.crm-public a.button:link,
#crm-container.crm-public a.button:visited,
#crm-container.crm-public input.crm-form-submit {
  border-radius: 0;
  -webkit-appearance: none;
  display: inline-block;
  float: none;
  padding: 5px 15px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: white;
  border: 0;
  font-weight: 300;
  font-size: 1.1rem;
  text-shadow: none;
  background: #e96803;
  margin: 5px auto;
}

#crm-container.crm-public .ui-dialog-buttonset .ui-button:hover, #crm-container.crm-public .ui-dialog-buttonset .ui-button:focus, #crm-container.crm-public .ui-dialog-buttonset .ui-button:active,
#crm-container.crm-public a.button:hover,
#crm-container.crm-public a.button:focus,
#crm-container.crm-public a.button:active,
#crm-container.crm-public a.crm-invoiceButton:hover,
#crm-container.crm-public a.crm-invoiceButton:focus,
#crm-container.crm-public a.crm-invoiceButton:active,
#crm-container.crm-public a.crm-invoiceButton:link:hover,
#crm-container.crm-public a.crm-invoiceButton:link:focus,
#crm-container.crm-public a.crm-invoiceButton:link:active,
#crm-container.crm-public a.button:link:hover,
#crm-container.crm-public a.button:link:focus,
#crm-container.crm-public a.button:link:active,
#crm-container.crm-public a.button:visited:hover,
#crm-container.crm-public a.button:visited:focus,
#crm-container.crm-public a.button:visited:active,
#crm-container.crm-public input.crm-form-submit:hover,
#crm-container.crm-public input.crm-form-submit:focus,
#crm-container.crm-public input.crm-form-submit:active {
  text-decoration: none;
  color: white;
  background-color: #2dbfcd;
}

#crm-container.crm-public input.crm-form-submit.cancel,
#crm-container.crm-public .crm-button_qf_Confirm_back,
#crm-container.crm-public a.button.cancel,
#crm-container.crm-public a.button.cancel:link,
#crm-container.crm-public a.button.cancel:visited,
#crm-container.crm-public a.button.cancel:hover {
  background-color: #f3f3f3;
  color: black;
  margin-left: 9px;
}

#crm-container.crm-public .crm-button_qf_Confirm_back input.crm-form-submit.cancel {
  background: none;
}

#crm-container.crm-public .crm-button_qf_Confirm_back input.crm-form-submit.cancel:hover {
  color: white;
}

#crm-container.crm-public .crm-button.crm-icon-button {
  padding: 0;
}

#crm-container.crm-public .crm-button-icon {
  margin-top: 5px;
  display: none;
}

#crm-container.crm-public input#frequency_interval {
  max-width: 54px;
}

#crm-container.crm-public input.crm-form-text,
#crm-container.crm-public input.dateplugin {
  height: 40px;
  line-height: 40px;
}

#crm-container.crm-public input[type='checkbox'],
#crm-container.crm-public input[type='radio'] {
  margin-right: 5px;
  width: initial;
}

#crm-container.crm-public div.display-block {
  margin: 1em 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .header-dark {
  margin-top: 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .billing_name_address-group,
#crm-container.crm-public .crm-contribution-thankyou-form-block .credit_card-group {
  margin: 0;
  padding: 25px;
}

.crm-block .help,
.crm-block #help {
  background: #bce4de;
}

.crm-block .warning {
  background: #ffe79d;
}

.crm-block .error {
  background: #fff0f0;
}

body {
  position: relative;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

.logged-in .anonymous {
  display: none;
}

img {
  display: block;
  margin: auto;
}

img.file-icon {
  display: inline;
}

.layout-swap__top {
  display: none;
}

.layout-swap {
  padding-top: 0;
}

.region-header {
  border-bottom: 1px solid #2dbfcd;
  background: #2dbfcd;
}

.region-header .block {
  padding: 10px 5px;
  width: 100%;
  height: 57px;
  text-align: right;
}

.region-header .block a,
.region-header .block a:link,
.region-header .block a:visited,
.region-header .block a:hover {
  color: white;
  text-decoration: none;
  line-height: 1em;
  vertical-align: middle;
  font-size: .85em;
  display: inline-block;
  text-align: center;
}

.region-header .block a.findjobs,
.region-header .block a:link.findjobs,
.region-header .block a:visited.findjobs,
.region-header .block a:hover.findjobs {
  display: none;
}

@media all and (min-width: 375px) {
  .region-header .block a,
  .region-header .block a:link,
  .region-header .block a:visited,
  .region-header .block a:hover {
    font-size: .875em;
  }
}

.region-header .block a:hover {
  color: #f9f9f9;
  text-decoration: underline;
  border-color: #f9f9f9;
}

.region-header .block .signin {
  white-space: nowrap;
  padding-right: 15px;
}

.region-header .block .signin span {
  display: none;
}

@media all and (min-width: 480px) {
  .region-header .block .signin span {
    display: inline;
    padding-right: 5px;
  }
}

.region-header .block .employers {
  padding-right: 5px;
}

.region-header .block .employers span {
  display: none;
}

@media all and (min-width: 375px) {
  .region-header .block .employers {
    padding-right: 10px;
  }
  .region-header .block .employers span {
    display: inline;
    padding-right: 5px;
  }
}

.region-header .block .logo {
  width: 110px;
  height: 35px;
  background-image: url("../images/logo-white.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100px 35px;
  float: left;
}

.region-header .block .trial {
  border: 1px solid white;
  padding: 3px;
  width: 67px;
  border-radius: 3px;
}

.region-header .block .trial:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.2);
}

@media all and (min-width: 430px) {
  .region-header .block .top-right,
  .region-header .block .top-right:link,
  .region-header .block .top-right:visited,
  .region-header .block .top-right:hover {
    padding: 0 10px;
    width: auto;
    margin-top: 5px;
  }
  .region-header .block .trial,
  .region-header .block .trial:link,
  .region-header .block .trial:visited,
  .region-header .block .trial:hover {
    padding: 5px;
  }
}

@media all and (min-width: 560px) {
  .region-header .block {
    padding: 8px 10px 10px;
  }
  .region-header .block a,
  .region-header .block a:link,
  .region-header .block a:visited,
  .region-header .block a:hover {
    font-size: .875em;
  }
  .region-header .block .top-right,
  .region-header .block .top-right:link,
  .region-header .block .top-right:visited,
  .region-header .block .top-right:hover {
    margin-top: 8px;
  }
  .region-header .block .top-right.findjobs,
  .region-header .block .top-right:link.findjobs,
  .region-header .block .top-right:visited.findjobs,
  .region-header .block .top-right:hover.findjobs {
    display: inline-block;
  }
  .region-header .block .logo {
    width: 130px;
    height: 40px;
    background-size: 130px 40px;
  }
}

@media all and (min-width: 1024px) {
  .region-header .block {
    height: 72px;
    padding: 10px;
  }
  .region-header .block .top-right,
  .region-header .block .top-right:link,
  .region-header .block .top-right:visited,
  .region-header .block .top-right:hover {
    font-size: 1em;
    margin-top: 3px;
    vertical-align: middle;
  }
  .region-header .block .trial,
  .region-header .block .trial:link,
  .region-header .block .trial:visited,
  .region-header .block .trial:hover {
    white-space: nowrap;
    padding: 5px 10px;
    line-height: 32px;
    border-width: 2px;
  }
  .region-header .block .logo {
    margin: 6px;
  }
}

.layout-center,
.header {
  z-index: 10;
}

.region-highlighted {
  z-index: -10;
}

.page-node-7 .region-bottom,
.front .region-bottom {
  margin-top: 0;
}

.page-node-7 h1,
.front h1 {
  display: none;
}

.page-node-7 .layout-center,
.front .layout-center {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.page-node-7 .layout-center .layout-3col,
.front .layout-center .layout-3col {
  margin: 0;
  padding: 0;
}

.page-node-7 .layout-center .layout-3col .layout-3col__full,
.front .layout-center .layout-3col .layout-3col__full {
  padding: 0;
}

.page-node-7 .header,
.front .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.page-node-7 .region-header,
.front .region-header {
  background: none;
  border: 0;
}

.page-node-7 .region-header .block,
.front .region-header .block {
  background: none;
  border: 0;
}

.page-node-7 .region-header .block a,
.page-node-7 .region-header .block a:link,
.page-node-7 .region-header .block a:visited,
.page-node-7 .region-header .block a:hover,
.front .region-header .block a,
.front .region-header .block a:link,
.front .region-header .block a:visited,
.front .region-header .block a:hover {
  color: white;
}

.page-node-7 .region-header .block .logo,
.front .region-header .block .logo {
  background-image: url("../images/logo-white.png");
}

@media all and (min-width: 1024px) {
  .page-node-7 .region-header .block .logo,
  .front .region-header .block .logo {
    width: 202px;
    height: 60px;
    background-size: 202px 60px;
    margin: 0;
  }
}

.page-node-7 .region-header .block .trial,
.front .region-header .block .trial {
  border-color: white;
}

.region-hero {
  position: relative;
}

.views-field-field-hero-desktop {
  display: none;
}

@media all and (min-width: 480px) {
  .views-field-field-hero-mobile {
    display: none;
  }
  .views-field-field-hero-desktop {
    display: block;
  }
}

.block__title {
  font-size: 24px;
}

@media all and (min-width: 375px) {
  .block__title {
    font-size: 32px;
  }
}

#block-block-5 {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  text-align: center;
  background: none;
}

@media all and (min-width: 366px) {
  #block-block-5 {
    top: 57%;
  }
}

@media all and (min-width: 413px) {
  #block-block-5 {
    top: 55%;
  }
}

@media all and (min-width: 461px) {
  #block-block-5 {
    top: 52%;
  }
}

@media all and (min-width: 480px) {
  #block-block-5 {
    top: 80%;
  }
}

@media all and (min-width: 520px) {
  #block-block-5 {
    top: 75%;
  }
}

@media all and (min-width: 580px) {
  #block-block-5 {
    top: 50%;
    margin-top: 60px;
  }
}

#block-block-5 a,
#block-block-5 a:link,
#block-block-5 a:visited,
#block-block-5 a:hover {
  background: #fe9c20;
}

#block-block-5 a::before,
#block-block-5 a:link::before,
#block-block-5 a:visited::before,
#block-block-5 a:hover::before {
  display: none;
  content: '\f0f6';
  font-family: FontAwesome;
  padding-right: 5px;
}

#block-views-exp-nonprofit-jobs-page,
#block-views-exp-nonprofit-jobs-index-page,
.local-jobs .view-filters form {
  position: absolute;
  top: 25%;
  left: 2%;
  width: 96%;
  z-index: 10;
}

@media all and (min-width: 480px) {
  #block-views-exp-nonprofit-jobs-page,
  #block-views-exp-nonprofit-jobs-index-page,
  .local-jobs .view-filters form {
    top: 15%;
  }
}

@media all and (min-width: 580px) {
  #block-views-exp-nonprofit-jobs-page,
  #block-views-exp-nonprofit-jobs-index-page,
  .local-jobs .view-filters form {
    top: 50%;
    margin-top: -100px;
  }
}

#block-views-exp-nonprofit-jobs-page .block__title,
#block-views-exp-nonprofit-jobs-index-page .block__title,
.local-jobs .view-filters form .block__title {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}

@media all and (min-width: 375px) {
  #block-views-exp-nonprofit-jobs-page .block__title,
  #block-views-exp-nonprofit-jobs-index-page .block__title,
  .local-jobs .view-filters form .block__title {
    margin-bottom: 1.5rem;
  }
}

@media all and (min-width: 480px) and (max-width: 580px) {
  #block-views-exp-nonprofit-jobs-page .block__title,
  #block-views-exp-nonprofit-jobs-index-page .block__title,
  .local-jobs .view-filters form .block__title {
    margin-bottom: .5rem;
  }
}

#block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
#block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.local-jobs .view-filters form #edit-secondary-wrapper {
  display: none;
}

#block-views-exp-nonprofit-jobs-page .form-autocomplete,
#block-views-exp-nonprofit-jobs-index-page .form-autocomplete,
.local-jobs .view-filters form .form-autocomplete {
  background: #fff;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.local-jobs .view-filters form .views-exposed-widgets {
  text-align: center;
  width: 300px;
  margin: auto;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widget,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget,
.local-jobs .view-filters form .views-exposed-widget {
  padding: 0;
  border-radius: 0;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widget input[type='text'],
#block-views-exp-nonprofit-jobs-page .views-exposed-widget input[type='textarea'],
#block-views-exp-nonprofit-jobs-page .views-exposed-widget input[type='submit'],
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget input[type='text'],
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget input[type='textarea'],
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget input[type='submit'],
.local-jobs .view-filters form .views-exposed-widget input[type='text'],
.local-jobs .view-filters form .views-exposed-widget input[type='textarea'],
.local-jobs .view-filters form .views-exposed-widget input[type='submit'] {
  -webkit-appearance: none;
  border-radius: 0;
  margin: 0;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-combine input,
#block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-search_api_views_fulltext input,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-combine input,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-search_api_views_fulltext input,
.local-jobs .view-filters form .views-exposed-widget.views-widget-filter-combine input,
.local-jobs .view-filters form .views-exposed-widget.views-widget-filter-search_api_views_fulltext input {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-field_location_latlon input,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-field_location_latlon input,
.local-jobs .view-filters form .views-exposed-widget.views-widget-filter-field_location_latlon input {
  border-bottom-left-radius: 3px;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-submit-button input,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-submit-button input,
.local-jobs .view-filters form .views-exposed-widget.views-submit-button input {
  border-bottom-right-radius: 3px;
}

@media all and (min-width: 580px) {
  #block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-combine input,
  #block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-search_api_views_fulltext input,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-combine input,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-search_api_views_fulltext input,
  .local-jobs .view-filters form .views-exposed-widget.views-widget-filter-combine input,
  .local-jobs .view-filters form .views-exposed-widget.views-widget-filter-search_api_views_fulltext input {
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  #block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-widget-filter-field_location_latlon input,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-widget-filter-field_location_latlon input,
  .local-jobs .view-filters form .views-exposed-widget.views-widget-filter-field_location_latlon input {
    border-radius: 0;
  }
  #block-views-exp-nonprofit-jobs-page .views-exposed-widget.views-submit-button input,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widget.views-submit-button input,
  .local-jobs .view-filters form .views-exposed-widget.views-submit-button input {
    border-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

#block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit,
.local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit {
  margin: 0;
  border: 0;
  background-color: #e96803;
  background-image: url("../images/search.png");
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
  line-height: 50px;
  text-indent: -999em;
  width: 50px;
}

#block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
#block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset {
  background: none;
  text-indent: 0;
  display: none;
}

#block-views-exp-nonprofit-jobs-page input.form-text,
#block-views-exp-nonprofit-jobs-index-page input.form-text,
.local-jobs .view-filters form input.form-text {
  line-height: 50px;
  height: 50px;
  border: 0;
  box-shadow: 0;
  padding: 0 10px;
  margin: 0;
  width: 300px;
  display: inline-block;
}

#block-views-exp-nonprofit-jobs-page input.form-text#edit-location,
#block-views-exp-nonprofit-jobs-index-page input.form-text#edit-location,
.local-jobs .view-filters form input.form-text#edit-location {
  width: 250px;
  border-top: 1px solid #ddd;
}

@media all and (min-width: 375px) {
  #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .local-jobs .view-filters form .views-exposed-widgets {
    width: 350px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text,
  #block-views-exp-nonprofit-jobs-index-page input.form-text,
  .local-jobs .view-filters form input.form-text {
    width: 350px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text#edit-location,
  #block-views-exp-nonprofit-jobs-index-page input.form-text#edit-location,
  .local-jobs .view-filters form input.form-text#edit-location {
    width: 300px;
  }
}

@media all and (min-width: 580px) {
  #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .local-jobs .view-filters form .views-exposed-widgets {
    width: 550px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text,
  #block-views-exp-nonprofit-jobs-index-page input.form-text,
  .local-jobs .view-filters form input.form-text {
    width: 300px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text#edit-location,
  #block-views-exp-nonprofit-jobs-index-page input.form-text#edit-location,
  .local-jobs .view-filters form input.form-text#edit-location {
    width: 200px;
    border-left: 1px solid #ddd;
    border-top: 0;
  }
}

@media all and (min-width: 700px) {
  #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .local-jobs .view-filters form .views-exposed-widgets {
    width: 650px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text,
  #block-views-exp-nonprofit-jobs-index-page input.form-text,
  .local-jobs .view-filters form input.form-text {
    width: 350px;
  }
  #block-views-exp-nonprofit-jobs-page input.form-text#edit-location,
  #block-views-exp-nonprofit-jobs-index-page input.form-text#edit-location,
  .local-jobs .view-filters form input.form-text#edit-location {
    width: 250px;
  }
}

.page-nonprofit-jobs-search .region-hero,
.page-nonprofit-jobs-search2 .region-hero,
.section-users .region-hero,
.section-user .region-hero,
.page-taxonomy-term .region-hero,
.page-nonprofit-jobs.page-views .region-hero {
  margin: 1rem auto;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page,
.page-nonprofit-jobs-search .local-jobs .view-filters form,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form,
.section-users #block-views-exp-nonprofit-jobs-page,
.section-users #block-views-exp-nonprofit-jobs-index-page,
.section-users .local-jobs .view-filters form,
.section-user #block-views-exp-nonprofit-jobs-page,
.section-user #block-views-exp-nonprofit-jobs-index-page,
.section-user .local-jobs .view-filters form,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page,
.page-taxonomy-term .local-jobs .view-filters form,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  margin-top: 0;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters,
.page-nonprofit-jobs-search .local-jobs .view-filters form .filters,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters,
.section-users #block-views-exp-nonprofit-jobs-page .filters,
.section-users #block-views-exp-nonprofit-jobs-index-page .filters,
.section-users .local-jobs .view-filters form .filters,
.section-user #block-views-exp-nonprofit-jobs-page .filters,
.section-user #block-views-exp-nonprofit-jobs-index-page .filters,
.section-user .local-jobs .view-filters form .filters,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters,
.page-taxonomy-term .local-jobs .view-filters form .filters,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters {
  position: absolute;
  left: 50%;
  bottom: -32px;
  margin-left: -145px;
}

@media all and (min-width: 375px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters,
  .section-users #block-views-exp-nonprofit-jobs-page .filters,
  .section-users #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-users .local-jobs .view-filters form .filters,
  .section-user #block-views-exp-nonprofit-jobs-page .filters,
  .section-user #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-user .local-jobs .view-filters form .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-taxonomy-term .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters {
    margin-left: -170px;
  }
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters,
  .section-users #block-views-exp-nonprofit-jobs-page .filters,
  .section-users #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-users .local-jobs .view-filters form .filters,
  .section-user #block-views-exp-nonprofit-jobs-page .filters,
  .section-user #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-user .local-jobs .view-filters form .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-taxonomy-term .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters {
    margin-left: -270px;
  }
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters,
  .section-users #block-views-exp-nonprofit-jobs-page .filters,
  .section-users #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-users .local-jobs .view-filters form .filters,
  .section-user #block-views-exp-nonprofit-jobs-page .filters,
  .section-user #block-views-exp-nonprofit-jobs-index-page .filters,
  .section-user .local-jobs .view-filters form .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-taxonomy-term .local-jobs .view-filters form .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters {
    margin-left: -320px;
    bottom: -39px;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters::before,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters::before,
.page-nonprofit-jobs-search .local-jobs .view-filters form .filters::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters::before,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters::before,
.section-users #block-views-exp-nonprofit-jobs-page .filters::before,
.section-users #block-views-exp-nonprofit-jobs-index-page .filters::before,
.section-users .local-jobs .view-filters form .filters::before,
.section-user #block-views-exp-nonprofit-jobs-page .filters::before,
.section-user #block-views-exp-nonprofit-jobs-index-page .filters::before,
.section-user .local-jobs .view-filters form .filters::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters::before,
.page-taxonomy-term .local-jobs .view-filters form .filters::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters::before,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters::before {
  content: '\f1de';
  font-family: FontAwesome;
  padding-right: 5px;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters.open,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters.open,
.page-nonprofit-jobs-search .local-jobs .view-filters form .filters.open,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters.open,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters.open,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters.open,
.section-users #block-views-exp-nonprofit-jobs-page .filters.open,
.section-users #block-views-exp-nonprofit-jobs-index-page .filters.open,
.section-users .local-jobs .view-filters form .filters.open,
.section-user #block-views-exp-nonprofit-jobs-page .filters.open,
.section-user #block-views-exp-nonprofit-jobs-index-page .filters.open,
.section-user .local-jobs .view-filters form .filters.open,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters.open,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters.open,
.page-taxonomy-term .local-jobs .view-filters form .filters.open,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters.open,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters.open,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters.open {
  color: #e96803;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .filters.open::before,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.page-nonprofit-jobs-search .local-jobs .view-filters form .filters.open::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .filters.open::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .filters.open::before,
.section-users #block-views-exp-nonprofit-jobs-page .filters.open::before,
.section-users #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.section-users .local-jobs .view-filters form .filters.open::before,
.section-user #block-views-exp-nonprofit-jobs-page .filters.open::before,
.section-user #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.section-user .local-jobs .view-filters form .filters.open::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .filters.open::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.page-taxonomy-term .local-jobs .view-filters form .filters.open::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .filters.open::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .filters.open::before,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .filters.open::before {
  content: 'X';
  font-family: inherit;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets,
.section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.section-users .local-jobs .view-filters form .views-exposed-widgets,
.section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.section-user .local-jobs .view-filters form .views-exposed-widgets,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets {
  position: relative;
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .section-users .local-jobs .view-filters form .views-exposed-widgets,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .section-user .local-jobs .view-filters form .views-exposed-widgets,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets {
    width: 100%;
    text-align: center;
  }
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-combine,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-search_api_views_fulltext,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-submit-button {
    float: none;
    position: relative;
    display: inline-block;
    left: auto;
    top: auto;
  }
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-users .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .section-user .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-widgets .views-submit-button,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-widget-filter-field_location_latlon,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-widgets .views-submit-button {
    margin-left: -4px;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
  position: absolute;
  top: 50px;
  left: 250px;
}

@media all and (min-width: 375px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
    left: 300px;
  }
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
    left: 500px;
    top: 0;
  }
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget.views-submit-button .form-submit,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-submit-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
    position: relative;
    left: auto;
    top: 5px;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset {
  display: block;
  position: absolute;
  top: 112px;
  right: 10px;
  width: auto;
  height: auto;
  line-height: 1em;
  margin-left: -20.5px;
  color: #999999;
  padding: 0;
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset {
    top: 62px;
  }
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-users .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .section-user .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-taxonomy-term .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .views-exposed-form .views-exposed-widget .form-submit#edit-reset,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .views-exposed-form .views-exposed-widget .form-submit#edit-reset {
    top: 73px;
    right: calc(50% - 315px);
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper {
  display: block;
  text-align: center;
  width: 300px;
  margin-top: .5rem;
  font-size: .875rem;
  background: white;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper {
  background: #f7f7f7;
  padding: 20px;
  border: 1px solid #f3f3f3;
  text-align: center;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .fieldset-wrapper .bef-secondary-options {
  text-align: left;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-select-as-links,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .form-checkboxes,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-select-as-links {
  padding-top: 10px;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item {
  display: inline-block;
  width: auto;
  padding: 1px;
  margin: 5px 0;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:link,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:link,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:visited,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active {
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  color: #999999;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a.active,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options .form-item .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a.active,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item a:hover {
  background: #2ab8c6;
  color: white;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item {
  padding: 5px 10px;
  background: white;
  border-radius: 20px;
  color: #999999;
  cursor: pointer;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'],
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item input[type='checkbox'] {
  display: none;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item label,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item label {
  cursor: pointer;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight {
  background: #2dbfcd;
  color: white;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-checkboxes .form-item.highlight label {
  color: white;
}

@media all and (min-width: 375px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper {
    width: 350px;
  }
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper {
    width: 550px;
  }
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper {
    width: 90%;
    position: absolute;
    top: 60px;
    left: 5%;
  }
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter:nth-child(2),
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter:nth-child(2) {
    display: none;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper legend,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper legend,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper legend {
  display: block;
  width: 300px;
  font-size: 1rem;
  text-align: left;
  z-index: 10;
  margin-left: 0;
  padding: 0;
  margin-left: calc(50% - 150px + 10px);
}

@media all and (min-width: 375px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper legend {
    width: 350px;
    margin-left: calc(50% - 175px + 10px);
  }
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper legend {
    width: 550px;
    margin-left: calc(50% - 275px + 10px);
  }
}

@media all and (min-width: 700px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-users .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .section-user .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper legend {
    width: 650px;
    margin-left: calc(50% - 325px + 10px);
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper legend .fieldset-legend,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper legend .fieldset-legend {
  display: block;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper #edit-secondary,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper #edit-secondary {
  border: 0;
  padding: 0;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item {
  margin-bottom: 1.5rem;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item > label,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item > label {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 3px;
  margin-bottom: 5px;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:link,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:visited,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover {
  text-decoration: none;
  padding: 2px 5px;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a.active,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .bef-secondary-options > .form-item .form-item a:hover {
  background: #2ab8c6;
  color: white;
  margin-left: 5px;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .applyfilter,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .applyfilter {
  text-decoration: none;
  font-size: 1rem;
  color: #e96803;
  width: 95px;
  display: block;
  margin: auto;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-legend,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-legend {
  background: none;
  padding: 0;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title {
  text-decoration: none;
  color: #e96803;
  white-space: nowrap;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible .fieldset-title::before,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible .fieldset-title::before {
  content: 'Apply';
  padding-right: 3px;
  display: inline-block;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title {
  color: #2ab8c6;
  background: none;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed .fieldset-title::before {
  content: '\f1de';
  font-family: FontAwesome;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs-search .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-users #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-users #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-users .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-user #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-user #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.section-user .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-taxonomy-term .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page #edit-secondary-wrapper .collapsible.collapsed legend::after,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form #edit-secondary-wrapper .collapsible.collapsed legend::after {
  display: none;
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.page-nonprofit-jobs-search .local-jobs .view-filters form .form-text,
.page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-location.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .form-text,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-location.form-text,
.section-users #block-views-exp-nonprofit-jobs-page .form-text,
.section-users #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.section-users #block-views-exp-nonprofit-jobs-index-page .form-text,
.section-users #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.section-users .local-jobs .view-filters form .form-text,
.section-users .local-jobs .view-filters form input#edit-location.form-text,
.section-user #block-views-exp-nonprofit-jobs-page .form-text,
.section-user #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.section-user #block-views-exp-nonprofit-jobs-index-page .form-text,
.section-user #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.section-user .local-jobs .view-filters form .form-text,
.section-user .local-jobs .view-filters form input#edit-location.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.page-taxonomy-term .local-jobs .view-filters form .form-text,
.page-taxonomy-term .local-jobs .view-filters form input#edit-location.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .form-text,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-location.form-text {
  border: 1px solid #cccccc;
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .page-nonprofit-jobs-search .local-jobs .view-filters form .form-text,
  .page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-location.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form .form-text,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-location.form-text,
  .section-users #block-views-exp-nonprofit-jobs-page .form-text,
  .section-users #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .section-users #block-views-exp-nonprofit-jobs-index-page .form-text,
  .section-users #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .section-users .local-jobs .view-filters form .form-text,
  .section-users .local-jobs .view-filters form input#edit-location.form-text,
  .section-user #block-views-exp-nonprofit-jobs-page .form-text,
  .section-user #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .section-user #block-views-exp-nonprofit-jobs-index-page .form-text,
  .section-user #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .section-user .local-jobs .view-filters form .form-text,
  .section-user .local-jobs .view-filters form input#edit-location.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page .form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .page-taxonomy-term .local-jobs .view-filters form .form-text,
  .page-taxonomy-term .local-jobs .view-filters form input#edit-location.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-location.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-location.form-text,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form .form-text,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-location.form-text {
    border-right: 0;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-combine.form-text,
.page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-keyword.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-combine.form-text,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-keyword.form-text,
.section-users #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.section-users #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.section-users #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.section-users #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.section-users .local-jobs .view-filters form input#edit-combine.form-text,
.section-users .local-jobs .view-filters form input#edit-keyword.form-text,
.section-user #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.section-user #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.section-user #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.section-user #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.section-user .local-jobs .view-filters form input#edit-combine.form-text,
.section-user .local-jobs .view-filters form input#edit-keyword.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.page-taxonomy-term .local-jobs .view-filters form input#edit-combine.form-text,
.page-taxonomy-term .local-jobs .view-filters form input#edit-keyword.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-combine.form-text,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-keyword.form-text {
  border-bottom: 0;
}

@media all and (min-width: 580px) {
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-combine.form-text,
  .page-nonprofit-jobs-search .local-jobs .view-filters form input#edit-keyword.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-combine.form-text,
  .page-nonprofit-jobs-search2 .local-jobs .view-filters form input#edit-keyword.form-text,
  .section-users #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .section-users #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .section-users #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .section-users #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .section-users .local-jobs .view-filters form input#edit-combine.form-text,
  .section-users .local-jobs .view-filters form input#edit-keyword.form-text,
  .section-user #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .section-user #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .section-user #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .section-user #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .section-user .local-jobs .view-filters form input#edit-combine.form-text,
  .section-user .local-jobs .view-filters form input#edit-keyword.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .page-taxonomy-term .local-jobs .view-filters form input#edit-combine.form-text,
  .page-taxonomy-term .local-jobs .view-filters form input#edit-keyword.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-combine.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page input#edit-keyword.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-combine.form-text,
  .page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-combine.form-text,
  .page-nonprofit-jobs.page-views .local-jobs .view-filters form input#edit-keyword.form-text {
    border-bottom: 1px solid #cccccc;
  }
}

.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-page .block__title,
.page-nonprofit-jobs-search #block-views-exp-nonprofit-jobs-index-page .block__title,
.page-nonprofit-jobs-search .local-jobs .view-filters form .block__title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-page .block__title,
.page-nonprofit-jobs-search2 #block-views-exp-nonprofit-jobs-index-page .block__title,
.page-nonprofit-jobs-search2 .local-jobs .view-filters form .block__title,
.section-users #block-views-exp-nonprofit-jobs-page .block__title,
.section-users #block-views-exp-nonprofit-jobs-index-page .block__title,
.section-users .local-jobs .view-filters form .block__title,
.section-user #block-views-exp-nonprofit-jobs-page .block__title,
.section-user #block-views-exp-nonprofit-jobs-index-page .block__title,
.section-user .local-jobs .view-filters form .block__title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-page .block__title,
.page-taxonomy-term #block-views-exp-nonprofit-jobs-index-page .block__title,
.page-taxonomy-term .local-jobs .view-filters form .block__title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-page .block__title,
.page-nonprofit-jobs.page-views #block-views-exp-nonprofit-jobs-index-page .block__title,
.page-nonprofit-jobs.page-views .local-jobs .view-filters form .block__title {
  display: none;
}

.section-users #block-views-exp-nonprofit-jobs-page .views-reset-button,
.section-users #block-views-exp-nonprofit-jobs-index-page .views-reset-button,
.section-user #block-views-exp-nonprofit-jobs-page .views-reset-button,
.section-user #block-views-exp-nonprofit-jobs-index-page .views-reset-button {
  display: none;
}

.page-nonprofit-jobs-search .region-highlighted,
.page-nonprofit-jobs-search2 .region-highlighted,
.page-taxonomy-term .region-highlighted,
.page-nonprofit-jobs.page-views .region-highlighted {
  background: #f7f7f7;
  padding: 20px;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi,
.page-taxonomy-term .region-highlighted .block-facetapi,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi {
  padding: 10px 0;
  font-size: .875rem;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi .block__title,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi .block__title,
.page-taxonomy-term .region-highlighted .block-facetapi .block__title,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi .block__title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 3px;
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 1rem;
  font-weight: 600px;
  text-align: left;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul,
.page-taxonomy-term .region-highlighted .block-facetapi ul,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul {
  margin: 0;
  padding: 0;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li,
.page-taxonomy-term .region-highlighted .block-facetapi ul li,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li {
  list-style: none;
  display: inline-block;
  width: auto;
  padding: 1px;
  margin: 5px 0;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li a,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li a:link,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li a:visited,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li a:hover,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li a,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li a:link,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li a:visited,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li a:hover,
.page-taxonomy-term .region-highlighted .block-facetapi ul li a,
.page-taxonomy-term .region-highlighted .block-facetapi ul li a:link,
.page-taxonomy-term .region-highlighted .block-facetapi ul li a:visited,
.page-taxonomy-term .region-highlighted .block-facetapi ul li a:hover,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li a,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li a:link,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li a:visited,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li a:hover {
  padding: 5px 10px;
  background: white;
  border-radius: 20px;
  color: #999;
  display: inline-block;
  font-size: .875rem;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li a:hover,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li a:hover,
.page-taxonomy-term .region-highlighted .block-facetapi ul li a:hover,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li a:hover {
  color: white;
  background: #2dbfcd;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li.active,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li.active,
.page-taxonomy-term .region-highlighted .block-facetapi ul li.active,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li.active {
  background: #2dbfcd;
  color: white;
  border-radius: 20px;
  position: relative;
  padding: 5px 10px;
}

.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li.active a,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li.active a:link,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li.active a:visited,
.page-nonprofit-jobs-search .region-highlighted .block-facetapi ul li.active a:hover,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li.active a,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li.active a:link,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li.active a:visited,
.page-nonprofit-jobs-search2 .region-highlighted .block-facetapi ul li.active a:hover,
.page-taxonomy-term .region-highlighted .block-facetapi ul li.active a,
.page-taxonomy-term .region-highlighted .block-facetapi ul li.active a:link,
.page-taxonomy-term .region-highlighted .block-facetapi ul li.active a:visited,
.page-taxonomy-term .region-highlighted .block-facetapi ul li.active a:hover,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li.active a,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li.active a:link,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li.active a:visited,
.page-nonprofit-jobs.page-views .region-highlighted .block-facetapi ul li.active a:hover {
  display: inline-block;
  background: none;
  z-index: 1;
  position: absolute;
  padding: 0;
  display: block;
  color: white;
  font-size: 0;
  color: transparent;
  width: 100%;
  height: 100%;
}

.page-nonprofit-jobs-search .view-empty,
.page-nonprofit-jobs-search2 .view-empty,
.page-taxonomy-term .view-empty,
.page-nonprofit-jobs.page-views .view-empty {
  background: white;
  position: relative;
}

.page-nonprofit-jobs-search .view-empty .messages,
.page-nonprofit-jobs-search2 .view-empty .messages,
.page-taxonomy-term .view-empty .messages,
.page-nonprofit-jobs.page-views .view-empty .messages {
  min-height: 110px;
  box-sizing: border-box;
  position: relative;
}

.page-nonprofit-jobs-search .view-empty .messages .dismiss,
.page-nonprofit-jobs-search2 .view-empty .messages .dismiss,
.page-taxonomy-term .view-empty .messages .dismiss,
.page-nonprofit-jobs.page-views .view-empty .messages .dismiss {
  display: none;
}

.page-nonprofit-jobs-search .layout-center,
.page-nonprofit-jobs-search .layout-3col__full,
.page-nonprofit-jobs-search .layout-3col,
.page-nonprofit-jobs-search2 .layout-center,
.page-nonprofit-jobs-search2 .layout-3col__full,
.page-nonprofit-jobs-search2 .layout-3col,
.page-taxonomy-term .layout-center,
.page-taxonomy-term .layout-3col__full,
.page-taxonomy-term .layout-3col,
.page-nonprofit-jobs.page-views .layout-center,
.page-nonprofit-jobs.page-views .layout-3col__full,
.page-nonprofit-jobs.page-views .layout-3col {
  padding: 0;
  margin: 0 auto;
}

.page-nonprofit-jobs-search .layout-center,
.page-nonprofit-jobs-search2 .layout-center,
.page-taxonomy-term .layout-center,
.page-nonprofit-jobs.page-views .layout-center {
  max-width: 1300px;
  padding-top: 30px;
}

.page-nonprofit-jobs-search h1,
.page-nonprofit-jobs-search2 h1,
.page-taxonomy-term h1,
.page-nonprofit-jobs.page-views h1 {
  margin-bottom: 0;
  margin-top: 10px;
}

.page-nonprofit-jobs-search .breadcrumb,
.page-nonprofit-jobs-search2 .breadcrumb,
.page-taxonomy-term .breadcrumb,
.page-nonprofit-jobs.page-views .breadcrumb {
  display: none;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .view-header,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .view-header,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .view-header,
.page-nonprofit-jobs-search .view-job-category-taxonomy .view-header,
.page-nonprofit-jobs-search .local-jobs .view-header,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .view-header,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .view-header,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .view-header,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .view-header,
.page-nonprofit-jobs-search2 .local-jobs .view-header,
.page-taxonomy-term .view-nonprofit-jobs .view-header,
.page-taxonomy-term .view-nonprofit-jobs-index .view-header,
.page-taxonomy-term .view-area-of-focus-taxonomy .view-header,
.page-taxonomy-term .view-job-category-taxonomy .view-header,
.page-taxonomy-term .local-jobs .view-header,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .view-header,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .view-header,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .view-header,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .view-header,
.page-nonprofit-jobs.page-views .local-jobs .view-header {
  text-align: center;
  padding-bottom: 1rem;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .view-content,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .view-content,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .view-content,
.page-nonprofit-jobs-search .view-job-category-taxonomy .view-content,
.page-nonprofit-jobs-search .local-jobs .view-content,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .view-content,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .view-content,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .view-content,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .view-content,
.page-nonprofit-jobs-search2 .local-jobs .view-content,
.page-taxonomy-term .view-nonprofit-jobs .view-content,
.page-taxonomy-term .view-nonprofit-jobs-index .view-content,
.page-taxonomy-term .view-area-of-focus-taxonomy .view-content,
.page-taxonomy-term .view-job-category-taxonomy .view-content,
.page-taxonomy-term .local-jobs .view-content,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .view-content,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .view-content,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .view-content,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .view-content,
.page-nonprofit-jobs.page-views .local-jobs .view-content {
  float: none;
  border-top: 1px solid #f3f3f3;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment,
.page-nonprofit-jobs-search .local-jobs .attachment,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment,
.page-nonprofit-jobs-search2 .local-jobs .attachment,
.page-taxonomy-term .view-nonprofit-jobs .attachment,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment,
.page-taxonomy-term .view-job-category-taxonomy .attachment,
.page-taxonomy-term .local-jobs .attachment,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment,
.page-nonprofit-jobs.page-views .local-jobs .attachment {
  display: none;
  width: calc( 100% - 320px);
  padding: 10px;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment h2,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment h2,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment h2,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment h2,
.page-nonprofit-jobs-search .local-jobs .attachment h2,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment h2,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment h2,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment h2,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment h2,
.page-nonprofit-jobs-search2 .local-jobs .attachment h2,
.page-taxonomy-term .view-nonprofit-jobs .attachment h2,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment h2,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment h2,
.page-taxonomy-term .view-job-category-taxonomy .attachment h2,
.page-taxonomy-term .local-jobs .attachment h2,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment h2,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment h2,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment h2,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment h2,
.page-nonprofit-jobs.page-views .local-jobs .attachment h2 {
  margin: 0;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment h2 a,
.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment h2 a:link,
.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment h2 a:visited,
.page-nonprofit-jobs-search .view-nonprofit-jobs .attachment h2 a:hover,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment h2 a,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment h2 a:link,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment h2 a:visited,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment h2 a:hover,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment h2 a,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment h2 a,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs-search .view-job-category-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs-search .local-jobs .attachment h2 a,
.page-nonprofit-jobs-search .local-jobs .attachment h2 a:link,
.page-nonprofit-jobs-search .local-jobs .attachment h2 a:visited,
.page-nonprofit-jobs-search .local-jobs .attachment h2 a:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment h2 a,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment h2 a:link,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment h2 a:visited,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment h2 a:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment h2 a,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment h2 a:link,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment h2 a:visited,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment h2 a:hover,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment h2 a,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment h2 a,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs-search2 .local-jobs .attachment h2 a,
.page-nonprofit-jobs-search2 .local-jobs .attachment h2 a:link,
.page-nonprofit-jobs-search2 .local-jobs .attachment h2 a:visited,
.page-nonprofit-jobs-search2 .local-jobs .attachment h2 a:hover,
.page-taxonomy-term .view-nonprofit-jobs .attachment h2 a,
.page-taxonomy-term .view-nonprofit-jobs .attachment h2 a:link,
.page-taxonomy-term .view-nonprofit-jobs .attachment h2 a:visited,
.page-taxonomy-term .view-nonprofit-jobs .attachment h2 a:hover,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment h2 a,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment h2 a:link,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment h2 a:visited,
.page-taxonomy-term .view-nonprofit-jobs-index .attachment h2 a:hover,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment h2 a,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment h2 a:link,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment h2 a:visited,
.page-taxonomy-term .view-area-of-focus-taxonomy .attachment h2 a:hover,
.page-taxonomy-term .view-job-category-taxonomy .attachment h2 a,
.page-taxonomy-term .view-job-category-taxonomy .attachment h2 a:link,
.page-taxonomy-term .view-job-category-taxonomy .attachment h2 a:visited,
.page-taxonomy-term .view-job-category-taxonomy .attachment h2 a:hover,
.page-taxonomy-term .local-jobs .attachment h2 a,
.page-taxonomy-term .local-jobs .attachment h2 a:link,
.page-taxonomy-term .local-jobs .attachment h2 a:visited,
.page-taxonomy-term .local-jobs .attachment h2 a:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment h2 a,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment h2 a:link,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment h2 a:visited,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment h2 a:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment h2 a,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment h2 a:link,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment h2 a:visited,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment h2 a:hover,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment h2 a,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment h2 a,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment h2 a:link,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment h2 a:visited,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment h2 a:hover,
.page-nonprofit-jobs.page-views .local-jobs .attachment h2 a,
.page-nonprofit-jobs.page-views .local-jobs .attachment h2 a:link,
.page-nonprofit-jobs.page-views .local-jobs .attachment h2 a:visited,
.page-nonprofit-jobs.page-views .local-jobs .attachment h2 a:hover {
  color: black;
}

@media all and (max-width: 767px) {
  .page-nonprofit-jobs-search .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search .local-jobs .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .local-jobs .view-content,
  .page-taxonomy-term .view-nonprofit-jobs .view-content,
  .page-taxonomy-term .view-nonprofit-jobs-index .view-content,
  .page-taxonomy-term .view-area-of-focus-taxonomy .view-content,
  .page-taxonomy-term .view-job-category-taxonomy .view-content,
  .page-taxonomy-term .local-jobs .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .local-jobs .view-content {
    float: none;
  }
}

@media all and (min-width: 768px) {
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs-search .local-jobs .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs-search2 .local-jobs .attachment,
  .page-taxonomy-term .view-nonprofit-jobs .attachment,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment,
  .page-taxonomy-term .view-job-category-taxonomy .attachment,
  .page-taxonomy-term .local-jobs .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs.page-views .local-jobs .attachment {
    background: #f3f3f3;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment .local-jobs,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment .local-jobs,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs-search .local-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search .local-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search .local-jobs .attachment .local-jobs,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment .local-jobs,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment .local-jobs,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs-search2 .local-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs-search2 .local-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs-search2 .local-jobs .attachment .local-jobs,
  .page-taxonomy-term .view-nonprofit-jobs .attachment .view-nonprofit-jobs,
  .page-taxonomy-term .view-nonprofit-jobs .attachment .view-nonprofit-jobs-index,
  .page-taxonomy-term .view-nonprofit-jobs .attachment .local-jobs,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs-index,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment .local-jobs,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment .local-jobs,
  .page-taxonomy-term .view-job-category-taxonomy .attachment .view-nonprofit-jobs,
  .page-taxonomy-term .view-job-category-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-taxonomy-term .view-job-category-taxonomy .attachment .local-jobs,
  .page-taxonomy-term .local-jobs .attachment .view-nonprofit-jobs,
  .page-taxonomy-term .local-jobs .attachment .view-nonprofit-jobs-index,
  .page-taxonomy-term .local-jobs .attachment .local-jobs,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment .local-jobs,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment .local-jobs,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment .local-jobs,
  .page-nonprofit-jobs.page-views .local-jobs .attachment .view-nonprofit-jobs,
  .page-nonprofit-jobs.page-views .local-jobs .attachment .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .local-jobs .attachment .local-jobs {
    padding: 20px 10px 30px;
    background: white;
    border: 1px solid #cccccc;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment header,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment header,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment header,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment header,
  .page-nonprofit-jobs-search .local-jobs .attachment header,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment header,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment header,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment header,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment header,
  .page-nonprofit-jobs-search2 .local-jobs .attachment header,
  .page-taxonomy-term .view-nonprofit-jobs .attachment header,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment header,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment header,
  .page-taxonomy-term .view-job-category-taxonomy .attachment header,
  .page-taxonomy-term .local-jobs .attachment header,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment header,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment header,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment header,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment header,
  .page-nonprofit-jobs.page-views .local-jobs .attachment header {
    margin: 0 0 0 110px;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search .local-jobs .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs-search2 .local-jobs .attachment .view-org-logo-for-job-listing,
  .page-taxonomy-term .view-nonprofit-jobs .attachment .view-org-logo-for-job-listing,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment .view-org-logo-for-job-listing,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-taxonomy-term .view-job-category-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-taxonomy-term .local-jobs .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment .view-org-logo-for-job-listing,
  .page-nonprofit-jobs.page-views .local-jobs .attachment .view-org-logo-for-job-listing {
    margin-top: -30px;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs-search .local-jobs .view-content,
  .page-nonprofit-jobs-search .local-jobs .item-list,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs-search2 .local-jobs .view-content,
  .page-nonprofit-jobs-search2 .local-jobs .item-list,
  .page-taxonomy-term .view-nonprofit-jobs .view-content,
  .page-taxonomy-term .view-nonprofit-jobs .item-list,
  .page-taxonomy-term .view-nonprofit-jobs-index .view-content,
  .page-taxonomy-term .view-nonprofit-jobs-index .item-list,
  .page-taxonomy-term .view-area-of-focus-taxonomy .view-content,
  .page-taxonomy-term .view-area-of-focus-taxonomy .item-list,
  .page-taxonomy-term .view-job-category-taxonomy .view-content,
  .page-taxonomy-term .view-job-category-taxonomy .item-list,
  .page-taxonomy-term .local-jobs .view-content,
  .page-taxonomy-term .local-jobs .item-list,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs.page-views .local-jobs .view-content,
  .page-nonprofit-jobs.page-views .local-jobs .item-list {
    width: 320px;
    float: left;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs > .attachment,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index > .attachment,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy > .attachment,
  .page-nonprofit-jobs-search .view-job-category-taxonomy > .attachment,
  .page-nonprofit-jobs-search .local-jobs > .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs > .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index > .attachment,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy > .attachment,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy > .attachment,
  .page-nonprofit-jobs-search2 .local-jobs > .attachment,
  .page-taxonomy-term .view-nonprofit-jobs > .attachment,
  .page-taxonomy-term .view-nonprofit-jobs-index > .attachment,
  .page-taxonomy-term .view-area-of-focus-taxonomy > .attachment,
  .page-taxonomy-term .view-job-category-taxonomy > .attachment,
  .page-taxonomy-term .local-jobs > .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs > .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index > .attachment,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy > .attachment,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy > .attachment,
  .page-nonprofit-jobs.page-views .local-jobs > .attachment {
    float: right;
    display: block;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs > .attachment .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index > .attachment .view-content,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs-search .view-job-category-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs-search .local-jobs > .attachment .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs > .attachment .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index > .attachment .view-content,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs-search2 .local-jobs > .attachment .view-content,
  .page-taxonomy-term .view-nonprofit-jobs > .attachment .view-content,
  .page-taxonomy-term .view-nonprofit-jobs-index > .attachment .view-content,
  .page-taxonomy-term .view-area-of-focus-taxonomy > .attachment .view-content,
  .page-taxonomy-term .view-job-category-taxonomy > .attachment .view-content,
  .page-taxonomy-term .local-jobs > .attachment .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs > .attachment .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index > .attachment .view-content,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy > .attachment .view-content,
  .page-nonprofit-jobs.page-views .local-jobs > .attachment .view-content {
    float: none;
    border: 0;
    width: 100%;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs > .attachment .node-job-posting,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index > .attachment .node-job-posting,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs-search .view-job-category-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs-search .local-jobs > .attachment .node-job-posting,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs > .attachment .node-job-posting,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index > .attachment .node-job-posting,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs-search2 .local-jobs > .attachment .node-job-posting,
  .page-taxonomy-term .view-nonprofit-jobs > .attachment .node-job-posting,
  .page-taxonomy-term .view-nonprofit-jobs-index > .attachment .node-job-posting,
  .page-taxonomy-term .view-area-of-focus-taxonomy > .attachment .node-job-posting,
  .page-taxonomy-term .view-job-category-taxonomy > .attachment .node-job-posting,
  .page-taxonomy-term .local-jobs > .attachment .node-job-posting,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs > .attachment .node-job-posting,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index > .attachment .node-job-posting,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy > .attachment .node-job-posting,
  .page-nonprofit-jobs.page-views .local-jobs > .attachment .node-job-posting {
    display: none;
  }
}

@media all and (min-width: 1024px) {
  .page-nonprofit-jobs-search .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs-search .local-jobs .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs-search2 .local-jobs .attachment,
  .page-taxonomy-term .view-nonprofit-jobs .attachment,
  .page-taxonomy-term .view-nonprofit-jobs-index .attachment,
  .page-taxonomy-term .view-area-of-focus-taxonomy .attachment,
  .page-taxonomy-term .view-job-category-taxonomy .attachment,
  .page-taxonomy-term .local-jobs .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .attachment,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .attachment,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .attachment,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .attachment,
  .page-nonprofit-jobs.page-views .local-jobs .attachment {
    width: calc( 100% - 400px);
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs-search .local-jobs .view-content,
  .page-nonprofit-jobs-search .local-jobs .item-list,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs-search2 .local-jobs .view-content,
  .page-nonprofit-jobs-search2 .local-jobs .item-list,
  .page-taxonomy-term .view-nonprofit-jobs .view-content,
  .page-taxonomy-term .view-nonprofit-jobs .item-list,
  .page-taxonomy-term .view-nonprofit-jobs-index .view-content,
  .page-taxonomy-term .view-nonprofit-jobs-index .item-list,
  .page-taxonomy-term .view-area-of-focus-taxonomy .view-content,
  .page-taxonomy-term .view-area-of-focus-taxonomy .item-list,
  .page-taxonomy-term .view-job-category-taxonomy .view-content,
  .page-taxonomy-term .view-job-category-taxonomy .item-list,
  .page-taxonomy-term .local-jobs .view-content,
  .page-taxonomy-term .local-jobs .item-list,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .item-list,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .view-content,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .item-list,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .view-content,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .item-list,
  .page-nonprofit-jobs.page-views .local-jobs .view-content,
  .page-nonprofit-jobs.page-views .local-jobs .item-list {
    width: 400px;
  }
}

.page-nonprofit-jobs-search .view-nonprofit-jobs h1,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index h1,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy h1,
.page-nonprofit-jobs-search .view-job-category-taxonomy h1,
.page-nonprofit-jobs-search .local-jobs h1,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs h1,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index h1,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy h1,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy h1,
.page-nonprofit-jobs-search2 .local-jobs h1,
.page-taxonomy-term .view-nonprofit-jobs h1,
.page-taxonomy-term .view-nonprofit-jobs-index h1,
.page-taxonomy-term .view-area-of-focus-taxonomy h1,
.page-taxonomy-term .view-job-category-taxonomy h1,
.page-taxonomy-term .local-jobs h1,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs h1,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index h1,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy h1,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy h1,
.page-nonprofit-jobs.page-views .local-jobs h1 {
  margin-bottom: 10px;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row,
.page-nonprofit-jobs-search .local-jobs .views-row,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row,
.page-nonprofit-jobs-search2 .local-jobs .views-row,
.page-taxonomy-term .view-nonprofit-jobs .views-row,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row,
.page-taxonomy-term .view-job-category-taxonomy .views-row,
.page-taxonomy-term .local-jobs .views-row,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row,
.page-nonprofit-jobs.page-views .local-jobs .views-row {
  line-height: 1.3rem;
  font-size: .875rem;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .node-job-posting,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .node-job-posting,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs-search .local-jobs .views-row .node-job-posting,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .node-job-posting,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .node-job-posting,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs-search2 .local-jobs .views-row .node-job-posting,
.page-taxonomy-term .view-nonprofit-jobs .views-row .node-job-posting,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .node-job-posting,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .node-job-posting,
.page-taxonomy-term .view-job-category-taxonomy .views-row .node-job-posting,
.page-taxonomy-term .local-jobs .views-row .node-job-posting,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .node-job-posting,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .node-job-posting,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .node-job-posting,
.page-nonprofit-jobs.page-views .local-jobs .views-row .node-job-posting {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media all and (min-width: 770px) and (max-width: 980px) {
  .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search .local-jobs .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs-search2 .local-jobs .views-row .node-job-posting .callstoaction,
  .page-taxonomy-term .view-nonprofit-jobs .views-row .node-job-posting .callstoaction,
  .page-taxonomy-term .view-nonprofit-jobs-index .views-row .node-job-posting .callstoaction,
  .page-taxonomy-term .view-area-of-focus-taxonomy .views-row .node-job-posting .callstoaction,
  .page-taxonomy-term .view-job-category-taxonomy .views-row .node-job-posting .callstoaction,
  .page-taxonomy-term .local-jobs .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .node-job-posting .callstoaction,
  .page-nonprofit-jobs.page-views .local-jobs .views-row .node-job-posting .callstoaction {
    float: none;
    clear: both;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search .local-jobs .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs-search2 .local-jobs .views-row .node-job-posting .keyinfo,
  .page-taxonomy-term .view-nonprofit-jobs .views-row .node-job-posting .keyinfo,
  .page-taxonomy-term .view-nonprofit-jobs-index .views-row .node-job-posting .keyinfo,
  .page-taxonomy-term .view-area-of-focus-taxonomy .views-row .node-job-posting .keyinfo,
  .page-taxonomy-term .view-job-category-taxonomy .views-row .node-job-posting .keyinfo,
  .page-taxonomy-term .local-jobs .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .node-job-posting .keyinfo,
  .page-nonprofit-jobs.page-views .local-jobs .views-row .node-job-posting .keyinfo {
    width: 100%;
    float: none;
  }
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search .local-jobs .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs-search2 .local-jobs .views-row .node-job-posting .views-row,
.page-taxonomy-term .view-nonprofit-jobs .views-row .node-job-posting .views-row,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .node-job-posting .views-row,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .node-job-posting .views-row,
.page-taxonomy-term .view-job-category-taxonomy .views-row .node-job-posting .views-row,
.page-taxonomy-term .local-jobs .views-row .node-job-posting .views-row,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .node-job-posting .views-row,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .node-job-posting .views-row,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .node-job-posting .views-row,
.page-nonprofit-jobs.page-views .local-jobs .views-row .node-job-posting .views-row {
  font-size: 1rem;
  line-height: 1.5rem;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job,
.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job:link,
.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job:visited,
.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job:link,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job:visited,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job:link,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job:visited,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job:link,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job:visited,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search .local-jobs .views-row .job,
.page-nonprofit-jobs-search .local-jobs .views-row .job:link,
.page-nonprofit-jobs-search .local-jobs .views-row .job:visited,
.page-nonprofit-jobs-search .local-jobs .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job:link,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job:visited,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job:link,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job:visited,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job:link,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job:visited,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job:link,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job:visited,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job:link,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job:visited,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job:hover,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job:link,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job:visited,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job:hover,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job:link,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job:visited,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job:hover,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job:link,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job:visited,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job:hover,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job:link,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job:visited,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job:hover,
.page-taxonomy-term .local-jobs .views-row .job,
.page-taxonomy-term .local-jobs .views-row .job:link,
.page-taxonomy-term .local-jobs .views-row .job:visited,
.page-taxonomy-term .local-jobs .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job:link,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job:visited,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job:link,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job:visited,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job:link,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job:visited,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job:link,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job:visited,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job:link,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job:visited,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job:hover {
  color: black;
  display: block;
  cursor: pointer;
  z-index: 0;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search .local-jobs .views-row .job:hover,
.page-nonprofit-jobs-search .local-jobs .views-row .job.active,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job:hover,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job.active,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job:hover,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job.active,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job:hover,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job.active,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job:hover,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job.active,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job:hover,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job.active,
.page-taxonomy-term .local-jobs .views-row .job:hover,
.page-taxonomy-term .local-jobs .views-row .job.active,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job:hover,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job:hover,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job.active {
  background: #f1f1f1;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search .local-jobs .views-row .job.active,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job.active,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job.active,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job.active,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job.active,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job.active,
.page-taxonomy-term .local-jobs .views-row .job.active,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job.active,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job.active,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job.active,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job.active,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job.active {
  border-right: 0;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row.views-row-last,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row.views-row-last,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs-search .local-jobs .views-row.views-row-last,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row.views-row-last,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row.views-row-last,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs-search2 .local-jobs .views-row.views-row-last,
.page-taxonomy-term .view-nonprofit-jobs .views-row.views-row-last,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row.views-row-last,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row.views-row-last,
.page-taxonomy-term .view-job-category-taxonomy .views-row.views-row-last,
.page-taxonomy-term .local-jobs .views-row.views-row-last,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row.views-row-last,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row.views-row-last,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row.views-row-last,
.page-nonprofit-jobs.page-views .local-jobs .views-row.views-row-last {
  border: 0;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job-title,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job-title,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job-title,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job-title,
.page-nonprofit-jobs-search .local-jobs .views-row .job-title,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job-title,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job-title,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job-title,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job-title,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job-title,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job-title,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job-title,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job-title,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job-title,
.page-taxonomy-term .local-jobs .views-row .job-title,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job-title,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job-title,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job-title,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job-title,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job-title {
  color: #2ab8c6;
  font-size: 1rem;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo,
.page-nonprofit-jobs-search .local-jobs .views-row .logo,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo,
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo,
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo,
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo,
.page-taxonomy-term .local-jobs .views-row .logo,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo,
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo {
  border: 1px solid #f3f3f3;
  background: white;
  width: 80px;
  height: 80px;
  float: left;
  margin: 0 10px 20px 0;
  font: 0/0 a;
  position: relative;
  border-radius: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial,
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial,
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial,
.page-taxonomy-term .local-jobs .views-row .logo .org-initial,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial,
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  width: 78px;
  height: 78px;
  line-height: 78px;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  background: #e96803;
  border-radius: 18px;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(odd),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(odd),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(odd) {
  background: #e96803;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(even),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(even),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(even) {
  background: #2dbfcd;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(3), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(6), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(9), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(12), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(15), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(18), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(21), .page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search .local-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(3),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(6),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(9),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(12),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(15),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(18),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(21),
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(24),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-taxonomy-term .local-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .org-initial:nth-of-type(24),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(3),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(6),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(9),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(12),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(15),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(18),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(21),
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .org-initial:nth-of-type(24) {
  background: #fe9c20;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo::before,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo::before,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo::before,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo::before,
.page-nonprofit-jobs-search .local-jobs .views-row .logo::before,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo::before,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo::before,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo::before,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo::before,
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo::before,
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo::before,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo::before,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo::before,
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo::before,
.page-taxonomy-term .local-jobs .views-row .logo::before,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo::before,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo::before,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo::before,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo::before,
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo .logo-img,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo .logo-img,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs-search .local-jobs .views-row .logo .logo-img,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo .logo-img,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo .logo-img,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo .logo-img,
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo .logo-img,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo .logo-img,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo .logo-img,
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo .logo-img,
.page-taxonomy-term .local-jobs .views-row .logo .logo-img,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo .logo-img,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo .logo-img,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo .logo-img,
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo .logo-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .logo img,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .logo img,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .logo img,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .logo img,
.page-nonprofit-jobs-search .local-jobs .views-row .logo img,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .logo img,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .logo img,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .logo img,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .logo img,
.page-nonprofit-jobs-search2 .local-jobs .views-row .logo img,
.page-taxonomy-term .view-nonprofit-jobs .views-row .logo img,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .logo img,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .logo img,
.page-taxonomy-term .view-job-category-taxonomy .views-row .logo img,
.page-taxonomy-term .local-jobs .views-row .logo img,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .logo img,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .logo img,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .logo img,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .logo img,
.page-nonprofit-jobs.page-views .local-jobs .views-row .logo img {
  max-height: 78px;
  max-width: 78px;
  width: auto;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job-location,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job-location,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job-location,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job-location,
.page-nonprofit-jobs-search .local-jobs .views-row .job-location,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job-location,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job-location,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job-location,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job-location,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job-location,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job-location,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job-location,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job-location,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job-location,
.page-taxonomy-term .local-jobs .views-row .job-location,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job-location,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job-location,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job-location,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job-location,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job-location {
  color: #666666;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job-date,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job-date,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job-date,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job-date,
.page-nonprofit-jobs-search .local-jobs .views-row .job-date,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job-date,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job-date,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job-date,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job-date,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job-date,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job-date,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job-date,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job-date,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job-date,
.page-taxonomy-term .local-jobs .views-row .job-date,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job-date,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job-date,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job-date,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job-date,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job-date {
  color: #999999;
  font-size: .75rem;
  float: right;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .job-type,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .job-type,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .job-type,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .job-type,
.page-nonprofit-jobs-search .local-jobs .views-row .job-type,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .job-type,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .job-type,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .job-type,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .job-type,
.page-nonprofit-jobs-search2 .local-jobs .views-row .job-type,
.page-taxonomy-term .view-nonprofit-jobs .views-row .job-type,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .job-type,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .job-type,
.page-taxonomy-term .view-job-category-taxonomy .views-row .job-type,
.page-taxonomy-term .local-jobs .views-row .job-type,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .job-type,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .job-type,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .job-type,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .job-type,
.page-nonprofit-jobs.page-views .local-jobs .views-row .job-type {
  color: #999999;
  font-size: .75rem;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .easy-apply-1,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs-search .local-jobs .views-row .easy-apply-1,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .easy-apply-1,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs-search2 .local-jobs .views-row .easy-apply-1,
.page-taxonomy-term .view-nonprofit-jobs .views-row .easy-apply-1,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .easy-apply-1,
.page-taxonomy-term .view-job-category-taxonomy .views-row .easy-apply-1,
.page-taxonomy-term .local-jobs .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .local-jobs .views-row .easy-apply-1 {
  color: black;
  padding: 0 3px;
  font-size: .75rem;
  float: right;
  margin-left: 10px;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs .views-row .easy-apply-2,
.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .easy-apply-2,
.page-nonprofit-jobs-search .view-area-of-focus-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs-search .view-job-category-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs-search .local-jobs .views-row .easy-apply-2,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs .views-row .easy-apply-2,
.page-nonprofit-jobs-search2 .view-nonprofit-jobs-index .views-row .easy-apply-2,
.page-nonprofit-jobs-search2 .view-area-of-focus-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs-search2 .view-job-category-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs-search2 .local-jobs .views-row .easy-apply-2,
.page-taxonomy-term .view-nonprofit-jobs .views-row .easy-apply-2,
.page-taxonomy-term .view-nonprofit-jobs-index .views-row .easy-apply-2,
.page-taxonomy-term .view-area-of-focus-taxonomy .views-row .easy-apply-2,
.page-taxonomy-term .view-job-category-taxonomy .views-row .easy-apply-2,
.page-taxonomy-term .local-jobs .views-row .easy-apply-2,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs .views-row .easy-apply-2,
.page-nonprofit-jobs.page-views .view-nonprofit-jobs-index .views-row .easy-apply-2,
.page-nonprofit-jobs.page-views .view-area-of-focus-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs.page-views .view-job-category-taxonomy .views-row .easy-apply-2,
.page-nonprofit-jobs.page-views .local-jobs .views-row .easy-apply-2 {
  display: none;
}

#autocomplete li {
  cursor: pointer;
  padding: 5px 10px;
  text-align: left;
}

#autocomplete li a,
#autocomplete li a:link,
#autocomplete li a:visited {
  color: #000;
  text-decoration: none;
  text-align: left;
}

#autocomplete li a:hover {
  color: white;
  background: #e96803;
}

.autocomplete,
.form-autocomplete,
div.autocomplete-deluxe-multiple {
  border: 1px solid #ddd;
  box-shadow: 0;
  background-color: white;
}

.autocomplete-deluxe-container {
  background-color: white;
}

.autocomplete-deluxe-item-delete {
  background: none;
  width: 0;
  right: 10px;
  top: 2px;
  text-decoration: none;
}

.autocomplete-deluxe-item-delete::before {
  content: 'x';
  color: #666666;
  width: 12px;
  display: block;
  font-size: 12px;
  text-decoration: none;
}

#edit-field-how-to-apply {
  display: none;
}

#block-system-help {
  max-width: 740px;
  margin: auto;
  text-align: center;
}

.node-form .form-managed-file {
  background: white;
  padding: 15px 5px 5px;
  max-width: 600px;
}

.node-form tr.draggable td {
  padding: 5px;
}

.node-form .showcover {
  cursor: pointer;
}

.node-form .form-radios .form-type-radio {
  border: 1px solid transparent;
  padding: 3px;
  border-radius: 3px;
}

.node-form .form-radios .form-type-radio.highlight {
  border-color: #2dbfcd;
}

.node-form #edit-field-cover-photo-template .form-type-radio {
  display: inline-block;
  width: 210px;
  margin: 2px;
}

.node-form #edit-field-cover-photo-template .form-type-radio input {
  display: none;
}

.node-form .description {
  color: #999999;
  font-size: .875rem;
}

.node-form .form-wrapper {
  margin: 2em 0;
}

.form-field-type-text-long .filter-wrapper {
  padding: 0;
}

form .field-multiple-table tbody .draggable.odd {
  background-color: white;
}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

.field-add-more-submit,
.field-add-more-submit:link,
.field-add-more-submit:visited,
.field-add-more-submit:hover {
  background: none;
  color: #2ab8c6;
  border: 0;
}

.field-add-more-submit::before,
.field-add-more-submit:link::before,
.field-add-more-submit:visited::before,
.field-add-more-submit:hover::before {
  content: '+';
  display: inline;
}

#job-posting-node-form .form-item-title label::after {
  content: 'Include the short and official title of this position like "Executive Director"';
  font-size: .875rem;
  color: #999999;
  font-weight: normal;
  display: block;
}

.flag-save-job {
  float: right;
}

.flag-save-job .flag-throbber {
  display: none;
}

.flag-save-job .flag {
  font-size: 0;
  visibility: hidden;
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
}

.flag-save-job .flag::before {
  content: '\f08a';
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  font-family: FontAwesome;
  color: #e96803;
  display: block;
  width: 30px;
  font-size: 1rem;
  height: 30px;
  text-align: center;
  line-height: 30px;
  text-indent: 0;
}

.flag-save-job .flag.unflag-action::before {
  content: '\f004';
}

.node-job-posting {
  margin: auto;
  max-width: 700px;
}

.node-job-posting .callstoaction {
  width: 100%;
  display: block;
  text-align: center;
  clear: both;
  margin: 20px 0;
}

.node-job-posting .links .flag-save-job {
  display: none;
}

.node-job-posting .view-job-application-button {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
}

.node-job-posting .view-job-application-button .button {
  margin: 0;
}

.node-job-posting #howapply {
  display: none;
}

.node-job-posting .flag-outer-save-job {
  display: inline-block;
  margin: 0 5px;
  padding-top: 1px;
  vertical-align: top;
}

.node-job-posting .flag-save-job {
  float: none;
  display: inline-block;
}

.node-job-posting .flag-save-job .flag,
.node-job-posting .flag-save-job .flag:link,
.node-job-posting .flag-save-job .flag:visited,
.node-job-posting .flag-save-job .flag:hover {
  visibility: visible;
  display: block;
  border: 1px solid #e96803;
  border-radius: 3px;
  padding: 5px 8px 5px 25px;
  width: auto;
  vertical-align: middle;
  line-height: 24px;
  height: 34px;
  font-size: 1rem;
  color: #e96803;
}

.node-job-posting .flag-save-job .flag::before,
.node-job-posting .flag-save-job .flag:link::before,
.node-job-posting .flag-save-job .flag:visited::before,
.node-job-posting .flag-save-job .flag:hover::before {
  top: 2px;
}

.node-job-posting .field-name-field-brief-description {
  clear: both;
}

.node-job-posting .field-type-text .field-items .field-item::before,
.node-job-posting .field-type-list-text .field-items .field-item::before {
  content: '\2022';
  color: #e96803;
  padding-right: 5px;
  padding-left: 10px;
}

.node-job-posting .field-type-taxonomy-term-reference .field-items {
  padding-top: 10px;
}

.node-job-posting .field .field-label {
  font-size: 24px;
  font-weight: 600;
  color: #e96803;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.node-job-posting .field-name-field-type-of-employment .field-items {
  padding-top: 0;
}

.node-job-posting .view-org-logo-for-job-listing {
  float: left;
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  overflow: hidden;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo {
  height: 100px;
  width: 100px;
  border-radius: 25px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  font: 0/0 a;
  border: 1px solid #f3f3f3;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .logo-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .logo-img img {
  max-height: 98px;
  max-width: 98px;
  width: auto;
  display: block;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial {
  display: inline-block;
  height: 98px;
  width: 98px;
  line-height: 98px;
  border-radius: 25px;
  font-size: 70px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  text-align: center;
  background: #e96803;
  border-radius: 18px;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(odd) {
  background: #e96803;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(even) {
  background: #2dbfcd;
}

.node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(3), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(6), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(9), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(12), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(15), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(18), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(21), .node-job-posting .view-org-logo-for-job-listing .views-row .logo .org-initial:nth-of-type(24) {
  background: #fe9c20;
}

.node-job-posting .view-org-logo-for-job-listing img,
.node-job-posting .view-org-logo-for-job-listing a {
  display: block;
}

@media all and (min-width: 500px) {
  .node-job-posting .callstoaction {
    float: right;
    width: auto;
    margin-top: 0;
    clear: none;
  }
}

@media all and (min-width: 660px) {
  .node-job-posting .keyinfo {
    float: left;
    width: calc( 100% - 220px);
  }
}

.node-job-posting .field-name-field-occupational-category::before {
  content: 'Similar Jobs Nationwide';
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #e96803;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.page-views .node-job-posting .view-org-logo-for-job-listing .views-row .logo .logo-img img {
  max-width: 78px;
  max-height: 78px;
}

.listing-stats {
  background: #f3f3f3;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.listing-stats h3 {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}

.listing-stats h3::after {
  content: 'Come back later to see live stats like visits,\00a0 saves,\00a0 and applications received.';
  font-size: .875rem;
  color: #999999;
  font-weight: 400;
  display: block;
}

.listing-stats .field-name-field-views,
.listing-stats .view-saves-per-job,
.listing-stats .view-job-applications-count,
.listing-stats .field {
  width: auto;
  margin: 5px;
  background: #cccccc;
  padding: 5px;
  display: inline-block;
}

.listing-stats .field-name-field-views .field-label,
.listing-stats .field-name-field-views .field-items,
.listing-stats .view-saves-per-job .field-label,
.listing-stats .view-saves-per-job .field-items,
.listing-stats .view-job-applications-count .field-label,
.listing-stats .view-job-applications-count .field-items,
.listing-stats .field .field-label,
.listing-stats .field .field-items {
  font-size: 1rem;
  margin: 0;
  color: black;
  display: inline-block;
  font-weight: 400;
  padding: 0 10px;
}

.listing-stats .field-name-field-views .field-items,
.listing-stats .view-saves-per-job .field-items,
.listing-stats .view-job-applications-count .field-items,
.listing-stats .field .field-items {
  background: #f3f3f3;
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  padding: 0;
}

.pager__item a,
.pager__item a:link,
.pager__item a:visited,
.pager__item a:hover,
.pager__current-item a,
.pager__current-item a:link,
.pager__current-item a:visited,
.pager__current-item a:hover,
.pager-current a,
.pager-current a:link,
.pager-current a:visited,
.pager-current a:hover,
.pager-item a,
.pager-item a:link,
.pager-item a:visited,
.pager-item a:hover,
.pager-first a,
.pager-first a:link,
.pager-first a:visited,
.pager-first a:hover,
.pager-previous a,
.pager-previous a:link,
.pager-previous a:visited,
.pager-previous a:hover,
.pager-next a,
.pager-next a:link,
.pager-next a:visited,
.pager-next a:hover,
.pager-last a,
.pager-last a:link,
.pager-last a:visited,
.pager-last a:hover,
.pager-ellipsis a,
.pager-ellipsis a:link,
.pager-ellipsis a:visited,
.pager-ellipsis a:hover {
  padding: .25em;
  color: #2ab8c6;
}

.pager__item a:hover,
.pager__current-item a:hover,
.pager-current a:hover,
.pager-item a:hover,
.pager-first a:hover,
.pager-previous a:hover,
.pager-next a:hover,
.pager-last a:hover,
.pager-ellipsis a:hover {
  color: white;
  background: #2ab8c6;
}

.node-type-job-application ul.tabs {
  display: none;
}

.node-job-application {
  margin: auto;
  max-width: 740px;
  padding: 0;
}

.node-job-application .comment-form #edit-author--2 {
  display: none;
}

.node-job-application article.comment {
  padding: 10px 10px 5px 10px;
  margin-bottom: 30px;
  background: #f3f3f3;
  border-radius: 20px;
  position: relative;
  margin-left: 10px;
  margin-right: 50px;
}

.node-job-application article.comment::before {
  position: absolute;
  bottom: 20px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f3f3f3;
}

.node-job-application article.comment.comment-by-node-author {
  background: #ff3f3f3;
}

.node-job-application article.comment.comment-by-viewer {
  background: #2dbfcd;
  color: white;
  margin-left: 50px;
  margin-right: 10px;
}

.node-job-application article.comment.comment-by-viewer::before {
  display: none;
}

.node-job-application article.comment.comment-by-viewer::after {
  position: absolute;
  bottom: 20px;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #2dbfcd;
}

.node-job-application article.comment.comment-by-viewer a,
.node-job-application article.comment.comment-by-viewer a:link,
.node-job-application article.comment.comment-by-viewer a:visited,
.node-job-application article.comment.comment-by-viewer a:hover {
  color: white;
  text-decoration: underline;
}

.node-job-application article.comment.comment-by-viewer .username {
  visibility: hidden;
  font-size: 0;
}

.node-job-application article.comment.comment-by-viewer .username::before {
  content: 'You';
  padding-right: 1px;
  visibility: visible;
  font-size: 1rem;
}

.node-job-application article.comment .submitted {
  margin: 0;
}

.node-job-application article.comment .comment__title,
.node-job-application article.comment .links.inline,
.node-job-application article.comment .comment__permalink {
  display: none;
}

.node-job-application article.comment .field-name-comment-body {
  padding: 0;
  background: none;
}

ul.tabs {
  margin-top: 0;
  text-align: left;
  width: auto;
  background: none;
  border: 0;
  position: fixed;
  bottom: 3px;
  left: 3px;
  z-index: 100;
  padding-right: 150px;
}

ul.tabs.tabs--secondary {
  position: relative;
  left: auto;
  top: auto;
}

ul.tabs .tabs__tab {
  float: left;
  background: none;
  margin: 3px 3px 0;
  border: 0;
  border-radius: 0;
  display: inline-block;
}

ul.tabs .tabs__tab.replicate {
  display: none;
}

ul.tabs .tabs__tab.is-active {
  border: 0;
  display: none;
}

ul.tabs .tabs__tab-link,
ul.tabs .tabs__tab-link:link,
ul.tabs .tabs__tab-link:visited,
ul.tabs .tabs__tab-link:hover {
  color: #fff;
  background: #e96803;
  padding: 0 10px;
  letter-spacing: 0;
  transition: 0;
  text-shadow: none;
  border-radius: 3px;
  display: block;
  border: 1px solid white;
}

ul.tabs .tabs__tab-link:hover {
  background: #2dbfcd;
}

ul.tabs .tabs__tab-link.is-active,
ul.tabs .tabs__tab-link--is-active {
  background: none;
  display: none;
}

.user-is-author.node-type-job-posting ul.tabs .tabs__tab.replicate {
  display: inline-block;
}

.page-node h1 {
  margin-bottom: 10px;
}

.page-node main h1::before {
  color: #cccccc;
  font-size: .875rem;
  text-align: center;
  display: block;
  content: '';
  font-weight: 400;
  line-height: 1em;
}

.node-type-job-application main h1::before {
  content: 'Job Application';
}

.node-type-job-posting main h1::before {
  content: 'Job Listing';
}

.messages button.dismiss {
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  margin: 0;
  top: .2em;
  right: .2em;
  background: none;
  border-radius: 0;
}

.messages button.dismiss::before {
  color: black;
  text-shadow: 0;
}

.messages,
.crm-block .help,
.crm-block #help,
.crm-container div.messages {
  border: 0;
  padding: 20px;
  max-width: 600px;
  width: auto;
  display: block;
  margin: 1.5rem auto;
}

.messages h3,
.crm-block .help h3,
.crm-block #help h3,
.crm-container div.messages h3 {
  margin: 0;
}

.messages.messages--status,
.crm-block .help.messages--status,
.crm-block #help.messages--status,
.crm-container div.messages.messages--status {
  background: #bce4de;
}

.messages.messages--error,
.crm-block .help.messages--error,
.crm-block #help.messages--error,
.crm-container div.messages.messages--error {
  background: #fff0f0;
}

.messages.messages--warning,
.crm-block .help.messages--warning,
.crm-block #help.messages--warning,
.crm-container div.messages.messages--warning {
  background: #ffe79d;
}

.messages .messages__icon,
.crm-block .help .messages__icon,
.crm-block #help .messages__icon,
.crm-container div.messages .messages__icon {
  display: none;
}

.crm-container div.messages .icon,
.crm-container div.messages .inform-icon {
  display: none;
}

.crm-container div.messages.status {
  background: #ffe79d;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background: #bce4de;
  color: black;
}

#colorbox {
  background: white;
  overflow: visible !important;
}

#colorbox header.header {
  display: none;
}

#colorbox main h1 {
  margin: 20px 0 0;
  font-size: 24px;
}

#colorbox main h1::before {
  display: none;
}

#colorbox #user-login,
#colorbox #user-register-form,
#colorbox #user-pass {
  padding-top: 170px;
  background: none;
}

#colorbox #user-login::before,
#colorbox #user-register-form::before,
#colorbox #user-pass::before {
  content: 'Sign into your account';
  font-size: 24px;
  font-weight: 600;
  color: #e96803;
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 20px;
  left: 0;
}

#colorbox #user-login .hybridauth-widget-wrapper,
#colorbox #user-register-form .hybridauth-widget-wrapper,
#colorbox #user-pass .hybridauth-widget-wrapper {
  top: 70px;
}

#colorbox #user-register-form::before {
  content: 'Create a new account';
}

#colorbox #user-pass {
  padding-top: 40px;
}

#colorbox #user-pass::before {
  content: 'Reset your password';
}

#colorbox .node-job_application-form {
  padding: 20px 20px 40px;
}

#colorbox .node-job_application-form::before {
  content: 'Submit your job application';
  font-size: 24px;
  font-weight: 600;
  color: #e96803;
  display: block;
  text-align: center;
}

#colorbox .node-job_application-form #edit-field-job {
  display: none;
}

#colorbox .node-job_application-form .form-wrapper {
  margin: 1rem 0 0;
}

#colorbox .node-job_application-form #edit-field-email-und-0-email {
  max-width: 274px;
}

#colorbox .node-job_application-form > div:first-of-type::before {
  display: block;
  width: 100%;
  content: 'Please fill out the form below and we\'ll send your job application straight to the organization’s recruiting team.';
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #999999;
  font-size: .875rem;
}

@media all and (min-width: 480px) {
  #colorbox .node-job_application-form #edit-field-first-name,
  #colorbox .node-job_application-form #edit-field-last-name {
    display: inline-block;
    width: 49%;
  }
  #colorbox .node-job_application-form #edit-field-first-name .form-item,
  #colorbox .node-job_application-form #edit-field-last-name .form-item {
    margin: 0;
  }
}

body #job-application-node-form #edit-field-source {
  display: none;
}

body #job-application-node-form .form-wrapper.form-field-name-field-resume {
  background: #f3f3f3;
  margin-top: -20px;
  padding: 10px;
}

body #job-application-node-form .form-wrapper.form-field-name-field-resume .form-item {
  margin-top: 0;
}

body #job-application-node-form .form-field-name-field-resume-on-file .description p {
  margin: 10px 0 0;
}

body #job-application-node-form .replace-resume {
  cursor: pointer;
}

.logged-in #job-application-node-form .form-wrapper.form-field-name-field-resume {
  display: none;
}

.logged-in #job-application-node-form .form-wrapper.form-field-name-field-resume label::before {
  content: 'New';
  padding-right: 3px;
}

.not-logged-in #job-application-node-form .form-wrapper.form-field-name-field-resume {
  margin-top: 20px;
}

#howapply-inner {
  margin: auto;
  max-width: 500px;
  padding: 20px;
}

#howapply-inner h3 {
  margin-bottom: .5em;
  margin-top: .5rem;
  color: #e96803;
}

.field-type-file .file-widget {
  background: #cccccc;
  padding: 10px;
}

.field-type-file .description {
  color: #999999;
}

.field-type-file .description strong {
  font-weight: normal;
}

.form-managed-file .form-file {
  margin-bottom: 10px;
}

.hybridauth-widget-wrapper {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 400px;
  width: 100%;
}

.hybridauth-widget-wrapper::after {
  content: 'Or sign in below';
  color: #999999;
  border-bottom: 1px solid #f3f3f3;
  display: block;
  margin: 10px 10px 0;
}

.hybridauth-widget-wrapper li {
  margin: 5px 2px;
}

.hybridauth-widget-wrapper .hybridauth-icon {
  display: block;
  font-family: 1rem;
  line-height: 32px;
  height: 32px;
  filter: none;
  border-radius: 3px;
  opacity: 1;
  width: auto;
  padding: 0;
  color: white;
  text-align: left;
}

.hybridauth-widget-wrapper .hybridauth-icon::before {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 1.3rem;
  width: 32px;
  text-align: center;
}

.hybridauth-widget-wrapper .hybridauth-icon::after {
  font-family: .875rem;
  vertical-align: middle;
  display: inline-block;
  margin-top: -5px;
  text-align: center;
  width: 188px;
}

.hybridauth-widget-wrapper .hybridauth-icon.facebook {
  background: #3b5998;
  line-height: 32px;
}

.hybridauth-widget-wrapper .hybridauth-icon.facebook::before {
  content: '\f09a';
  border-right: 1px solid white;
}

.hybridauth-widget-wrapper .hybridauth-icon.facebook::after {
  content: 'Sign In with Facebook';
}

.hybridauth-widget-wrapper .hybridauth-icon.google {
  background: #c00;
}

.hybridauth-widget-wrapper .hybridauth-icon.google::before {
  content: '\f1a0';
  border-right: 1px solid white;
}

.hybridauth-widget-wrapper .hybridauth-icon.google::after {
  content: 'Sign In with Google';
}

.page-user-edit #user-profile-form {
  background: #f3f3f3;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.page-user-edit #edit-timezone {
  padding: 0;
}

.page-user-edit #edit-timezone legend {
  display: none;
}

.page-user-hybridauth main table {
  max-width: 800px;
  margin: auto;
  width: 100%;
}

.page-user-hybridauth main table th {
  background: #f3f3f3;
  border: 1px solid white;
  padding: 5px;
}

.page-user-hybridauth main table .empty.message {
  font-size: 0;
  visibility: hidden;
  padding-top: 0;
  width: 100%;
}

.page-user-hybridauth main table .empty.message::after {
  content: 'You don\'t have any connected accounts yet.';
  font-size: 1rem;
  visibility: visible;
  text-align: center;
  background: #fff0f0;
  padding: 20px;
  margin: 0 auto;
  max-width: 500px;
  display: block;
}

.page-user-hybridauth h1 {
  visibility: hidden;
  font-size: 0;
  margin-bottom: 0;
  line-height: 1rem;
}

.page-user-hybridauth h1::before {
  content: 'My Social Logins';
  visibility: visible;
  display: block;
  font-size: 32px;
  margin: 30px 0 0;
}

.page-user-hybridauth h1::after {
  content: 'This allows you to login with your Facebook or Google accounts';
  font-size: 1rem;
  padding: 10px;
  visibility: visible;
  display: block;
  font-weight: 400;
  margin: 0;
}

.page-user-hybridauth .hybridauth-widget-wrapper {
  position: relative;
  top: auto;
  text-align: center;
  left: auto;
  width: auto;
}

.page-user-hybridauth .hybridauth-widget-wrapper .item-list h3 {
  font-size: 0;
  visibility: hidden;
}

.page-user-hybridauth .hybridauth-widget-wrapper .item-list h3::after {
  content: 'Add a connected account';
  font-size: 1.25rem;
  visibility: visible;
}

.page-user-hybridauth .hybridauth-widget-wrapper::after {
  display: none;
}

#user-login,
#user-register-form,
#user-pass {
  padding: 80px 20px 20px;
  margin: auto;
  max-width: 400px;
  position: relative;
}

#user-login .form-item-persistent-login,
#user-register-form .form-item-persistent-login,
#user-pass .form-item-persistent-login {
  display: none;
}

#user-login .form-type-password .description,
#user-login .form-item-name .description,
#user-register-form .form-type-password .description,
#user-register-form .form-item-name .description,
#user-pass .form-type-password .description,
#user-pass .form-item-name .description {
  display: none;
}

#user-login .forgotpswd,
#user-register-form .forgotpswd,
#user-pass .forgotpswd {
  text-align: right;
}

#user-login .newacct,
#user-register-form .newacct,
#user-pass .newacct {
  border-top: 1px solid #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
}

#user-register-form .signinform {
  border-top: 1px solid #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
}

.page-user .profile {
  display: none;
}

.page-user .region-header .block .signin {
  display: none;
}

.page-user #user-login {
  background: #f3f3f3;
  padding: 120px 20px 20px;
}

.page-user #user-login .hybridauth-widget-wrapper {
  top: 20px;
}

.page-user #user-login .hybridauth-widget-wrapper::after {
  border-color: #cccccc;
}

.page-user main {
  text-align: center;
}

.page-user main .block {
  width: auto;
  display: block;
  text-align: left;
  margin: 0 0 40px;
  vertical-align: top;
  padding: 0;
  text-align: center;
}

.page-user main .block::after {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

.page-user main .block .block__title {
  margin: 0;
  color: #e96803;
  font-size: 20px;
  padding: 10px;
  line-height: 1em;
}

.page-user main .block .views-row {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  width: auto;
  margin: 2.5px 1px;
}

.page-user main .block .views-row a,
.page-user main .block .views-row a:link,
.page-user main .block .views-row a:visited,
.page-user main .block .views-row a:hover {
  background: #f3f3f3;
  padding: 10px 20px;
  display: block;
  width: auto;
  color: black;
}

.page-user main .block .views-row a .title,
.page-user main .block .views-row a:link .title,
.page-user main .block .views-row a:visited .title,
.page-user main .block .views-row a:hover .title {
  color: #2dbfcd;
}

.page-user main .block .views-row a:hover {
  background: #ddd;
}

.page-user-register h1,
.page-user-password h1,
.page-user h1 {
  margin-bottom: 20px;
}

.page-user-register ul.tabs,
.page-user-password ul.tabs,
.page-user ul.tabs {
  display: none;
}

.page-user-register main,
.page-user-password main {
  text-align: left;
}

.page-user-register .region-header .block .signin,
.page-user-password .region-header .block .signin {
  display: inline-block;
}

.page-user-register #user-register-form {
  padding-top: 140px;
}

#user-register-form,
#user-pass {
  background: #f3f3f3;
  padding: 100px 20px 20px;
}

#user-register-form #edit-actions,
#user-pass #edit-actions {
  margin-top: 40px;
}

#user-register-form .hybridauth-widget-wrapper,
#user-pass .hybridauth-widget-wrapper {
  top: 30px;
}

#user-register-form .hybridauth-widget-wrapper::after,
#user-pass .hybridauth-widget-wrapper::after {
  border-color: #cccccc;
  color: #666666;
  content: 'Or create a new account below';
}

#user-register-form .hybridauth-widget-wrapper .hybridauth-icon.facebook::after,
#user-pass .hybridauth-widget-wrapper .hybridauth-icon.facebook::after {
  content: 'Register with Facebook';
}

#user-register-form .hybridauth-widget-wrapper .hybridauth-icon.google::after,
#user-pass .hybridauth-widget-wrapper .hybridauth-icon.google::after {
  content: 'Register with Google';
}

#user-pass {
  max-width: 400px;
  padding: 20px;
}

#user-register-form #edit-timezone {
  display: none;
}

#user-register-form .password-strength,
#user-register-form .field-name-field-registration-source {
  display: none;
}

#user-register-form .password-parent {
  width: auto;
}

#user-register-form .confirm-parent {
  width: auto;
  display: flex;
  flex-direction: column;
}

#user-register-form .confirm-parent label {
  order: 1;
}

#user-register-form .confirm-parent #edit-pass-pass2 {
  order: 2;
}

#user-register-form .confirm-parent div.password-confirm {
  float: none;
  margin-top: 0;
  order: 3;
}

#user-register-form .confirm-parent div.password-confirm .ok,
#user-register-form .confirm-parent div.password-confirm .error {
  padding: 0 10px;
}

#user-register-form div.password-suggestions {
  border: 0;
  font-size: .75rem;
  color: #999999;
  width: auto;
}

#user-register-form div.password-suggestions ul {
  margin-top: 10px;
}

#user-register-form .form-type-password-confirm .description,
#user-register-form .form-item-mail .description {
  display: none;
}

.node,
.node-form {
  margin: auto;
  max-width: 740px;
  padding: 0;
}

.node .field-group-fieldset,
.node .form-field-type-image fieldset,
.node-form .field-group-fieldset,
.node-form .form-field-type-image fieldset {
  background: #f7f7f7;
  border: 0;
  margin: 1.5rem 0;
  padding: 20px 30px;
}

.node .field-group-fieldset legend,
.node .form-field-type-image fieldset legend,
.node-form .field-group-fieldset legend,
.node-form .form-field-type-image fieldset legend {
  background: #fe9c20;
  color: white;
  padding: 5px 15px;
}

.node .field-group-fieldset .field,
.node .form-field-type-image fieldset .field,
.node-form .field-group-fieldset .field,
.node-form .form-field-type-image fieldset .field {
  margin: 1rem 0;
}

.node .field-group-fieldset .field .field-item p,
.node .form-field-type-image fieldset .field .field-item p,
.node-form .field-group-fieldset .field .field-item p,
.node-form .form-field-type-image fieldset .field .field-item p {
  margin-top: 0;
}

.node .field-group-fieldset .field.field-label-inline .field-label,
.node .form-field-type-image fieldset .field.field-label-inline .field-label,
.node-form .field-group-fieldset .field.field-label-inline .field-label,
.node-form .form-field-type-image fieldset .field.field-label-inline .field-label {
  display: block;
}

@media all and (min-width: 480px) {
  .node .field-group-fieldset .field.field-label-inline .field-label,
  .node .form-field-type-image fieldset .field.field-label-inline .field-label,
  .node-form .field-group-fieldset .field.field-label-inline .field-label,
  .node-form .form-field-type-image fieldset .field.field-label-inline .field-label {
    width: 100px;
    display: inline-block;
    vertical-align: top;
  }
  .node .field-group-fieldset .field.field-label-inline .field-items,
  .node .form-field-type-image fieldset .field.field-label-inline .field-items,
  .node-form .field-group-fieldset .field.field-label-inline .field-items,
  .node-form .form-field-type-image fieldset .field.field-label-inline .field-items {
    display: inline-block;
    width: calc( 100% - 120px);
  }
}

.node .comment__form-title,
.node-form .comment__form-title {
  font-size: 24px;
  color: #e96803;
  margin: 2rem 0 .5rem;
}

.node .link-field-url .form-item,
.node-form .link-field-url .form-item {
  margin: 0;
}

.node-type-organization ul.tabs {
  text-align: left;
}

.node-type-organization h1 {
  display: none;
}

.node-type-organization h1.field-content, .node-type-organization h1.views-field {
  display: block;
  text-align: left;
}

.node-type-organization.page-node-edit h1 {
  display: block;
}

.node-type-organization.page-node-edit ul.tabs {
  text-align: center;
}

#block-views-organization-header-block {
  max-width: 1133px;
  margin: auto;
  display: block;
  padding-bottom: 5px;
  overflow: hidden;
}

#block-views-organization-header-block .logo {
  background: white;
  font: 0/0 a;
}

#block-views-organization-header-block .logo .logo-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
}

#block-views-organization-header-block .logo .logo-img img {
  max-height: 100%;
  width: auto;
}

#block-views-organization-header-block .logo .org-initial {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -1px;
  left: -1px;
  color: white;
  text-align: center;
  background: #e96803;
}

@media all and (min-width: 700px) {
  #block-views-organization-header-block .logo .org-initial {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

#block-views-organization-header-block .logo .org-initial:nth-of-type(odd) {
  background: #e96803;
}

#block-views-organization-header-block .logo .org-initial:nth-of-type(even) {
  background: #2dbfcd;
}

#block-views-organization-header-block .logo .org-initial:nth-of-type(3), #block-views-organization-header-block .logo .org-initial:nth-of-type(6), #block-views-organization-header-block .logo .org-initial:nth-of-type(9), #block-views-organization-header-block .logo .org-initial:nth-of-type(12), #block-views-organization-header-block .logo .org-initial:nth-of-type(15), #block-views-organization-header-block .logo .org-initial:nth-of-type(18), #block-views-organization-header-block .logo .org-initial:nth-of-type(21), #block-views-organization-header-block .logo .org-initial:nth-of-type(24) {
  background: #fe9c20;
}

#block-views-organization-header-block .logo::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

#block-views-organization-header-block .views-field-title {
  margin-top: 40px;
  text-align: center;
  font-weight: 600;
  padding: 0 10px;
  font-size: 1.3rem;
  line-height: 1.2em;
}

#block-views-organization-header-block .views-field-field-cover-photo {
  width: 100%;
  display: block;
  z-index: -1;
}

@media all and (max-width: 400px) {
  #block-views-organization-header-block .views-field-field-cover-photo {
    width: 160%;
    margin-left: -30%;
    margin-right: -30%;
  }
}

@media all and (min-width: 401px) and (max-width: 700px) {
  #block-views-organization-header-block .views-field-field-cover-photo {
    width: 140%;
    margin-left: -20%;
    margin-right: -20%;
  }
}

#block-views-organization-header-block .views-field-field-cover-photo img {
  z-index: -1;
}

#block-views-organization-header-block .views-field-field-logo {
  z-index: 100;
  margin: 0 auto;
  display: block;
  width: 70px;
  position: relative;
}

#block-views-organization-header-block .views-field-field-logo span.logo {
  z-index: 100;
  position: absolute;
  top: -35px;
  left: 0;
  display: block;
  background: white;
  border: 1px solid #cccccc;
  padding: 5px;
  width: 70px;
  height: 70px;
}

#block-views-organization-header-block .views-field-field-logo span.logo .logo-img img {
  max-width: 58px;
  max-height: 58px;
}

@media all and (min-width: 700px) {
  #block-views-organization-header-block {
    background: #f7f7f7;
    padding-bottom: 10px;
  }
  #block-views-organization-header-block .views-field-title {
    margin-left: calc( 2.4% + 120px);
    text-align: left;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  #block-views-organization-header-block .views-field-field-logo {
    margin-left: 2.4%;
    width: 100px;
  }
  #block-views-organization-header-block .views-field-field-logo span.logo {
    padding: 10px;
    top: -40px;
    width: 100px;
    height: 100px;
  }
  #block-views-organization-header-block .views-field-field-logo span.logo .logo-img img {
    max-width: 78px;
    max-height: 78px;
  }
}

@media all and (min-width: 1024px) {
  #block-views-organization-header-block .views-field-title {
    font-size: 2rem;
  }
}

.node-organization {
  max-width: 100%;
}

.node-organization .field-name-field-logo {
  float: left;
  margin: 20px 20px 0 0;
  width: 100px;
}

.node-organization h1.view-organization-header {
  display: block;
  margin-left: 120px;
}

.node-organization .group-overview {
  clear: both;
}

@media all and (min-width: 480px) {
  .node-organization .group-overview .field.field-label-inline .field-label {
    width: 200px;
  }
  .node-organization .group-overview .field.field-label-inline .field-items {
    width: calc( 100% - 220px);
  }
}

.node-organization .field-name-field-photos .field-items {
  text-align: center;
}

@media all and (min-width: 650px) {
  .node-organization .field-name-field-photos .field-items {
    text-align: left;
  }
}

.node-organization .field-name-field-photos .field-items .field-item {
  width: 165px;
  display: inline-block;
  margin: 2px;
  vertical-align: top;
}

#block-views-available-jobs-block .view-empty {
  padding: 10px 10px 20px;
  text-align: center;
}

#block-views-available-jobs-block .job,
#block-views-available-jobs-block .job:link,
#block-views-available-jobs-block .job:visited,
#block-views-available-jobs-block .job:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
  color: black;
  padding: 5px 10px;
  display: block;
}

#block-views-available-jobs-block .job:first-of-type,
#block-views-available-jobs-block .job:link:first-of-type,
#block-views-available-jobs-block .job:visited:first-of-type,
#block-views-available-jobs-block .job:hover:first-of-type {
  border-top: 1px solid white;
}

#block-views-available-jobs-block .job .job-title,
#block-views-available-jobs-block .job:link .job-title,
#block-views-available-jobs-block .job:visited .job-title,
#block-views-available-jobs-block .job:hover .job-title {
  color: #2dbfcd;
}

#block-views-available-jobs-block .job .job-location,
#block-views-available-jobs-block .job:link .job-location,
#block-views-available-jobs-block .job:visited .job-location,
#block-views-available-jobs-block .job:hover .job-location {
  font-size: .875rem;
}

#block-views-available-jobs-block .job .job-type,
#block-views-available-jobs-block .job .job-date,
#block-views-available-jobs-block .job:link .job-type,
#block-views-available-jobs-block .job:link .job-date,
#block-views-available-jobs-block .job:visited .job-type,
#block-views-available-jobs-block .job:visited .job-date,
#block-views-available-jobs-block .job:hover .job-type,
#block-views-available-jobs-block .job:hover .job-date {
  color: #999;
  font-size: .75rem;
}

#block-views-available-jobs-block .job .job-date,
#block-views-available-jobs-block .job:link .job-date,
#block-views-available-jobs-block .job:visited .job-date,
#block-views-available-jobs-block .job:hover .job-date {
  float: right;
}

#block-views-available-jobs-block .job .easy-apply-1,
#block-views-available-jobs-block .job:link .easy-apply-1,
#block-views-available-jobs-block .job:visited .easy-apply-1,
#block-views-available-jobs-block .job:hover .easy-apply-1 {
  color: black;
  padding: 0 3px;
  background: #f1f1f1;
  font-size: .75rem;
  float: right;
  margin-left: 10px;
}

#block-views-available-jobs-block .job .easy-apply-2,
#block-views-available-jobs-block .job:link .easy-apply-2,
#block-views-available-jobs-block .job:visited .easy-apply-2,
#block-views-available-jobs-block .job:hover .easy-apply-2 {
  display: none;
}

#block-views-available-jobs-block .job:hover {
  background: #f3f3f3;
}

.layout-3col__right-sidebar {
  margin-top: 0;
}

.layout-3col__right-sidebar .pager {
  padding-bottom: 20px;
}

.layout-3col__right-sidebar .view-organization-video {
  padding: 10px;
}

.layout-3col__right-sidebar .view-organization-video .views-field-field-video a,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:link,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:visited a:hover {
  display: block;
  position: relative;
  overflow: hidden;
}

.layout-3col__right-sidebar .view-organization-video .views-field-field-video a img,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:link img,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:visited a:hover img {
  margin-top: -35px;
  margin-bottom: -35px;
}

.layout-3col__right-sidebar .view-organization-video .views-field-field-video a::before,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:link::before,
.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:visited a:hover::before {
  content: '\f04b';
  border: 1px solid white;
  border-radius: 50%;
  font-family: FontAwesome;
  font-size: 30px;
  padding-left: 5px;
  position: absolute;
  width: 45px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  color: white;
  opacity: .7;
}

.layout-3col__right-sidebar .view-organization-video .views-field-field-video a:hover::before {
  color: #c4302b;
  opacity: 1;
  border-color: #c4302b;
}

.layout-3col__right-sidebar .block {
  position: relative;
  margin-top: 41px;
  padding-top: 20px;
  background: #f7f7f7;
}

.layout-3col__right-sidebar .block__title {
  background: #fe9c20;
  line-height: 1.5rem;
  width: auto;
  color: white;
  margin: 0;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  top: -17px;
  left: 25px;
  font-weight: 400;
  padding: 5px 15px;
}

.layout-3col__right-sidebar .view-organization-social-media {
  padding: 10px;
  text-align: center;
}

.node .group-social {
  text-align: center;
  display: inline-block;
  min-width: 250px;
}

.node .group-social .field-item,
.node .group-social .field-items,
.node .group-social .field {
  margin: 0;
  display: inline-block;
}

.social,
.social:link,
.social:visited,
.social:hover,
.group-social a,
.group-social a:link,
.group-social a:visited,
.group-social a:hover {
  display: inline-block;
  margin: 10px;
  text-align: center;
  line-height: 40px;
  transform: scale(1);
  transition-duration: .3s;
  font-size: 0;
  visibility: hidden;
}

.social::before,
.social:link::before,
.social:visited::before,
.social:hover::before,
.group-social a::before,
.group-social a:link::before,
.group-social a:visited::before,
.group-social a:hover::before {
  font-family: FontAwesome;
  color: white;
  display: inline-block;
  font-size: 25px;
  visibility: visible;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.social:hover,
.group-social a:hover {
  transform: scale(1.2);
  transition-duration: .3s;
}

.facebook,
.facebook:link,
.facebook:visited,
.facebook:hover,
.group-social .field-name-field-facebook a,
.group-social .field-name-field-facebook a:link,
.group-social .field-name-field-facebook a:visited,
.group-social .field-name-field-facebook a:hover {
  line-height: 43px;
}

.facebook::before,
.facebook:link::before,
.facebook:visited::before,
.facebook:hover::before,
.group-social .field-name-field-facebook a::before,
.group-social .field-name-field-facebook a:link::before,
.group-social .field-name-field-facebook a:visited::before,
.group-social .field-name-field-facebook a:hover::before {
  content: '\f09a';
  background: #3b5998;
}

.twitter,
.twitter:link,
.twitter:visited,
.twitter:hover,
.group-social .field-name-field-twitter a,
.group-social .field-name-field-twitter a:link,
.group-social .field-name-field-twitter a:visited,
.group-social .field-name-field-twitter a:hover {
  line-height: 43px;
}

.twitter::before,
.twitter:link::before,
.twitter:visited::before,
.twitter:hover::before,
.group-social .field-name-field-twitter a::before,
.group-social .field-name-field-twitter a:link::before,
.group-social .field-name-field-twitter a:visited::before,
.group-social .field-name-field-twitter a:hover::before {
  content: '\f099';
  background: #4099ff;
}

.linkedin::before,
.linkedin:link::before,
.linkedin:visited::before,
.linkedin:hover::before,
.group-social .field-name-field-linkedin a::before,
.group-social .field-name-field-linkedin a:link::before,
.group-social .field-name-field-linkedin a:visited::before,
.group-social .field-name-field-linkedin a:hover::before {
  content: '\f0e1';
  background: #007bb6;
}

.youtube::before,
.youtube:link::before,
.youtube:visited::before,
.youtube:hover::before,
.group-social .field-name-field-youtube a::before,
.group-social .field-name-field-youtube a:link::before,
.group-social .field-name-field-youtube a:visited::before,
.group-social .field-name-field-youtube a:hover::before {
  content: '\f167';
  background: #c4302b;
}

.field-type-taxonomy-term-reference .field-items .field-item a,
.field-type-taxonomy-term-reference .field-items .field-item a:link,
.field-type-taxonomy-term-reference .field-items .field-item a:visited,
.field-type-taxonomy-term-reference .field-items .field-item a:hover {
  background: #ededed;
  border-radius: 20px;
  padding: 5px 15px;
  margin: 5px;
  display: inline-block;
}

.field-type-taxonomy-term-reference .field-items .field-item a:hover {
  color: white;
  background: #2dbfcd;
}

main .block__title {
  text-align: center;
  font-size: 24px;
}

main .block {
  padding: 20px 0;
}

@media all and (min-width: 600px) {
  main .block__title {
    font-size: 32px;
  }
  main .block {
    padding: 40px 0;
  }
}

main .region-help .block {
  padding: 0;
}

#block-views-nonprofits-hiring-now-block .view-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}

#block-views-nonprofits-hiring-now-block .views-row {
  flex-basis: 30%;
}

#block-views-nonprofits-hiring-now-block .views-row:nth-of-type(4), #block-views-nonprofits-hiring-now-block .views-row:nth-of-type(5) {
  display: none;
}

#block-views-nonprofits-hiring-now-block .views-row a,
#block-views-nonprofits-hiring-now-block .views-row a:link,
#block-views-nonprofits-hiring-now-block .views-row a:visited,
#block-views-nonprofits-hiring-now-block .views-row a:hover {
  display: block;
  height: 100%;
  border: 1px solid #f3f3f3;
  font: 0/0 a;
  position: relative;
  overflow: hidden;
  text-align: center;
}

#block-views-nonprofits-hiring-now-block .views-row a::before,
#block-views-nonprofits-hiring-now-block .views-row a:link::before,
#block-views-nonprofits-hiring-now-block .views-row a:visited::before,
#block-views-nonprofits-hiring-now-block .views-row a:hover::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

#block-views-nonprofits-hiring-now-block .views-row a img,
#block-views-nonprofits-hiring-now-block .views-row a:link img,
#block-views-nonprofits-hiring-now-block .views-row a:visited img,
#block-views-nonprofits-hiring-now-block .views-row a:hover img {
  max-width: 90%;
  margin: 5%;
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
  max-height: 100%;
}

#block-views-nonprofits-hiring-now-block .views-row a:hover {
  border-color: #2dbfcd;
}

@media all and (min-width: 600px) {
  #block-views-nonprofits-hiring-now-block .views-row {
    flex-basis: 17.6%;
  }
  #block-views-nonprofits-hiring-now-block .views-row a img,
  #block-views-nonprofits-hiring-now-block .views-row a:link img,
  #block-views-nonprofits-hiring-now-block .views-row a:visited img,
  #block-views-nonprofits-hiring-now-block .views-row a:hover img {
    margin: 10%;
    max-width: 80%;
  }
  #block-views-nonprofits-hiring-now-block .views-row:nth-of-type(4), #block-views-nonprofits-hiring-now-block .views-row:nth-of-type(5) {
    display: block;
  }
}

.more-link {
  text-align: center;
  margin-top: 30px;
}

.more-link a {
  background: #fe9c20;
}

#block-views-nonprofits-hiring-now-block {
  max-width: 1000px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#block-block-6,
.node-3,
.node-218,
.node-2674,
.node-3852 {
  background: #f3f3f3;
  padding: 0;
}

#block-block-6 .block__title,
.node-3 .block__title,
.node-218 .block__title,
.node-2674 .block__title,
.node-3852 .block__title {
  margin: 0 0 20px;
  padding: 30px 20px;
  background: #e9e9e9;
}

#block-block-6::after,
.node-3::after,
.node-218::after,
.node-2674::after,
.node-3852::after {
  clear: both;
  display: table;
  content: '';
  width: 100%;
}

#block-block-6 .inner-block-6,
.node-3 .inner-block-6,
.node-218 .inner-block-6,
.node-2674 .inner-block-6,
.node-3852 .inner-block-6 {
  max-width: 1000px;
  margin: auto;
}

#block-block-6 .block-6-text,
.node-3 .block-6-text,
.node-218 .block-6-text,
.node-2674 .block-6-text,
.node-3852 .block-6-text {
  max-width: 600px;
  padding: 0 10px;
  margin: 30px auto 0;
}

#block-block-6 .block-6-text div,
.node-3 .block-6-text div,
.node-218 .block-6-text div,
.node-2674 .block-6-text div,
.node-3852 .block-6-text div {
  text-align: center;
  max-width: 300px;
  margin: auto;
}

#block-block-6 .block-6-text h4,
.node-3 .block-6-text h4,
.node-218 .block-6-text h4,
.node-2674 .block-6-text h4,
.node-3852 .block-6-text h4 {
  font-size: 1.2rem;
  margin-top: 0;
  text-align: center;
}

#block-block-6 .recruiterimg,
#block-block-6 .careerpageimg,
.node-3 .recruiterimg,
.node-3 .careerpageimg,
.node-218 .recruiterimg,
.node-218 .careerpageimg,
.node-2674 .recruiterimg,
.node-2674 .careerpageimg,
.node-3852 .recruiterimg,
.node-3852 .careerpageimg {
  position: relative;
  border-radius: 3px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 3px #cccccc;
  max-width: 300px;
  margin: auto;
  display: block;
}

#block-block-6 .buttons,
.node-3 .buttons,
.node-218 .buttons,
.node-2674 .buttons,
.node-3852 .buttons {
  text-align: center;
}

#block-block-6 .recruiter-container,
.node-3 .recruiter-container,
.node-218 .recruiter-container,
.node-2674 .recruiter-container,
.node-3852 .recruiter-container {
  background: #e9e9e9;
  width: 100%;
  margin-top: 30px;
  padding: 30px 0;
}

#block-block-6 .recruiterdash,
.node-3 .recruiterdash,
.node-218 .recruiterdash,
.node-2674 .recruiterdash,
.node-3852 .recruiterdash {
  max-width: 600px;
}

@media all and (min-width: 400px) {
  #block-block-6 .block-6-text div,
  .node-3 .block-6-text div,
  .node-218 .block-6-text div,
  .node-2674 .block-6-text div,
  .node-3852 .block-6-text div {
    max-width: 100%;
  }
}

@media all and (min-width: 900px) {
  #block-block-6 .recruiterdash,
  #block-block-6 .recruiterimg,
  #block-block-6 .careerpageimg,
  #block-block-6 .block-6-text,
  .node-3 .recruiterdash,
  .node-3 .recruiterimg,
  .node-3 .careerpageimg,
  .node-3 .block-6-text,
  .node-218 .recruiterdash,
  .node-218 .recruiterimg,
  .node-218 .careerpageimg,
  .node-218 .block-6-text,
  .node-2674 .recruiterdash,
  .node-2674 .recruiterimg,
  .node-2674 .careerpageimg,
  .node-2674 .block-6-text,
  .node-3852 .recruiterdash,
  .node-3852 .recruiterimg,
  .node-3852 .careerpageimg,
  .node-3852 .block-6-text {
    max-width: 50%;
  }
  #block-block-6 .buttons,
  .node-3 .buttons,
  .node-218 .buttons,
  .node-2674 .buttons,
  .node-3852 .buttons {
    text-align: left;
  }
  #block-block-6 .recruiterimg,
  #block-block-6 .careerpageimg,
  #block-block-6 .block-6-text,
  #block-block-6 .recruiter-container,
  .node-3 .recruiterimg,
  .node-3 .careerpageimg,
  .node-3 .block-6-text,
  .node-3 .recruiter-container,
  .node-218 .recruiterimg,
  .node-218 .careerpageimg,
  .node-218 .block-6-text,
  .node-218 .recruiter-container,
  .node-2674 .recruiterimg,
  .node-2674 .careerpageimg,
  .node-2674 .block-6-text,
  .node-2674 .recruiter-container,
  .node-3852 .recruiterimg,
  .node-3852 .careerpageimg,
  .node-3852 .block-6-text,
  .node-3852 .recruiter-container {
    float: left;
  }
  #block-block-6 .block-6-text,
  .node-3 .block-6-text,
  .node-218 .block-6-text,
  .node-2674 .block-6-text,
  .node-3852 .block-6-text {
    padding-left: 40px;
    margin: 0;
  }
  #block-block-6 .block-6-text h4,
  .node-3 .block-6-text h4,
  .node-218 .block-6-text h4,
  .node-2674 .block-6-text h4,
  .node-3852 .block-6-text h4 {
    text-align: left;
    font-size: 1.5rem;
  }
  #block-block-6 .recruiterdash,
  .node-3 .recruiterdash,
  .node-218 .recruiterdash,
  .node-2674 .recruiterdash,
  .node-3852 .recruiterdash {
    padding-left: 20px;
    padding-right: 20px;
  }
  #block-block-6 .recruiterdash div,
  .node-3 .recruiterdash div,
  .node-218 .recruiterdash div,
  .node-2674 .recruiterdash div,
  .node-3852 .recruiterdash div {
    text-align: left;
  }
  #block-block-6 .recruiterimg,
  .node-3 .recruiterimg,
  .node-218 .recruiterimg,
  .node-2674 .recruiterimg,
  .node-3852 .recruiterimg {
    float: right;
  }
}

@media all and (min-width: 1024px) {
  #block-block-6 .block-6-text,
  .node-3 .block-6-text,
  .node-218 .block-6-text,
  .node-2674 .block-6-text,
  .node-3852 .block-6-text {
    padding-top: 60px;
  }
  #block-block-6 .recruiterdash,
  .node-3 .recruiterdash,
  .node-218 .recruiterdash,
  .node-2674 .recruiterdash,
  .node-3852 .recruiterdash {
    padding-left: 0;
  }
}

.page-node-3 main h1,
.page-node-16 main h1,
.page-node-218 main h1,
.page-node-378 main h1,
.page-node-2674 main h1,
.page-node-3852 main h1 {
  display: none;
}

.page-node-3 .layout-center,
.page-node-3 .layout-3col,
.page-node-3 .layout-3col__full,
.page-node-16 .layout-center,
.page-node-16 .layout-3col,
.page-node-16 .layout-3col__full,
.page-node-218 .layout-center,
.page-node-218 .layout-3col,
.page-node-218 .layout-3col__full,
.page-node-378 .layout-center,
.page-node-378 .layout-3col,
.page-node-378 .layout-3col__full,
.page-node-2674 .layout-center,
.page-node-2674 .layout-3col,
.page-node-2674 .layout-3col__full,
.page-node-3852 .layout-center,
.page-node-3852 .layout-3col,
.page-node-3852 .layout-3col__full {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.page-node-3 .node,
.page-node-16 .node,
.page-node-218 .node,
.page-node-378 .node,
.page-node-2674 .node,
.page-node-3852 .node {
  background: none;
  max-width: 100%;
}

.page-node-3 .node #block-block-6,
.page-node-16 .node #block-block-6,
.page-node-218 .node #block-block-6,
.page-node-378 .node #block-block-6,
.page-node-2674 .node #block-block-6,
.page-node-3852 .node #block-block-6 {
  margin-top: 40px;
}

.page-node-16,
#block-block-18,
#block-block-26 {
  background: #f3f3f3;
}

.page-node-16 .prices,
#block-block-18 .prices,
#block-block-26 .prices {
  padding-top: 50px;
}

.page-node-16 .prices::before,
#block-block-18 .prices::before,
#block-block-26 .prices::before {
  content: 'Pricing';
  display: block;
  font-size: 32px;
  text-align: center;
  margin-bottom: 60px;
}

.page-node-16.role-anonymous-user .prices::before,
#block-block-18.role-anonymous-user .prices::before,
#block-block-26.role-anonymous-user .prices::before {
  height: 50px;
  line-height: 50px;
  background: #999999;
  color: white;
}

.page-node-16.role-Active-Trial .plans-header, .page-node-16.role-Trial-Expired .plans-header, .page-node-16.role-Job-Poster .plans-header, .page-node-16.role-Pay-As-You-Go .plans-header, .page-node-16.role-Growing-Team .plans-header, .page-node-16.role-Big-Team .plans-header, .page-node-16.role-Job-Poster-0-Credits .plans-header,
#block-block-18.role-Active-Trial .plans-header,
#block-block-18.role-Trial-Expired .plans-header,
#block-block-18.role-Job-Poster .plans-header,
#block-block-18.role-Pay-As-You-Go .plans-header,
#block-block-18.role-Growing-Team .plans-header,
#block-block-18.role-Big-Team .plans-header,
#block-block-18.role-Job-Poster-0-Credits .plans-header,
#block-block-26.role-Active-Trial .plans-header,
#block-block-26.role-Trial-Expired .plans-header,
#block-block-26.role-Job-Poster .plans-header,
#block-block-26.role-Pay-As-You-Go .plans-header,
#block-block-26.role-Growing-Team .plans-header,
#block-block-26.role-Big-Team .plans-header,
#block-block-26.role-Job-Poster-0-Credits .plans-header {
  display: none;
}

.page-node-16 .features,
#block-block-18 .features,
#block-block-26 .features {
  text-align: center;
  margin-top: 30px;
}

.page-node-16 .features h2,
#block-block-18 .features h2,
#block-block-26 .features h2 {
  background: #cccccc;
  margin: 0;
  padding: 10px;
  color: black;
  font-weight: 400;
}

.page-node-16 .features ul,
#block-block-18 .features ul,
#block-block-26 .features ul {
  margin: 20px auto;
  width: 100%;
  padding: 0 20px 0 50px;
  list-style-position: inside;
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.page-node-16 .features ul h3,
#block-block-18 .features ul h3,
#block-block-26 .features ul h3 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  color: #e96803;
  margin-left: -10%;
}

@media all and (min-width: 850px) {
  .page-node-16 .features ul,
  #block-block-18 .features ul,
  #block-block-26 .features ul {
    max-width: 31%;
    margin: .5%;
    background: white;
    padding: 20px 20px 20px 50px;
    font-size: .875rem;
  }
  .page-node-16 .features ul:first-of-type,
  #block-block-18 .features ul:first-of-type,
  #block-block-26 .features ul:first-of-type {
    margin-left: 1%;
  }
  .page-node-16 .features ul:last-of-type,
  #block-block-18 .features ul:last-of-type,
  #block-block-26 .features ul:last-of-type {
    margin-right: 1%;
  }
}

@media all and (min-width: 1000px) {
  .page-node-16 .features ul,
  #block-block-18 .features ul,
  #block-block-26 .features ul {
    max-width: 30%;
    margin: 1%;
    font-size: 1rem;
  }
  .page-node-16 .features ul:first-of-type,
  #block-block-18 .features ul:first-of-type,
  #block-block-26 .features ul:first-of-type {
    margin-left: 2%;
  }
  .page-node-16 .features ul:last-of-type,
  #block-block-18 .features ul:last-of-type,
  #block-block-26 .features ul:last-of-type {
    margin-right: 2%;
  }
}

.page-node-16 .features li,
#block-block-18 .features li,
#block-block-26 .features li {
  margin: 20px 0;
  list-style: none;
}

.page-node-16 .features li::before,
#block-block-18 .features li::before,
#block-block-26 .features li::before {
  content: '\f00c';
  color: #e96803;
  padding-right: 10px;
  font-family: 'FontAwesome';
  margin-left: -27px;
}

.page-node-16.role-anonymous-user .prices {
  padding-top: 30px;
}

.page-node-16.role-anonymous-user .prices::before {
  background: none;
  color: black;
}

.intro-text {
  max-width: 700px;
  margin: auto;
}

.view-testimonial {
  color: white;
}

.view-testimonial .view-content {
  max-width: 900px;
  background: #fe9c20;
  margin: auto;
  width: 280px;
}

.view-testimonial .view-content::after {
  display: table;
  width: 100%;
  clear: both;
  content: '';
}

.view-testimonial .view-content img {
  height: 280px;
  width: 280px;
  display: inline-block;
  vertical-align: top;
}

.view-testimonial .view-content .credit {
  padding: 0 20px 20px;
  display: inline-block;
  font-size: .875rem;
  width: 280px;
  line-height: 1.1em;
}

.view-testimonial .view-content .credit span {
  display: block;
}

.view-testimonial .view-content .credit .organization {
  font-weight: 700;
}

.view-testimonial .view-content .quote {
  font-size: 1.2rem;
  width: 280px;
  font-weight: 300;
  line-height: 1.3em;
  color: white;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.view-testimonial .view-content .quote::before {
  content: '\f10d';
  padding-right: 5px;
  font-family: FontAwesome;
  font-size: 1.1rem;
}

.view-testimonial .view-content .quote::after {
  content: '\f10e';
  font-family: FontAwesome;
  font-size: 1.1rem;
  padding-left: 0;
}

@media all and (min-width: 480px) {
  .view-testimonial .view-content {
    width: 440px;
  }
  .view-testimonial .view-content img {
    width: 250px;
    height: 250px;
    float: left;
  }
  .view-testimonial .view-content .credit,
  .view-testimonial .view-content .quote {
    padding: 10px;
    width: 190px;
    float: left;
  }
  .view-testimonial .view-content .credit {
    padding-top: 0;
  }
  .view-testimonial .view-content .quote {
    font-size: 1.1rem;
    line-height: 1.2em;
  }
}

@media all and (min-width: 500px) {
  .view-testimonial .view-content {
    width: 460px;
  }
  .view-testimonial .view-content .credit,
  .view-testimonial .view-content .quote {
    padding: 15px 20px 10px;
    width: 210px;
  }
  .view-testimonial .view-content .credit {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media all and (min-width: 600px) {
  .view-testimonial .view-content {
    width: 540px;
  }
  .view-testimonial .view-content img {
    width: 270px;
    height: 270px;
    float: left;
  }
  .view-testimonial .view-content .credit,
  .view-testimonial .view-content .quote {
    padding: 20px;
    padding-bottom: 10px;
    width: 270px;
    float: left;
  }
  .view-testimonial .view-content .credit {
    padding-top: 0;
  }
  .view-testimonial .view-content .quote {
    font-size: 1.19rem;
    line-height: 1.5em;
  }
}

@media all and (min-width: 700px) {
  .view-testimonial .view-content {
    width: 660px;
  }
  .view-testimonial .view-content img {
    width: 300px;
    height: 300px;
  }
  .view-testimonial .view-content .credit,
  .view-testimonial .view-content .quote {
    width: 360px;
    padding: 30px;
    padding-bottom: 10px;
  }
  .view-testimonial .view-content .quote {
    font-size: 1.4rem;
    line-height: 1.4em;
  }
  .view-testimonial .view-content .credit {
    padding-top: 0;
  }
}

@media all and (min-width: 940px) {
  .view-testimonial .view-content {
    width: 900px;
  }
  .view-testimonial .view-content img {
    width: 300px;
    height: 300px;
  }
  .view-testimonial .view-content .credit,
  .view-testimonial .view-content .quote {
    width: 600px;
  }
  .view-testimonial .view-content .credit {
    padding: 0 50px;
    font-size: 1rem;
  }
  .view-testimonial .view-content .credit span {
    display: inline-block;
  }
  .view-testimonial .view-content .credit .jobtitle::before {
    content: '-';
    padding: 0 5px;
  }
  .view-testimonial .view-content .quote {
    padding: 60px 50px 30px;
    font-size: 1.8rem;
  }
  .view-testimonial .view-content .quote::before {
    font-size: 1.5rem;
    position: absolute;
    top: 50px;
    padding-right: 0;
    left: 20px;
  }
  .view-testimonial .view-content .quote::after {
    font-size: 1.5rem;
    padding-left: 10px;
  }
}

.not-logged-in .authenticated,
.logged-in .anonymous {
  display: none !important;
}

.region-bottom {
  margin-top: 50px;
}

#block-block-7 {
  background: #2dbfcd;
  padding: 10px 10px 50px;
  font-size: .875rem;
  color: white;
}

#block-block-7::after {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

#block-block-7 a,
#block-block-7 a:link,
#block-block-7 a:visited,
#block-block-7 a:hover {
  color: white;
}

#block-block-7 a:hover {
  text-decoration: underline;
}

#block-block-7 .footer-menu {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

#block-block-7 .footer-menu a,
#block-block-7 .footer-menu a:link,
#block-block-7 .footer-menu a:visited,
#block-block-7 .footer-menu a:hover {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  margin: 2px 1px;
  background: white;
  color: #2dbfcd;
  text-decoration: none;
}

#block-block-7 .logo {
  background-image: url("../images/logo-white.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 45px;
  display: block;
  margin: 0 auto 10px;
}

#block-block-7 .footer-text {
  text-align: center;
  line-height: 1.4em;
}

#block-block-7 .footer-text span {
  display: block;
}

#block-block-7 .socialmedia {
  text-align: center;
  margin-bottom: 10px;
}

#block-block-7 .socialmedia .social,
#block-block-7 .socialmedia .social:link,
#block-block-7 .socialmedia .social:visited,
#block-block-7 .socialmedia .social:hover {
  text-decoration: none;
  color: #2dbfcd;
}

#block-block-7 .socialmedia .social::before,
#block-block-7 .socialmedia .social:link::before,
#block-block-7 .socialmedia .social:visited::before,
#block-block-7 .socialmedia .social:hover::before {
  color: #2dbfcd;
}

#block-block-7 .socialmedia .facebook,
#block-block-7 .socialmedia .twitter,
#block-block-7 .socialmedia .linkedin,
#block-block-7 .socialmedia .youtube {
  background: white;
}

@media all and (min-width: 400px) {
  #block-block-7 .footer-menu {
    clear: both;
  }
  #block-block-7 .footer-menu a,
  #block-block-7 .footer-menu a:link,
  #block-block-7 .footer-menu a:visited,
  #block-block-7 .footer-menu a:hover {
    background: none;
    color: white;
  }
  #block-block-7 .footer-menu a:hover {
    text-decoration: underline;
  }
  #block-block-7 .socialmedia {
    float: right;
  }
  #block-block-7 .logo {
    margin: 20px 0;
  }
  #block-block-7 .footer-text {
    clear: both;
  }
  #block-block-7 .footer-text span {
    display: inline-block;
  }
  #block-block-7 .footer-text span.footer-text-1 {
    display: block;
  }
  #block-block-7 .footer-text .address-2::before {
    content: ',';
    padding-right: 5px;
  }
  #block-block-7 .footer-text .address-2::after {
    content: '-';
    padding: 0 5px;
  }
}

@media all and (min-width: 768px) {
  #block-block-7 {
    padding: 20px 20px 80px;
  }
  #block-block-7 .footer-menu {
    float: right;
    width: auto;
  }
  #block-block-7 .socialmedia {
    float: right;
    clear: right;
  }
  #block-block-7 .footer-text {
    text-align: left;
    float: left;
    clear: none;
  }
  #block-block-7 .logo {
    margin-top: 10px;
  }
}

#block-block-7 .facebook,
#block-block-7 .twitter,
#block-block-7 .linkedin,
#block-block-7 .youtube {
  background: white;
}

#block-block-7 .facebook:link::before, #block-block-7 .facebook:visited::before, #block-block-7 .facebook:hover::before,
#block-block-7 .twitter:link::before,
#block-block-7 .twitter:visited::before,
#block-block-7 .twitter:hover::before,
#block-block-7 .linkedin:link::before,
#block-block-7 .linkedin:visited::before,
#block-block-7 .linkedin:hover::before,
#block-block-7 .youtube:link::before,
#block-block-7 .youtube:visited::before,
#block-block-7 .youtube:hover::before {
  background: white;
}

.not-logged-in #block-block-7,
.role-Job_Seeker #block-block-7 {
  padding-bottom: 30px;
}

.section-user #block-block-7 {
  padding-bottom: 120px;
}

.employer-header,
.plans-header,
.affiliate-header {
  text-align: center;
  padding: 200px 20px 50px;
  background-image: url("../images/business-people-meeting-office.jpg");
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-size: 1.1rem;
}

.employer-header h2,
.plans-header h2,
.affiliate-header h2 {
  font-size: 1.8rem;
  margin: 0 auto 1rem;
  line-height: 1.3em;
  max-width: 860px;
}

.employer-header h2 span:nth-of-type(1),
.plans-header h2 span:nth-of-type(1),
.affiliate-header h2 span:nth-of-type(1) {
  padding-right: 10px;
}

@media all and (min-width: 580px) {
  .employer-header h2 span,
  .plans-header h2 span,
  .affiliate-header h2 span {
    display: block;
    padding-right: 0;
  }
}

@media all and (min-width: 480px) {
  .employer-header,
  .plans-header,
  .affiliate-header {
    background-size: cover;
  }
  .employer-header h2,
  .plans-header h2,
  .affiliate-header h2 {
    font-size: 2.3rem;
  }
}

@media all and (min-width: 1024px) {
  .employer-header,
  .plans-header,
  .affiliate-header {
    font-size: 1.2rem;
  }
  .employer-header .button,
  .plans-header .button,
  .affiliate-header .button {
    padding: 10px 25px;
  }
  .employer-header h2,
  .plans-header h2,
  .affiliate-header h2 {
    font-size: 3rem;
  }
}

.plans-header {
  background-image: url("../images/trial-photo.jpg");
}

.affiliate-header {
  background-image: url("../images/partnerships.jpg");
}

.employer-header h2 span,
.plans-header h2 span,
.features h2 span {
  display: block;
  padding-right: 0;
}

@media all and (min-width: 1024px) {
  .employer-header,
  .plans-header,
  .features {
    background-position: center -200px;
  }
  .employer-header h2 span,
  .plans-header h2 span,
  .features h2 span {
    display: inline-block;
  }
  .employer-header h2 span:nth-of-type(1),
  .plans-header h2 span:nth-of-type(1),
  .features h2 span:nth-of-type(1) {
    padding-right: 10px;
  }
}

.plans-header {
  background-position: center 0px;
}

@media all and (min-width: 1024px) {
  .plans-header {
    background-position: center -100px;
  }
}

.centered {
  text-align: center;
}

#edit-field-don-t-fill-this-out-if-you,
.field-name-field-don-t-fill-this-out-if-you {
  display: none;
}

.prices {
  padding: 70px 0 0;
  text-align: center;
}

.prices .price-option,
.prices .price-option:link,
.prices .price-option:visited,
.prices .price-option:hover {
  position: relative;
  color: black;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  vertical-align: top;
}

.prices .price-option .price-container,
.prices .price-option:link .price-container,
.prices .price-option:visited .price-container,
.prices .price-option:hover .price-container {
  border: 1px solid #cccccc;
  background: white;
  margin-bottom: 5px;
  padding: 10px;
}

.prices .price-option sup,
.prices .price-option sub,
.prices .price-option:link sup,
.prices .price-option:link sub,
.prices .price-option:visited sup,
.prices .price-option:visited sub,
.prices .price-option:hover sup,
.prices .price-option:hover sub {
  font-size: .875rem;
}

.prices .price-option .price-buy,
.prices .price-option:link .price-buy,
.prices .price-option:visited .price-buy,
.prices .price-option:hover .price-buy {
  margin: 20px auto 0;
}

@media all and (min-width: 680px) {
  .prices .price-option,
  .prices .price-option:link,
  .prices .price-option:visited,
  .prices .price-option:hover {
    margin: 15px;
  }
  .prices .price-option .price-container,
  .prices .price-option:link .price-container,
  .prices .price-option:visited .price-container,
  .prices .price-option:hover .price-container {
    padding: 20px;
  }
}

.prices .price-option .price,
.prices .price-option:link .price,
.prices .price-option:visited .price,
.prices .price-option:hover .price {
  font-size: 2.5rem;
  display: block;
  font-weight: 300;
  padding: 30px 0;
}

.prices .price-option .price-name,
.prices .price-option:link .price-name,
.prices .price-option:visited .price-name,
.prices .price-option:hover .price-name {
  font-size: 1.5rem;
  line-height: 1.5em;
  font-weight: 300;
  display: block;
}

.prices .price-option .discount,
.prices .price-option:link .discount,
.prices .price-option:visited .discount,
.prices .price-option:hover .discount {
  color: #999999;
  font-size: .875em;
  display: block;
}

.prices .price-option .description,
.prices .price-option:link .description,
.prices .price-option:visited .description,
.prices .price-option:hover .description {
  display: block;
  color: #999999;
  margin: 0 0 10px;
}

.prices .price-option:hover .price-container {
  border-color: #2dbfcd;
}

.prices .price-option:hover .button {
  background-color: #2dbfcd;
}

.prices .price-1::before,
.prices .price-1:link::before,
.prices .price-1:visited::before,
.prices .price-1:hover::before {
  content: 'Most Popular';
  text-transform: uppercase;
  background: #2dbfcd;
  color: white;
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: block;
  position: absolute;
  top: -30px;
}

.prices .price-1 .price-container,
.prices .price-1:link .price-container,
.prices .price-1:visited .price-container,
.prices .price-1:hover .price-container {
  border-color: #2dbfcd;
}

#crm-container.crm-public p,
#crm-container.crm-public h1,
#crm-container.crm-public h2,
#crm-container.crm-public h3,
#crm-container.crm-public h4,
#crm-container.crm-public a {
  font-family: inherit;
}

#crm-container.crm-public .help,
#crm-container.crm-public #help {
  font-size: 1rem;
  color: black;
  line-height: 1.5rem;
}

#crm-container.crm-public h3 {
  font-size: 1.5rem;
  background: none;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

#crm-container.crm-public .crm-not-you-message {
  display: none;
}

#crm-container.crm-public form {
  padding: 0;
}

#crm-container.crm-public form select {
  height: auto;
  line-height: inherit;
}

#crm-container.crm-public form .price_set-section .one_job_listing-section {
  text-align: center;
  margin-bottom: 0;
}

#crm-container.crm-public form .price_set-section .one_job_listing-section .clear {
  margin-bottom: 10px;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group fieldset {
  padding: 5px 25px;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group fieldset::before {
  content: 'Your Order';
  font-family: inherit;
  font-size: 1rem;
  color: white;
  background-color: #fe9c20;
  padding: 5px 15px;
  font-weight: normal;
  position: relative;
  top: -18px;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section {
  margin: 0;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .membership_amount-content label {
  display: block;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .membership_amount-content label::after {
  content: '3 job listings - $199/month';
  font-size: 1rem;
  visibility: visible;
  color: white;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .membership_amount-content label .crm-price-amount-label,
#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .membership_amount-content label .crm-price-amount-amount,
#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .membership_amount-content label .crm-price-amount-label-separator {
  display: none;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .label {
  display: none;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section .auto-renew {
  margin: 0;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section #force_renew {
  font-size: 0;
  visibility: hidden;
}

#crm-container.crm-public .crm-contribution-main-form-block .membership-group .membership_amount-section #force_renew::before {
  text-align: center;
  display: block;
  content: 'This plan will renew automatically every month. You can cancel at any time.';
  visibility: visible;
  color: #999999;
  font-size: .875rem;
}

#crm-container.crm-public .crm-contribution-main-form-block .email-5-section {
  display: none;
}

#crm-container.crm-public .crm-contribution-main-form-block #priceset-div {
  margin-bottom: 25px;
}

#crm-container.crm-public .crm-contribution-main-form-block.crm-contribution-page-id-3 .membership-group fieldset {
  padding-bottom: 10px;
}

#crm-container.crm-public .crm-contribution-main-form-block.crm-contribution-page-id-3 .membership-group .membership_amount-section .membership_amount-content .membership_amount-row1 label::after {
  content: 'Unlimited job listings - $499/month';
}

#crm-container.crm-public .crm-contribution-main-form-block.crm-contribution-page-id-3 .membership-group .membership_amount-section .membership_amount-content .membership_amount-row2 label::after {
  content: 'Unlimited job listings - $4,999/year';
}

#crm-container.crm-public .crm-contribution-thankyou-form-block.crm-contribution-page-id-2 > .header-dark,
#crm-container.crm-public .crm-contribution-thankyou-form-block.crm-contribution-page-id-2 #membership-listings,
#crm-container.crm-public .crm-contribution-thankyou-form-block.crm-contribution-page-id-2 .amount_display-group .crm-auto-renew-cancel-info,
#crm-container.crm-public .crm-contribution-thankyou-form-block.crm-contribution-page-id-2 .amount_display-group strong:nth-of-type(3) {
  display: none;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .help {
  display: none;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .crm-group .header-dark {
  font-size: 1.5rem;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .contributor_email-group {
  margin-top: 0;
  padding-top: 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .contributor_email-group .header-dark {
  display: none;
}

.morelistings {
  cursor: pointer;
}

#block-block-9 {
  text-align: center;
  font-size: .75rem;
  display: table;
  width: 100%;
  max-width: 600px;
  margin: 30px auto 0;
}

@media all and (min-width: 480px) {
  #block-block-9 {
    font-size: .875rem;
  }
}

#block-block-9 span {
  height: 40px;
  display: table-cell;
  min-width: 80px;
  padding: 5px;
  line-height: 1.3em;
  color: #999999;
  border-top: 3px solid #cccccc;
  margin: 1px;
  vertical-align: top;
}

@media all and (min-width: 480px) {
  #block-block-9 span {
    padding: 5px 15px;
  }
}

#block-block-9 span.step1::before {
  content: '1. Register';
}

#block-block-9 span.step2::before {
  content: '2. Create an employer profile';
}

#block-block-9 span.step3::before {
  content: '3. Start posting jobs';
}

#block-block-9 span.step4 {
  display: none;
}

.page-user-register-trial #block-block-9 span.step1,
.page-user-register-plan #block-block-9 span.step1,
.page-user-login-purchase #block-block-9 span.step1,
.page-user-register-purchase #block-block-9 span.step1 {
  border-color: #fe9c20;
}

.page-user-login-purchase .hybridauth-widget-wrapper {
  display: none;
}

.page-user-login-purchase #user-login {
  padding-top: 10px;
}

.page-user-login-purchase #block-block-9 span.step1::before {
  content: '1. Sign In';
}

.page-user-register-purchase #block-block-9 span.step1::before {
  content: '1. Register';
}

.page-node-add-organization-trial #block-block-9 span.step1,
.page-node-add-organization-trial #block-block-9 span.step2,
.page-civicrm-contribute #block-block-9 span.step1,
.page-civicrm-contribute #block-block-9 span.step2,
.page-node-16 #block-block-9 span.step1,
.page-node-16 #block-block-9 span.step2 {
  border-color: #fe9c20;
}

.page-node-add-job-posting-trial #block-block-9 span.step1,
.page-node-add-job-posting-trial #block-block-9 span.step2,
.page-node-add-job-posting-trial #block-block-9 span.step3,
.page-node-add-job-posting-plan #block-block-9 span.step1,
.page-node-add-job-posting-plan #block-block-9 span.step2,
.page-node-add-job-posting-plan #block-block-9 span.step3,
.civicrm-thank-you-page #block-block-9 span.step1,
.civicrm-thank-you-page #block-block-9 span.step2,
.civicrm-thank-you-page #block-block-9 span.step3 {
  border-color: #fe9c20;
}

.page-civicrm-contribute #block-block-9 span.step2::before,
.page-node-add-job-posting-plan #block-block-9 span.step2::before,
.page-user-register-plan #block-block-9 span.step2::before,
.page-user-login-purchase #block-block-9 span.step2::before,
.page-user-register-purchase #block-block-9 span.step2::before,
.page-node-16 #block-block-9 span.step2::before {
  content: '2. Purchase';
}

.page-node-add-job-posting-plan #block-block-9 span.step3::before {
  content: '3. Post Jobs';
}

.page-user-register-trial .edit-field-linkedin {
  display: none;
}

#block-block-10,
#block-block-20 {
  position: fixed;
  right: 3px;
  bottom: 3px;
}

#block-block-10 a,
#block-block-10 a:link,
#block-block-10 a:visited,
#block-block-10 a:hover,
#block-block-20 a,
#block-block-20 a:link,
#block-block-20 a:visited,
#block-block-20 a:hover {
  padding: 10px 15px 10px 35px;
  border: 1px solid white;
  font-size: 1.2rem;
  background: #e96803;
  position: relative;
  margin: 0;
}

#block-block-10 a::before,
#block-block-10 a:link::before,
#block-block-10 a:visited::before,
#block-block-10 a:hover::before,
#block-block-20 a::before,
#block-block-20 a:link::before,
#block-block-20 a:visited::before,
#block-block-20 a:hover::before {
  content: '+';
  font-weight: 300;
  font-size: 1.8rem;
  position: absolute;
  top: 10px;
  left: 10px;
}

#block-block-10 a:hover,
#block-block-20 a:hover {
  background: #2dbfcd;
}

#block-block-10 .button,
#block-block-20 .button {
  margin: 0;
}

.not-logged-in #block-block-20 a,
.not-logged-in #block-block-20 a:link,
.not-logged-in #block-block-20 a:visited,
.not-logged-in #block-block-20 a:hover {
  padding: 10px 15px;
}

.not-logged-in #block-block-20 a::before,
.not-logged-in #block-block-20 a:link::before,
.not-logged-in #block-block-20 a:visited::before,
.not-logged-in #block-block-20 a:hover::before {
  display: none;
}

#block-views-expired-listing-block {
  background: #f3f3f3;
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;
}

#block-views-expired-listing-block .attachment .view {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 10px;
}

#block-views-expired-listing-block .attachment .view h3 {
  margin-bottom: 5px;
}

#block-views-expired-listing-block .attachment .view .views-row {
  padding: 10px;
  background: white;
  margin: 5px;
}

.page-taxonomy-term .taxonomy-term-description {
  max-width: 740px;
  margin: 0 auto 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.page-taxonomy-term .taxonomy-term-description p {
  margin: 0 0 20px;
}

.page-taxonomy-term main {
  text-align: center;
}

.page-taxonomy-term main .block-views {
  border: 0;
  display: inline-block;
  background: #f3f3f3;
  float: none;
  width: 100%;
  max-width: 100%;
  vertical-align: top;
  padding: 0 10px 20px;
  margin-top: 40px;
}

@media all and (min-width: 1180px) {
  .page-taxonomy-term main .block-views {
    max-width: 390px;
    margin-top: 0;
  }
}

.page-taxonomy-term main .block-views .view,
.page-taxonomy-term main .block-views .view .view-content {
  max-width: 100%;
  width: 100%;
}

.page-taxonomy-term main .block-views .block__title {
  margin: 20px 0 0;
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

@media all and (min-width: 1180px) {
  .page-taxonomy-term main .block-views .block__title {
    margin-top: 0;
  }
}

.page-taxonomy-term main .block-views .view-content {
  padding: 0;
  text-align: center;
}

.page-taxonomy-term main .block-views .view-content .views-row {
  display: inline-block;
  max-width: 100%;
  width: auto;
  vertical-align: top;
  text-align: left;
}

@media all and (min-width: 1180px) {
  .page-taxonomy-term main .block-views .view-content .views-row {
    max-width: 100%;
    display: block;
  }
}

.page-taxonomy-term main .block-views .view-content .views-row a,
.page-taxonomy-term main .block-views .view-content .views-row a:link,
.page-taxonomy-term main .block-views .view-content .views-row a:visited,
.page-taxonomy-term main .block-views .view-content .views-row a:hover {
  display: block;
  margin: 3px;
  color: black;
  border: 1px solid #cccccc;
  padding: 5px 15px;
  float: left;
}

@media all and (min-width: 1180px) {
  .page-taxonomy-term main .block-views .view-content .views-row a,
  .page-taxonomy-term main .block-views .view-content .views-row a:link,
  .page-taxonomy-term main .block-views .view-content .views-row a:visited,
  .page-taxonomy-term main .block-views .view-content .views-row a:hover {
    border-bottom: 0;
    margin: 0;
    float: none;
  }
}

.page-taxonomy-term main .block-views .view-content .views-row a:hover {
  background: #2dbfcd;
  color: white;
}

.page-taxonomy-term main .block-views .view-content .views-row:last-of-type a,
.page-taxonomy-term main .block-views .view-content .views-row:last-of-type a:link,
.page-taxonomy-term main .block-views .view-content .views-row:last-of-type a:visited,
.page-taxonomy-term main .block-views .view-content .views-row:last-of-type a:hover {
  border-bottom: 1px solid #cccccc;
}

.page-taxonomy-term main .view {
  width: auto;
  display: inline-block;
  vertical-align: top;
}

@media all and (min-width: 768px) {
  .page-taxonomy-term main .view {
    width: 718px;
  }
}

@media all and (min-width: 850px) {
  .page-taxonomy-term main .view {
    width: 806px;
  }
}

.page-taxonomy-term main .view .job,
.page-taxonomy-term main .view .job:link,
.page-taxonomy-term main .view .job:visited,
.page-taxonomy-term main .view .job:hover {
  border: 1px solid #f3f3f3;
  border-bottom: 0;
  text-align: left;
}

.page-taxonomy-term main .view .views-row:last-of-type .job {
  border-bottom: 1px solid #f3f3f3;
}

.page-taxonomy-term main .view .view-content,
.page-taxonomy-term main .view .attachment {
  border: 0;
  display: inline-block;
  float: none;
  width: 100%;
  max-width: 400px;
  margin: auto;
  vertical-align: top;
  background: none;
  padding: 0 10px;
}

@media all and (min-width: 768px) {
  .page-taxonomy-term main .view .view-content,
  .page-taxonomy-term main .view .attachment {
    max-width: 356px;
    margin: 0;
  }
}

@media all and (min-width: 850px) {
  .page-taxonomy-term main .view .view-content,
  .page-taxonomy-term main .view .attachment {
    max-width: 400px;
  }
}

.page-taxonomy-term main .view .view-header {
  max-width: 400px;
  margin: auto;
}

@media all and (min-width: 768px) {
  .page-taxonomy-term main .view .view-header {
    max-width: 356px;
    margin: 0;
  }
}

@media all and (min-width: 850px) {
  .page-taxonomy-term main .view .view-header {
    max-width: 400px;
  }
}

.page-taxonomy-term main .view .view-header h3 {
  margin: 0;
}

.page-taxonomy-term main .view .attachment {
  padding: 0;
  margin-top: 40px;
}

@media all and (min-width: 768px) {
  .page-taxonomy-term main .view .attachment {
    margin-top: -50px;
  }
}

.page-taxonomy-term main .view .attachment .view {
  width: 100%;
}

.page-taxonomy-term main .view .attachment .view-content .view-content {
  padding: 0;
}

.page-taxonomy-term main .view .attachment .job .logo {
  margin-bottom: 0;
}

.page-taxonomy-term main .view .attachment .job::after {
  content: '';
  width: 100%;
  clear: both;
  display: table;
}

.page-taxonomy-term main h1,
.page-taxonomy-term main .feed-icon {
  display: none;
}

.page-taxonomy-term .region-hero {
  margin: 0;
}

.page-taxonomy-term .region-hero .block {
  position: relative;
  overflow: hidden;
}

.page-taxonomy-term .region-hero .block img {
  filter: brightness(70%);
  margin-left: -40%;
  margin-right: -40%;
  width: 180%;
  max-width: 180%;
}

.page-taxonomy-term .region-hero .block h1 {
  text-align: center;
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 1;
  margin-top: -16px;
  color: white;
}

@media all and (min-width: 768px) {
  .page-taxonomy-term .region-hero .block img {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  .page-taxonomy-term .region-hero .block h1 {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
  }
}

.section-job-category main h1,
.section-job-function main h1 {
  display: block;
  margin-bottom: 30px;
}

.view-company-info-for-job {
  margin-top: 40px;
}

.view-company-info-for-job h2.title {
  text-align: center;
  border-top: 3px solid #f3f3f3;
}

.view-company-info-for-job .node header,
.view-company-info-for-job .node .node-readmore {
  display: none;
}

.view-company-info-for-job .node fieldset .field .field-label {
  font-size: 1rem;
  color: black;
  margin-top: 0;
}

.view-company-info-for-job .node .field-name-field-area-of-focus .field-item {
  display: inline-block;
  margin: 3px;
}

.view-company-info-for-job .node .group-overview .field {
  display: inline-block;
  max-width: 320px;
  width: 100%;
  margin: 10px 20px 10px 0;
  vertical-align: top;
}

.view-company-info-for-job .node .group-overview .field .field-label {
  display: inline;
}

.view-company-info-for-job .node .group-overview .field .field-label::after {
  content: ':';
  margin-left: -3px;
}

.view-company-info-for-job .node .group-overview .field .field-items {
  display: inline;
  padding-left: 10px;
}

.view-company-info-for-job .node .group-overview .field .field-items .field-item {
  display: inline;
}

.view-company-info-for-job .node .group-overview .field .field-items .field-item::before {
  display: none;
}

.view-company-info-for-job .node .group-video .field-name-field-video a,
.view-company-info-for-job .node .group-video .field-name-field-video a:link,
.view-company-info-for-job .node .group-video .field-name-field-video a:visited a:hover {
  display: block;
  position: relative;
  overflow: hidden;
}

.view-company-info-for-job .node .group-video .field-name-field-video a img,
.view-company-info-for-job .node .group-video .field-name-field-video a:link img,
.view-company-info-for-job .node .group-video .field-name-field-video a:visited a:hover img {
  margin-top: -6.6%;
  margin-bottom: -6.6%;
  width: 100%;
}

.view-company-info-for-job .node .group-video .field-name-field-video a::before,
.view-company-info-for-job .node .group-video .field-name-field-video a:link::before,
.view-company-info-for-job .node .group-video .field-name-field-video a:visited a:hover::before {
  content: '\f04b';
  border: 1px solid white;
  border-radius: 50%;
  font-family: FontAwesome;
  font-size: 30px;
  padding-left: 5px;
  position: absolute;
  width: 45px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  color: white;
  opacity: .7;
}

.view-company-info-for-job .node .group-video .field-name-field-video a:hover::before {
  color: #c4302b;
  opacity: 1;
  border-color: #c4302b;
}

.view-user-profile.view-display-id-page_1 {
  margin-bottom: 10px;
}

.view-user-profile.view-display-id-page_1::after {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

.view-user-profile.view-display-id-page_1 > .view-content {
  background: #f3f3f3;
  padding: 20px;
  text-align: center;
  max-width: 650px;
  margin: auto;
}

.view-user-profile.view-display-id-page_1 > .view-content::after {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

.view-user-profile.view-display-id-page_1 > .view-content .name {
  margin-bottom: 10px;
}

.view-user-profile.view-display-id-page_1 > .view-content .name h3 {
  margin: 0;
}

.view-user-profile.view-display-id-page_1 > .view-content span {
  padding-right: 10px;
  display: inline-block;
  margin: 3px 0;
  margin-right: 5px;
  border-right: 1px solid #cccccc;
}

.view-user-profile.view-display-id-page_1 > .view-content span:last-of-type {
  border: 0;
}

.views-table {
  width: 100%;
}

.views-table thead {
  background: #cccccc;
}

.views-table thead th {
  text-align: left;
  border: 1px solid white;
  padding: 5px 10px;
}

.views-table thead th a,
.views-table thead th a:link,
.views-table thead th a:visited a:hover {
  color: black;
}

.views-table thead th a img,
.views-table thead th a:link img,
.views-table thead th a:visited a:hover img {
  display: inline;
  filter: brightness(0%);
}

.views-table thead th a:hover {
  color: #2ab8c6;
}

.views-table tbody td {
  padding: 5px;
  text-align: left;
}

.views-table tbody tr.even {
  background: #f3f3f3;
}

.view-user-widgets .views-exposed-form .views-exposed-widgets {
  text-align: center;
}

.view-user-widgets .views-exposed-form .views-exposed-widget {
  padding-right: 5px;
  float: none;
  text-align: left;
  display: inline-block;
}

.view-user-widgets .views-exposed-form .views-exposed-widget #edit-nid {
  width: 100px;
}

.view-user-widgets .views-exposed-form .views-exposed-widget input[type='text'] {
  height: 30px;
  padding-left: 5px;
}

.view-user-widgets .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0;
}

.view-user-widgets .views-exposed-form .views-reset-button,
.view-user-widgets .views-exposed-form .views-submit-button {
  padding-top: .35em;
  padding-right: 5px;
}

.view-user-widgets .views-exposed-form .views-reset-button a {
  background: #f3f3f3;
  color: black;
}

.view-user-widgets .view-empty {
  background: #ffe79d;
  padding: 20px;
  max-width: 650px;
  margin: 20px auto;
}

.view-user-widgets.jobs-posted .view-footer {
  text-align: left;
  font-size: .875rem;
  color: #999999;
}

.view-user-widgets.jobs-posted .howapply-2 {
  font-size: 0;
  visibility: hidden;
}

.view-user-widgets.jobs-posted .howapply-2::after {
  content: 'N/A *';
  font-size: 1rem;
  visibility: visible;
}

.view-user-widgets.jobs-posted .applications {
  cursor: pointer;
}

.view-user-widgets.jobs-posted .views-field-created-1 .month::after,
.view-user-widgets.jobs-posted .views-field-created-1 .months::after,
.view-user-widgets.jobs-posted .views-field-created-1 .year::after,
.view-user-widgets.jobs-posted .views-field-created-1 .years::after,
.view-user-widgets.jobs-posted .views-field-created-1 .Inactive::after {
  content: 'Inactive';
  color: #999999;
}

.view-user-widgets.jobs-posted .views-field-created-1 .month.Active::after,
.view-user-widgets.jobs-posted .views-field-created-1 .months.Active::after,
.view-user-widgets.jobs-posted .views-field-created-1 .year.Active::after,
.view-user-widgets.jobs-posted .views-field-created-1 .years.Active::after,
.view-user-widgets.jobs-posted .views-field-created-1 .Inactive.Active::after {
  content: 'Inactive';
  color: #999999;
}

.view-user-widgets.jobs-posted .views-field-created-1 .week::after,
.view-user-widgets.jobs-posted .views-field-created-1 .weeks::after,
.view-user-widgets.jobs-posted .views-field-created-1 .day::after,
.view-user-widgets.jobs-posted .views-field-created-1 .days::after,
.view-user-widgets.jobs-posted .views-field-created-1 .second::after,
.view-user-widgets.jobs-posted .views-field-created-1 .seconds::after,
.view-user-widgets.jobs-posted .views-field-created-1 .minute::after,
.view-user-widgets.jobs-posted .views-field-created-1 .minutes .hour::after,
.view-user-widgets.jobs-posted .views-field-created-1 .hours::after,
.view-user-widgets.jobs-posted .views-field-created-1 .Active::after {
  content: 'Active';
}

.view-user-widgets.jobs-posted .views-field-created-1 .week.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .weeks.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .day.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .days.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .second.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .seconds.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .minute.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .minutes .hour.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .hours.Inactive::after,
.view-user-widgets.jobs-posted .views-field-created-1 .Active.Inactive::after {
  content: 'Inactive';
  color: #999999;
}

.role-Employer.page-user #block-views-user-widgets-block,
.role-Employer.page-user #block-views-user-widgets-block-1 {
  display: none;
}

.role-Job-Seeker.page-user #block-views-user-widgets-block-2,
.role-Job-Seeker.page-user #block-views-user-widgets-block-3 {
  display: none;
}

div.autocomplete-deluxe-multiple {
  width: 100%;
  max-width: 500px;
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='telephone'],
input[type='file'],
select,
textarea {
  width: 100%;
  max-width: 100%;
}

@media all and (min-width: 768px) {
  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='telephone'],
  input[type='file'],
  select,
  textarea {
    width: auto;
  }
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='telephone'],
input[type='password'] {
  -webkit-appearance: none;
  border: 0;
  border: 1px solid #ddd;
}

input.form-submit {
  width: auto;
}

.form-field-type-image {
  overflow: hidden;
}

.page-node-3852 h2.block__title {
  text-align: center;
}

.page-node-3852 .node-3852 .inner-block-6 {
  border-bottom: 10px solid #f1f1f1;
}

@media all and (min-width: 900px) {
  .page-node-3852 .node-3852 .careerpageimg {
    max-width: 40%;
  }
  .page-node-3852 .node-3852 .block-6-text {
    max-width: 55%;
  }
}

#block-search-api-saved-searches-jobs {
  display: none;
}

#search-api-saved-searches-save-form-wrapper {
  padding: 40px 20px 10px;
  text-align: center;
}

#search-api-saved-searches-save-form-wrapper::before {
  content: 'Get notifications about new job listings delivered to your inbox.';
  color: #e96803;
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 1.2em;
}

#search-api-saved-searches-save-form-wrapper #search-api-saved-searches-save-form .form-item-name label::before {
  content: 'Job Alert ';
}

#search-api-saved-searches-save-form-wrapper input[type='text'] {
  width: 100%;
}

#search-api-saved-searches-search-edit-form {
  margin: auto;
  max-width: 600px;
  padding: 20px;
  background: #f3f3f3;
}

.page-nonprofit-jobs-search .region-highlighted,
.page-nonprofit-jobs-search .region-highlighted.closed,
.page-nonprofit-jobs.page-views .region-highlighted,
.page-nonprofit-jobs.page-views .region-highlighted.closed {
  display: none;
}

.page-nonprofit-jobs-search .region-highlighted.open,
.page-nonprofit-jobs.page-views .region-highlighted.open {
  height: auto;
  display: block;
}

#block-views-nonprofit-jobs-index-block-1,
#block-views-localized-search-block-1 {
  display: none;
  width: calc( 100% - 320px);
  padding: 30px;
}

#block-views-nonprofit-jobs-index-block-1 .view-content,
#block-views-localized-search-block-1 .view-content {
  background: none;
  width: 100%;
  float: none;
  border: 0;
}

#block-views-nonprofit-jobs-index-block-1 .view-filters,
#block-views-nonprofit-jobs-index-block-1 .block__title,
#block-views-localized-search-block-1 .view-filters,
#block-views-localized-search-block-1 .block__title {
  display: none;
}

@media all and (min-width: 768px) {
  #block-views-nonprofit-jobs-index-block-1,
  #block-views-localized-search-block-1 {
    background: #f1f1f1;
    float: right;
    display: block;
  }
  #block-views-nonprofit-jobs-index-block-1 .view-nonprofit-jobs-index,
  #block-views-nonprofit-jobs-index-block-1 .view-localized-search,
  #block-views-localized-search-block-1 .view-nonprofit-jobs-index,
  #block-views-localized-search-block-1 .view-localized-search {
    padding: 20px 10px 30px;
    background: white;
    border: 1px solid #cccccc;
  }
  #block-views-nonprofit-jobs-index-block-1 header,
  #block-views-localized-search-block-1 header {
    margin: 0 0 0 110px;
  }
  #block-views-nonprofit-jobs-index-block-1 header h2,
  #block-views-localized-search-block-1 header h2 {
    margin: 0;
  }
  #block-views-nonprofit-jobs-index-block-1 header h2 a,
  #block-views-localized-search-block-1 header h2 a {
    color: black;
  }
  #block-views-nonprofit-jobs-index-block-1 .view-org-logo-for-job-listing,
  #block-views-localized-search-block-1 .view-org-logo-for-job-listing {
    margin-top: -30px;
  }
}

@media all and (min-width: 1024px) {
  #block-views-nonprofit-jobs-index-block-1,
  #block-views-localized-search-block-1 {
    width: calc( 100% - 400px);
  }
}

@media all and (min-width: 768px) {
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index,
  .page-nonprofit-jobs.page-views .local-jobs {
    position: relative;
  }
  .page-nonprofit-jobs-search .view-nonprofit-jobs-index .item-list,
  .page-nonprofit-jobs.page-views .local-jobs .item-list {
    position: absolute;
    top: 10px;
  }
}

.node-job-posting .field-name-field-location,
.node-job-posting .view-joblocation-address {
  display: none;
}

.node-job-posting .field-name-field-job-category {
  margin-top: 2rem;
}

.node-job-posting .field-name-field-job-category .field-label,
.node-job-posting .field-name-field-job-category .field-items {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  color: black;
  vertical-align: middle;
  display: inline-block;
}

.node-job-posting .field-name-field-job-category .field-items .field-item {
  display: inline-block;
}

.node-job-posting .field-name-field-job-category .field-label {
  padding-top: .node-job-posting .field-name-field-job-category .field-label;
}

.node-job-posting .field-name-field-job-category .field-label ::after {
  content: ':';
  padding-right: 5px;
}

.node-job-posting .listing-stats,
.field-name-field-industry,
.field-name-field-hiring-organization {
  display: none;
}

.user-is-author #block-views-expired-listing-block {
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  min-height: 50px;
  height: auto;
  max-width: 740px;
  margin: auto;
}

.user-is-author #block-views-expired-listing-block .block__title,
.user-is-author #block-views-expired-listing-block .view,
.user-is-author #block-views-expired-listing-block .attachment {
  display: none;
}

.user-is-author #block-views-expired-listing-block::before {
  content: 'This job is inactive.';
  font-weight: 700;
  visibility: visible;
  font-size: 1.5rem;
  line-height: 1.5em;
  text-align: center;
  display: block;
  background-color: #fff0f0;
  padding: 20px 20px 0;
}

.user-is-author #block-views-expired-listing-block::after {
  content: 'The \'Listing Status\' of this job is set to \'Inactive\' or it has already reached its 30-day lifespan. You can still clone this job using the \'Replicate\' button on the bottom left hand corner of the screen (if your current plan allows for more jobs to be posted). Notice that you\'ll need to change the listing status of the cloned job to \'Active\'.';
  font-weight: 400;
  visibility: visible;
  font-size: 1rem;
  line-height: 1.5em;
  text-align: center;
  display: block;
  padding: 0 20px 20px;
  background-color: #fff0f0;
}

.user-is-author .node-job-posting .listing-stats {
  display: block;
}

.page-user-hybridauth .hybridauth-widget-wrapper {
  width: 100%;
  max-width: 100%;
}

.page-user-hybridauth main table,
.page-user-saved-searches main table,
.page-user-edit main table,
.page-user-cases main table,
.page-user main table {
  max-width: 800px;
  margin: auto;
  width: 100%;
  position: relative;
}

.page-user-hybridauth main table .empty.message,
.page-user-saved-searches main table .empty.message,
.page-user-edit main table .empty.message,
.page-user-cases main table .empty.message,
.page-user main table .empty.message {
  text-align: center;
  background: #fff0f0;
  padding: 20px;
  margin: 0 auto;
  max-width: 500px;
}

.page-user-hybridauth main table th,
.page-user-saved-searches main table th,
.page-user-edit main table th,
.page-user-cases main table th,
.page-user main table th {
  background: #f3f3f3;
  border: 1px solid white;
  padding: 5px;
}

@media all and (max-width: 600px) {
  .page-user-hybridauth main table,
  .page-user-saved-searches main table,
  .page-user-edit main table,
  .page-user-cases main table,
  .page-user main table {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    display: block;
    overflow-x: auto;
    background: #cccccc;
    padding: 10px;
  }
  .page-user-hybridauth main table thead,
  .page-user-hybridauth main table tbody,
  .page-user-saved-searches main table thead,
  .page-user-saved-searches main table tbody,
  .page-user-edit main table thead,
  .page-user-edit main table tbody,
  .page-user-cases main table thead,
  .page-user-cases main table tbody,
  .page-user main table thead,
  .page-user main table tbody {
    background: white;
  }
  .page-user-hybridauth main table th,
  .page-user-saved-searches main table th,
  .page-user-edit main table th,
  .page-user-cases main table th,
  .page-user main table th {
    white-space: nowrap;
    padding: 5px 20px;
  }
  .page-user-hybridauth main table td,
  .page-user-saved-searches main table td,
  .page-user-edit main table td,
  .page-user-cases main table td,
  .page-user main table td {
    padding: 0 5px;
  }
}

.page-user-hybridauth #block-views-user-widgets-block,
.page-user-hybridauth #block-views-user-widgets-block-1,
.page-user-hybridauth #block-views-user-widgets-block-2,
.page-user-hybridauth #block-views-user-widgets-block-3,
.page-user-hybridauth #block-views-user-widgets-block-4,
.page-user-hybridauth #block-views-user-widgets-block-5,
.page-user-saved-searches #block-views-user-widgets-block,
.page-user-saved-searches #block-views-user-widgets-block-1,
.page-user-saved-searches #block-views-user-widgets-block-2,
.page-user-saved-searches #block-views-user-widgets-block-3,
.page-user-saved-searches #block-views-user-widgets-block-4,
.page-user-saved-searches #block-views-user-widgets-block-5,
.page-user-edit #block-views-user-widgets-block,
.page-user-edit #block-views-user-widgets-block-1,
.page-user-edit #block-views-user-widgets-block-2,
.page-user-edit #block-views-user-widgets-block-3,
.page-user-edit #block-views-user-widgets-block-4,
.page-user-edit #block-views-user-widgets-block-5,
.page-user-cases #block-views-user-widgets-block,
.page-user-cases #block-views-user-widgets-block-1,
.page-user-cases #block-views-user-widgets-block-2,
.page-user-cases #block-views-user-widgets-block-3,
.page-user-cases #block-views-user-widgets-block-4,
.page-user-cases #block-views-user-widgets-block-5 {
  display: none;
}

.page-user-hybridauth ul.tabs,
.page-user-saved-searches ul.tabs,
.page-user-edit ul.tabs,
.page-user-cases ul.tabs {
  display: block;
}

.page-user-hybridauth main,
.page-user-saved-searches main,
.page-user-edit main,
.page-user-cases main {
  text-align: left;
}

.page-user main table {
  max-width: 100%;
}

.page-user-hybridauth h1 {
  font-size: 0;
  line-height: 0;
}

.page-user-hybridauth h1::before {
  font-size: 32px;
  line-height: 1.3em;
  content: 'My Social Logins';
  padding-bottom: 20px;
}

.page-user-hybridauth h1::after {
  line-height: 1.3em;
}

.page-user-saved-searches h1 {
  font-size: 0;
  line-height: 0;
}

.page-user-saved-searches h1::before {
  font-size: 32px;
  line-height: 1.3em;
  content: 'My Job Alerts';
  padding-bottom: 20px;
}

#search-api-saved-searches-search-disable-form,
#search-api-saved-searches-search-delete-form {
  max-width: 600px;
  margin: auto;
  text-align: center;
  background: #f3f3f3;
  padding: 20px;
}

.page-search-api-saved-search-activate main p {
  max-width: 600px;
  padding: 20px;
  background: #f3f3f3;
  margin: auto;
}

.page-search-api-saved-search-activate main h1 {
  font-size: 0;
  line-height: 0;
}

.page-search-api-saved-search-activate main h1::after {
  font-size: 32px;
  line-height: 1.2em;
  content: 'Your job alert has been activated.';
}

html {
  background: #2dbfcd;
}

body {
  background: white;
}

.node-webform {
  background: #f3f3f3;
  padding: 20px;
}

.webform-component--url {
  display: none;
}

.logged-in .webform-client-form .webform-component--civicrm-1-contact-1-contact-first-name,
.logged-in .webform-client-form .webform-component--civicrm-1-contact-1-contact-last-name,
.logged-in .webform-client-form .webform-component--civicrm-1-contact-1-email-email,
.logged-in .webform-client-form .webform-component--url {
  display: none;
}

.page-user-cases-activities h1::before {
  content: 'Support Ticket';
  font-size: .875rem;
  line-height: 1em;
  display: block;
  font-weight: 400;
  color: #cccccc;
}

.page-user-cases-activities .view-my-support-tickets {
  max-width: 700px;
  margin: auto;
}

.page-user-cases-activities .view-my-support-tickets .view-header {
  background: #f3f3f3;
  padding: 20px;
  text-align: center;
}

.page-user-cases-activities .view-my-support-tickets .view-header span {
  display: inline-block;
  margin: 1px;
  padding: 5px;
  background: white;
}

.page-user-cases-activities .view-my-support-tickets .view-footer {
  text-align: center;
  margin: 10px auto;
}

.page-user-cases-activities .view-my-support-tickets .views-row {
  padding: 20px;
  border: 1px solid #f3f3f3;
}

.page-user-cases-activities .view-my-support-tickets .views-row .views-field-activity-date-time {
  float: right;
  color: #999999;
}

.page-user-cases-activities .view-my-support-tickets .views-row .source {
  float: left;
  color: #999999;
}

.page-user-cases-activities .view-my-support-tickets .views-row .views-field-details {
  margin: 0;
}

.page-user-cases-activities .view-my-support-tickets .views-row .views-field-details::before {
  content: '';
  width: 100%;
  display: table;
  clear: both;
}

.page-user-cases-activities .view-my-support-tickets .views-row.views-row-even {
  background: #f3f3f3;
}

#block-webform-client-block-119 {
  max-width: 700px;
  margin: 30px auto;
  padding: 20px;
  background: #f3f3f3;
}

#block-webform-client-block-119 textarea {
  border: 0;
}

.page-user .region-header .block .signin {
  display: inline-block;
}

.page-nonprofits-hiring-now .layout-center {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.page-nonprofits-hiring-now .layout-center .layout-3col {
  margin: 0;
  padding: 0;
}

.page-nonprofits-hiring-now .layout-center .layout-3col .layout-3col__full {
  padding: 0;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content {
  display: block;
  text-align: center;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row {
  max-width: 400px;
  margin: 5px;
  display: inline-block;
  width: 90%;
  vertical-align: top;
}

@media all and (min-width: 420px) {
  .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row {
    width: 100%;
  }
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover {
  height: 100%;
  min-height: 120px;
  color: black;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  display: block;
  height: auto;
  font-size: 1rem;
  line-height: 1.3rem;
  font-family: inherit;
  color: #999999;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover::before {
  display: none;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a h3,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link h3,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited h3,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover h3 {
  color: black;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.2em;
  font-weight: 400;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a span,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link span,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited span,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover span {
  display: block;
  margin-bottom: 3px;
  font-size: .875rem;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .location,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .location,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .location,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .location {
  display: inline;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .label,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .label,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .label,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .label {
  color: black;
  display: inline;
  margin-right: 5px;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo {
  background: white;
  font: 0/0 a;
  float: left;
  width: 70px;
  display: block;
  height: 70px;
  position: relative;
  border: 1px solid #f3f3f3;
  margin: 0 10px 5px 0;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .logo-img,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .logo-img,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .logo-img,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .logo-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -1px;
  left: -1px;
  color: white;
  text-align: center;
  background: #e96803;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(odd),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(odd),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(odd),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(odd) {
  background: #e96803;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(even),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(even),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(even),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(even) {
  background: #2dbfcd;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(3), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(6), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(9), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(12), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(15), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(18), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(21), .page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo .org-initial:nth-of-type(24),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(3),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(6),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(9),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(12),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(15),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(18),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(21),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo .org-initial:nth-of-type(24),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(3),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(6),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(9),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(12),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(15),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(18),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(21),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo .org-initial:nth-of-type(24),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(3),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(6),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(9),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(12),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(15),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(18),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(21),
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo .org-initial:nth-of-type(24) {
  background: #fe9c20;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a .logo::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:link .logo::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:visited .logo::before,
.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover .logo::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover {
  border-color: #2ab8c6;
}

.page-nonprofits-hiring-now .view-nonprofits-hiring-now .view-content .views-row a:hover h3 {
  color: #2ab8c6;
}

#block-views-exp-help-center-page {
  text-align: center;
  padding: 70px 20px 20px;
  background-image: url("../images/customerservice.jpg");
  background-size: 190% auto;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-size: 1.1rem;
}

#block-views-exp-help-center-page .block__title {
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 24px;
}

#block-views-exp-help-center-page .views-exposed-widgets {
  max-width: 400px;
  margin: auto;
}

@media all and (min-width: 480px) {
  #block-views-exp-help-center-page {
    background-size: 160% auto;
    padding: 90px 20px 20px;
  }
}

@media all and (min-width: 600px) {
  #block-views-exp-help-center-page {
    padding: 80px 20px 120px;
  }
}

@media all and (min-width: 880px) {
  #block-views-exp-help-center-page {
    background-size: 150% auto;
    padding: 80px 20px 120px;
  }
  #block-views-exp-help-center-page .views-exposed-widgets {
    max-width: 500px;
  }
  #block-views-exp-help-center-page .block__title {
    line-height: 2.25rem;
    margin-top: 1.5rem;
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
}

@media all and (min-width: 1000px) {
  #block-views-exp-help-center-page {
    background-size: 130% auto;
  }
}

@media all and (min-width: 1200px) {
  #block-views-exp-help-center-page {
    background-size: 120% auto;
  }
}

@media all and (min-width: 1400px) {
  #block-views-exp-help-center-page {
    background-size: 100% auto;
  }
}

#block-views-exp-help-center-page #edit-keys-wrapper {
  width: 100%;
  padding: 0;
  max-width: calc( 100% - 51px);
  float: left;
}

#block-views-exp-help-center-page #edit-keys-wrapper input {
  line-height: 50px;
  width: 100%;
  border: 0;
  height: 50px;
  padding-left: 15px;
}

#block-views-exp-help-center-page .views-submit-button {
  padding: 0;
  float: left;
}

#block-views-exp-help-center-page .views-submit-button input {
  margin: 0;
  background-image: url("../images/search.png");
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
  line-height: 50px;
  text-indent: -999em;
  width: 50px;
}

.view-help-center {
  max-width: 740px;
  margin: auto;
}

.view-help-center .views-row {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
}

#block-block-12 {
  position: fixed;
  right: 10px;
  top: 100px;
  text-align: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  color: #999999;
  font-size: .875rem;
  line-height: 1.2rem;
  border: 1px solid #cccccc;
  display: none;
}

@media all and (min-width: 1000px) {
  #block-block-12 {
    display: inline-block;
  }
}

.admin-menu #block-block-12 {
  top: 129px;
}

.page-user main .block .views-row {
  max-width: 320px;
  width: 100%;
}

.view-user-profile .purpose {
  color: #999999;
  font-size: .875rem;
  margin-bottom: 10px;
  display: none;
}

.role-Employer .view-user-profile .purpose.employer,
.role-Job-Seeker .view-user-profile .purpose.jobseeker {
  display: block;
}

.role-Job-Seeker.page-user #block-views-user-widgets-block-4 {
  display: none;
}

#block-views-user-profile-block-1 .view-content,
#block-views-user-profile-block-2 .view-content,
#block-views-user-profile-block-4 .view-content {
  padding: 0;
  background: none;
}

#block-views-user-profile-block-1 .view-content .views-row,
#block-views-user-profile-block-2 .view-content .views-row,
#block-views-user-profile-block-4 .view-content .views-row {
  max-width: 100%;
}

#block-views-user-profile-block-1 .view-content .views-row .messages,
#block-views-user-profile-block-2 .view-content .views-row .messages,
#block-views-user-profile-block-4 .view-content .views-row .messages {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

#block-views-user-profile-block-1 .view-content .views-row .button,
#block-views-user-profile-block-1 .view-content .views-row .button:link,
#block-views-user-profile-block-1 .view-content .views-row .button:visited,
#block-views-user-profile-block-1 .view-content .views-row .button:hover,
#block-views-user-profile-block-2 .view-content .views-row .button,
#block-views-user-profile-block-2 .view-content .views-row .button:link,
#block-views-user-profile-block-2 .view-content .views-row .button:visited,
#block-views-user-profile-block-2 .view-content .views-row .button:hover,
#block-views-user-profile-block-4 .view-content .views-row .button,
#block-views-user-profile-block-4 .view-content .views-row .button:link,
#block-views-user-profile-block-4 .view-content .views-row .button:visited,
#block-views-user-profile-block-4 .view-content .views-row .button:hover {
  background: #e96803;
  display: inline-block;
  color: white;
  padding: 5px 15px;
}

#block-views-user-profile-block-1 .view-content .views-row .button:hover,
#block-views-user-profile-block-2 .view-content .views-row .button:hover,
#block-views-user-profile-block-4 .view-content .views-row .button:hover {
  background: #2dbfcd;
}

.webform-confirmation {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

#block-views-nonprofits-hiring-now-block .more-link,
#block-views-nonprofits-hiring-now-block-2 .more-link {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#block-block-6,
.node-3,
.node-218,
.node-2674,
.node-3852 {
  padding: 0 20px 20px;
  background: #f3f3f3;
}

#block-block-6 .block__title,
.node-3 .block__title,
.node-218 .block__title,
.node-2674 .block__title,
.node-3852 .block__title {
  background: none;
  margin-bottom: 0;
}

@media all and (min-width: 900px) {
  #block-block-6 .block-6-text,
  .node-3 .block-6-text,
  .node-218 .block-6-text,
  .node-2674 .block-6-text,
  .node-3852 .block-6-text {
    padding-top: 30px;
  }
}

#block-block-6 .recruiter-container,
.node-3 .recruiter-container,
.node-218 .recruiter-container,
.node-2674 .recruiter-container,
.node-3852 .recruiter-container {
  background: none;
  margin-top: 0;
}

#block-block-6 .inner-block-6,
.node-3 .inner-block-6,
.node-218 .inner-block-6,
.node-2674 .inner-block-6,
.node-3852 .inner-block-6 {
  background: white;
  padding: 20px;
  max-width: 1100px;
}

#block-block-6 .inner-block-6 h4,
.node-3 .inner-block-6 h4,
.node-218 .inner-block-6 h4,
.node-2674 .inner-block-6 h4,
.node-3852 .inner-block-6 h4 {
  text-align: center;
}

#block-block-6 .inner-block-6::after,
.node-3 .inner-block-6::after,
.node-218 .inner-block-6::after,
.node-2674 .inner-block-6::after,
.node-3852 .inner-block-6::after {
  display: table;
  content: '';
  width: 100%;
  clear: both;
}

#block-block-6 .recruiterdash .buttons,
.node-3 .recruiterdash .buttons,
.node-218 .recruiterdash .buttons,
.node-2674 .recruiterdash .buttons,
.node-3852 .recruiterdash .buttons {
  text-align: center;
}

#block-block-7 .footer-text .address-2::after {
  display: none;
}

#block-block-7 .footer-text .text-links {
  display: block;
}

.page-node-85.page-node-done main h1 {
  visibility: hidden;
  line-height: 0;
  font-size: 0;
}

.page-node-85.page-node-done main h1::after {
  visibility: visible;
  line-height: 1em;
  font-size: 2rem;
  content: 'Thank You';
}

.page-node-85.page-node-done main .links {
  text-align: center;
}

.pager--infinite-scroll {
  float: left;
  width: 100px;
  clear: left;
}

.filtertags {
  max-width: 300px;
  margin: auto;
}

@media all and (min-width: 375px) {
  .filtertags {
    max-width: 350px;
  }
}

@media all and (min-width: 580px) {
  .filtertags {
    max-width: 550px;
  }
}

@media all and (min-width: 700px) {
  .filtertags {
    max-width: 650px;
  }
}

.filtertags .filter,
.filtertags .filter:link,
.filtertags .filter:visited,
.filtertags .filter:hover {
  color: black;
  background: #f3f3f3;
  padding: 2px 8px;
  display: inline-block;
  margin: 2px 1px;
  cursor: pointer;
  font-size: .875rem;
}

.filtertags .filter::after,
.filtertags .filter:link::after,
.filtertags .filter:visited::after,
.filtertags .filter:hover::after {
  content: 'X';
  padding-left: 3px;
  color: #999999;
}

body .node-form .form-wrapper.form-field-type-file .form-type-managed-file {
  margin-bottom: 0;
}

.node .fieldset-wrapper .field-type-file .file a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  word-break: break-all;
  max-width: calc( 100% - 18px);
  display: inline-block;
}

.node-webform.node-195 {
  max-width: 640px;
}

.node-webform.node-195 .webform-client-form .webform-component-textfield,
.node-webform.node-195 .webform-client-form .webform-component-email {
  display: inline-block;
  max-width: 295px;
  width: 100%;
  text-align: left;
  margin: 1rem 0;
}

.node-webform.node-195 .webform-client-form .webform-component-textfield input,
.node-webform.node-195 .webform-client-form .webform-component-email input {
  width: 100%;
}

.node-organization-form .form-wrapper {
  margin: 1.7rem 0;
}

.node-organization-form .form-field-name-field-logo .description {
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}

.node-organization-form .form-field-name-field-logo .description::after {
  content: 'Minimum size is 200px by 200px.';
  visibility: visible;
  font-size: .875rem;
  line-height: 1.2rem;
  padding-top: 5px;
}

.page-user-register-trial #edit-field-linkedin,
.page-user-register-purchase #edit-field-linkedin {
  display: none;
}

.page-user-register-trial .hybridauth-widget-wrapper,
.page-user-register-purchase .hybridauth-widget-wrapper {
  display: none;
}

.page-user-register-trial #user-register-form,
.page-user-register-purchase #user-register-form {
  padding-top: 5px;
}

.page-user-register-trial h1::after,
.page-user-register-purchase h1::after {
  content: 'A no-commitment 14-day free trial will be automatically activated for you.';
  font-size: 1rem;
  display: block;
  padding-top: 0;
  font-weight: 400;
}

.page-user-register-purchase h1::after {
  display: none;
}

.node-job-posting .field-name-field-city-state .field-items .field-item::before {
  display: none;
}

.node-book .field-name-body h2,
.node-page .field-name-body h2 {
  color: #e96803;
  margin: 1.5rem 0 .5rem;
}

.node-book .field-name-body h3,
.node-page .field-name-body h3 {
  margin: 1.5rem 0 .5rem;
}

.node-book .field-name-body p,
.node-page .field-name-body p {
  margin: .5rem 0 1.5rem;
}

.node-16,
.node-3,
.node-218,
.node-378,
.node-2674,
.node-3852 {
  padding: 0;
}

.node-16 .field-name-body h2,
.node-3 .field-name-body h2,
.node-218 .field-name-body h2,
.node-378 .field-name-body h2,
.node-2674 .field-name-body h2,
.node-3852 .field-name-body h2 {
  color: white;
  margin: 0 auto 1rem;
}

.node-16 .features.anonymous h2,
.node-3 .features.anonymous h2,
.node-218 .features.anonymous h2,
.node-378 .features.anonymous h2,
.node-2674 .features.anonymous h2,
.node-3852 .features.anonymous h2 {
  background: none;
  color: black;
}

.node-16 #block-block-6 h2,
.node-3 #block-block-6 h2,
.node-218 #block-block-6 h2,
.node-378 #block-block-6 h2,
.node-2674 #block-block-6 h2,
.node-3852 #block-block-6 h2 {
  color: black;
}

#block-block-13 {
  margin: auto;
  margin-top: 30px;
  max-width: 700px;
  padding: 20px;
  background: #f9f9f9;
}

#block-block-13 .block__title {
  color: black;
}

#block-block-13 ul {
  text-align: left;
}

#block-block-13 ul li {
  margin: .5rem 0;
}

.node .book-navigation .page-links {
  display: none;
}

#search-api-saved-searches-save-form::before {
  content: 'Notifications will be based on the search you just performed';
  font-size: 1rem;
  color: black;
  padding-top: 20px;
  font-style: italic;
  display: block;
  max-width: 300px;
  margin: auto;
}

.layout-3col__left-sidebar #block-book-explorer-book-explorer {
  background: #2dbfcd;
  padding: 20px;
}

.layout-3col__left-sidebar #block-book-explorer-book-explorer .block__title {
  margin: 0 0 .5rem;
}

.layout-3col__left-sidebar #block-book-explorer-book-explorer .book-title,
.layout-3col__left-sidebar #block-book-explorer-book-explorer .book-title:link,
.layout-3col__left-sidebar #block-book-explorer-book-explorer .book-title:visited .book-title:hover {
  color: white;
  font-size: 24px;
}

.layout-3col__left-sidebar #block-book-explorer-book-explorer a,
.layout-3col__left-sidebar #block-book-explorer-book-explorer a:link,
.layout-3col__left-sidebar #block-book-explorer-book-explorer a:visited,
.layout-3col__left-sidebar #block-book-explorer-book-explorer a:hover {
  color: white;
}

.layout-3col__left-sidebar #block-book-explorer-book-explorer .menu__link.active,
.layout-3col__left-sidebar #block-book-explorer-book-explorer .menu__link.active:link,
.layout-3col__left-sidebar #block-book-explorer-book-explorer .menu__link.active:visited,
.layout-3col__left-sidebar #block-book-explorer-book-explorer .menu__link.active:hover {
  color: black;
}

.footer-text-2 .text-links {
  padding-top: 10px;
}

.book-explorer ul.menu li {
  background: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 5px;
  padding-bottom: 5px;
}

.book-explorer .book-explorer-toggle {
  background: none;
  position: relative;
  width: 0;
  height: 1.5rem;
  margin-top: 7px;
}

.book-explorer .book-explorer-toggle::before {
  content: '\f055';
  font-family: FontAwesome;
  display: block;
  width: 16px;
  font-size: 16px;
  color: white;
}

.book-explorer .book-explorer-toggle.book-explorer-expanded::before {
  content: '\f146';
}

.view-company-info-for-job h2.title {
  padding-top: 1.5rem;
  margin-bottom: 0;
}

.page-node-3 .region-bottom,
.page-node-3852 .region-bottom {
  margin-top: 0;
}

.page-node-3 .top-right.employers,
.page-node-3852 .top-right.employers {
  display: none;
}

#block-views-nonprofits-hiring-now-block-1,
#block-views-nonprofits-hiring-now-block-2 {
  max-width: 1100px;
  margin: auto;
}

#block-views-nonprofits-hiring-now-block-1 .view-content,
#block-views-nonprofits-hiring-now-block-2 .view-content {
  text-align: center;
}

#block-views-nonprofits-hiring-now-block-1 .views-row,
#block-views-nonprofits-hiring-now-block-2 .views-row {
  display: inline-block;
  width: 90px;
  margin: 14px;
  height: 100px;
  vertical-align: top;
  font: 0/0 a;
  position: relative;
  overflow: hidden;
}

#block-views-nonprofits-hiring-now-block-1 .views-row::before,
#block-views-nonprofits-hiring-now-block-2 .views-row::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

#block-views-nonprofits-hiring-now-block-1 .views-row img,
#block-views-nonprofits-hiring-now-block-2 .views-row img {
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 Arial sans-serif;
}

.page-node-3 #block-views-testimonial-block-1,
.page-node-3852 #block-views-testimonial-block-1 {
  padding-top: 0;
}

.page-node-3 #block-views-testimonial-block-1 .view-testimonial,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial {
  color: black;
}

.page-node-3 #block-views-testimonial-block-1 .view-testimonial .view-content,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial .view-content {
  background: #f3f3f3;
}

.page-node-3 #block-views-testimonial-block-1 .view-testimonial .view-content .credit .jobtitle::after,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial .view-content .credit .jobtitle::after {
  content: ',';
  padding-right: 5px;
}

.page-node-3 #block-views-testimonial-block-1 .view-testimonial .view-content .quote,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial .view-content .quote {
  color: black;
}

.page-node-3 #block-views-testimonial-block-1 .view-testimonial .view-content .quote::before, .page-node-3 #block-views-testimonial-block-1 .view-testimonial .view-content .quote::after,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial .view-content .quote::before,
.page-node-3852 #block-views-testimonial-block-1 .view-testimonial .view-content .quote::after {
  color: #e96803;
}

#block-block-14 {
  padding-bottom: 0;
}

#block-block-14 .button {
  transform: scale(1.1);
  padding: 10px 25px;
  margin-bottom: 20px;
}

@media all and (min-width: 1100px) {
  body .node-3 #block-block-6 .inner-block-6 .block-6-text,
  body .node-2674 #block-block-6 .inner-block-6 .block-6-text,
  body .node-3852 #block-block-6 .inner-block-6 .block-6-text {
    padding-left: 50px;
    padding-right: 30px;
  }
  body .node-3 #block-block-6 .inner-block-6 .recruiterdash,
  body .node-2674 #block-block-6 .inner-block-6 .recruiterdash,
  body .node-3852 #block-block-6 .inner-block-6 .recruiterdash {
    padding-right: 50px;
    padding-left: 30px;
  }
}

body .node-3 li,
body .node-2674 li,
body .node-3852 li {
  margin: 8px 0;
  list-style: none;
}

body .node-3 li::before,
body .node-2674 li::before,
body .node-3852 li::before {
  content: '\f00c';
  color: #e96803;
  padding-right: 10px;
  font-family: 'FontAwesome';
  margin-left: -27px;
}

body .node-3 .intro-text,
body .node-2674 .intro-text,
body .node-3852 .intro-text {
  font-size: 1.2rem;
  line-height: 1.8em;
  max-width: 840px;
  font-weight: 300;
  text-align: center;
  padding: 30px 20px 0;
}

body .node-3 .intro-text span,
body .node-2674 .intro-text span,
body .node-3852 .intro-text span {
  padding-right: 5px;
}

@media all and (min-width: 900px) {
  body .node-3 .intro-text span,
  body .node-2674 .intro-text span,
  body .node-3852 .intro-text span {
    display: block;
    padding: 0;
    margin-bottom: .5rem;
  }
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .price_set-section .additional_jobs-section {
  margin: 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .price_set-section .additional_jobs-section .label {
  margin: 5px 0 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .price_set-section .additional_jobs-section label {
  margin-top: 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .price_set-section .section-pricesetTotal {
  margin: 10px 0 20px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .additional_jobs-content {
  padding-top: 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .payment_options-group .payment_processor-section .label {
  display: none;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group {
  padding-top: 10px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section .creditcard {
  max-width: 180px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section #cvv2 {
  width: 70px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section .crm-error {
  background: none;
  color: black;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section input.error,
#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section .select2-container.error {
  border: 1px solid red;
  background: #FBE3E4;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section .crm-marker {
  display: none;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .crm-section .content .crm-error {
  display: block;
  width: 100%;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .credit_card_type-section {
  width: 120px;
  padding-top: 20px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .credit_card_type-section .crm-credit_card_type-icons a {
  margin: 3px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .credit_card_number-section {
  margin-right: 10px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .cvv2-section {
  width: 130px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .cvv2-section .description {
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_mode-group .cvv2-section .description::after {
  background: url("../images/ccv2.jpg") no-repeat left top;
  width: 100px;
  height: 40px;
  display: block;
  background-size: contain;
  content: '';
  visibility: visible;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section {
  display: inline-block;
  vertical-align: top;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section .content .crm-error {
  display: block;
  width: 100%;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section .crm-error {
  background: none;
  color: black;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section input.error,
#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section .select2-container.error {
  border: 1px solid red;
  background: #FBE3E4;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .crm-section .crm-marker {
  display: none;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_middle_name-section,
#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_country_id-5-section {
  display: none;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_first_name-section .label label,
#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_last_name-section .label label {
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_first_name-section .label label::after,
#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_last_name-section .label label::after {
  content: 'First Name';
  visibility: visible;
  font-size: 1rem;
  line-height: 1.5rem;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_name_address-group .billing_last_name-section .label label::after {
  content: 'Last Name';
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .crm-form-submit {
  transform: scale(1.1);
  padding: 10px 25px;
}

#crm-container.crm-public .CRM_Contribute_Form_Contribution_Main .billing_state_province_id-5-section {
  width: 100%;
  max-width: 300px;
}

#crm-container.crm-public .crm-contribution-page-id-2 #allow_auto_renew {
  display: none;
}

#crm-container.crm-public .crm-contribution-page-id-2 .membership-group .membership_amount-section {
  margin-bottom: 20px;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block {
  padding-bottom: 20px;
  background: #f3f3f3;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .billing_name_address-group,
#crm-container.crm-public .crm-contribution-thankyou-form-block .contributor_email-group {
  padding: 0 25px 5px;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .help {
  display: block;
  margin: 0;
  border-radius: 0;
  max-width: 700px;
  padding-top: 1px;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .help p,
#crm-container.crm-public .crm-contribution-thankyou-form-block .help div {
  margin: 1rem 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block #thankyou_text {
  margin-bottom: 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block #thankyou_text .help {
  padding-bottom: 0;
  padding-top: 20px;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block #thankyou_text .help p {
  margin-bottom: 0;
}

#crm-container.crm-public .crm-contribution-thankyou-form-block .amount_display-group {
  padding-bottom: 10px;
}

#colorbox #user-login,
#colorbox #user-register-form,
#colorbox #user-pass {
  padding-bottom: 0;
}

.view-nonprofit-jobs-index .view-header .button {
  background: #fe9c20;
}

.page-nonprofit-jobs-search .view-nonprofit-jobs-index .views-row .easy-apply-1,
.page-nonprofit-jobs.page-views .local-jobs .views-row .easy-apply-1 {
  color: #999999;
}

#block-views-expired-listing-block .attachment .view .view-header h3,
#block-views-expired-listing-block .attachment .view .view-footer {
  text-align: center;
}

#block-views-expired-listing-block .attachment .view .view-footer {
  margin-top: 20px;
}

#block-views-expired-listing-block .attachment .view .view-content {
  text-align: center;
}

#block-views-expired-listing-block .attachment .view .view-content .views-row {
  display: inline-block;
  margin: 4px 2px;
  text-align: left;
  width: 100%;
  max-width: 300px;
}

.node-form #edit-field-cover-photo-template .form-type-radio {
  padding: 1px;
  border-radius: 0px;
  width: 220px;
  margin: 0 1px;
}

.page-user main .block .views-row .messages {
  width: 100%;
  max-width: 650px;
}

.page-user main .block .views-row .messages .button,
.page-user main .block .views-row .messages .button:link,
.page-user main .block .views-row .messages .button:visited,
.page-user main .block .views-row .messages .button:hover {
  padding: 5px 15px;
  background: #e96803;
  color: white;
  display: inline-block;
}

.page-user main .block .views-row .messages .button:hover {
  background: #2dbfcd;
}

.page-user main .block .view-user-profile.view-display-id-block_3 .views-row {
  max-width: 100%;
}

#replicate-ui-confirm {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.role-Employer.page-user #block-views-exp-nonprofit-jobs-index-page {
  display: none;
}

#block-views-contact-from-help-block {
  padding-top: 0;
}

.page-taxonomy-term.vocabulary-job_function main h1 {
  display: block;
  margin-bottom: 1.5rem;
}

.page-taxonomy-term.vocabulary-job_function main h1::before {
  content: 'Nonprofit';
  padding-right: 7px;
}

.page-taxonomy-term.vocabulary-job_function main h1::after {
  content: 'Jobs';
  padding-left: 7px;
}

.page-taxonomy-term.vocabulary-area_of_focus h1::after {
  content: 'Jobs';
  padding-left: 7px;
}

.node-job-posting .field-name-field-skills .field-items,
.node-job-posting .field-name-field-requirements-or-qualificat .field-items {
  padding-left: 30px;
}

.node-job-posting .field-name-field-skills .field-items .field-item,
.node-job-posting .field-name-field-requirements-or-qualificat .field-items .field-item {
  margin: 10px 0;
}

.node-job-posting .field-name-field-skills .field-items .field-item::before,
.node-job-posting .field-name-field-requirements-or-qualificat .field-items .field-item::before {
  margin-left: -20px;
  padding-left: 0;
  padding-right: 5px;
  font-family: FontAwesome;
  content: '\f00c';
}

.node-job-posting .field-name-field-how-to-apply {
  overflow: hidden;
  max-width: 100%;
}

.field .field-item ul,
.field .field-item ul li {
  list-style: none;
}

.field .field-item ul li {
  margin: 10px 0;
}

.field .field-item ul li::before {
  margin-left: -20px;
  padding-left: 0;
  padding-right: 5px;
  font-family: FontAwesome;
  content: '\f00c';
  color: #e96803;
}

.field .field-item ul li li::before {
  content: '\f1db';
}

@media all and (max-width: 850px) {
  #user-profile-form .form-type-password {
    width: 100%;
    overflow: hidden;
  }
  #user-profile-form .form-type-password .password-strength {
    display: none;
  }
  #user-profile-form .form-type-password .password-confirm {
    margin-top: 0;
  }
}

.node-type-organization #block-block-16 {
  display: none;
}

@media all and (min-width: 1001px) {
  .node-type-organization #block-block-16 {
    display: block;
  }
}

.node-type-organization #block-block-17 {
  background: none;
}

@media all and (min-width: 1001px) {
  .node-type-organization #block-block-17 {
    display: none;
  }
}

.page-nonprofit-jobs-region.page-views h1 {
  margin-top: 90px;
}

.page-nonprofit-jobs-region.page-views .local-jobs .view-filters {
  display: block;
  position: absolute;
  width: 100%;
  top: -150px;
}

.view-job-function-sidebar .views-row .active,
.view-areas-of-focus-sidebar .views-row .active {
  background: #cccccc;
  color: white;
}

.webform-component-civicrm-contact ul.token-input-list {
  border-color: #ddd;
  max-width: 500px;
}

.webform-component-textarea textarea {
  border: 1px solid #ddd;
}

@media all and (min-width: 768px) {
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-contact-first-name,
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-contact-last-name,
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-phone-phone,
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-phone-phone-ext,
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-email-email,
  .node-webform.node-400 .webform-component--civicrm-1-contact-1-contact-job-title {
    display: inline-block;
    max-width: 49%;
    vertical-align: top;
  }
}

#block-block-18,
#block-block-26 {
  padding: 0;
}

#block-block-18 .features h2,
#block-block-26 .features h2 {
  background: none;
}

.page-node-3.role-anonymous-user #block-block-18,
.page-node-3.role-anonymous-user #block-block-26,
.page-node-3852.role-anonymous-user #block-block-18,
.page-node-3852.role-anonymous-user #block-block-26 {
  padding-bottom: 30px;
}

.page-node-3.role-anonymous-user #block-block-18 hr,
.page-node-3.role-anonymous-user #block-block-26 hr,
.page-node-3852.role-anonymous-user #block-block-18 hr,
.page-node-3852.role-anonymous-user #block-block-26 hr {
  display: none;
}

.page-node-3.role-anonymous-user #block-block-18 .prices::before,
.page-node-3.role-anonymous-user #block-block-26 .prices::before,
.page-node-3852.role-anonymous-user #block-block-18 .prices::before,
.page-node-3852.role-anonymous-user #block-block-26 .prices::before {
  height: 50px;
  line-height: 50px;
  background: #999999;
  color: white;
}

#block-block-26 {
  padding-top: 40px;
  background: #e9e9e9;
}

#block-block-26 .prices {
  padding-top: 0;
}

#block-block-26 .prices::before {
  display: none;
}

#block-block-19 {
  padding: 0;
}

.page-node-2674 .employer-header {
  background-image: url(/sites/all/themes/natlnp/images/jobseekers.jpg);
}

@media all and (min-width: 700px) {
  .page-node-2674 .employer-header {
    background-position: center -100px;
  }
}

@media all and (min-width: 800px) {
  .page-node-2674 .employer-header {
    background-position: center -200px;
  }
}

@media all and (min-width: 1000px) {
  .page-node-2674 .employer-header {
    background-position: center -300px;
  }
}

@media all and (min-width: 1150px) {
  .page-node-2674 .employer-header {
    background-position: center -400px;
  }
}

.page-node-2674 #block-block-6 {
  text-align: center;
  padding-bottom: 40px;
}

.page-node-2674 #block-block-6 .block-6-container .inner-block-6,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:link,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:visited,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover {
  display: block;
  color: black;
  vertical-align: top;
  background: white;
  margin-bottom: 10px;
}

.page-node-2674 #block-block-6 .block-6-container .inner-block-6 h4,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:link h4,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:visited h4,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover h4 {
  margin-bottom: .5rem;
}

.page-node-2674 #block-block-6 .block-6-container .inner-block-6 .block-6-text,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:link .block-6-text,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:visited .block-6-text,
.page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover .block-6-text {
  margin-bottom: 10px;
}

.page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover {
  border-color: #2dbfcd;
}

.page-node-2674 #block-block-6 .block-6-container .block-6-text {
  padding-left: 10px;
  padding-top: 10px;
  max-width: 100%;
  float: none;
}

@media all and (min-width: 900px) {
  .page-node-2674 #block-block-6 .block-6-container {
    display: flex;
    align-items: self-start;
    justify-content: space-evenly;
  }
  .page-node-2674 #block-block-6 .block-6-container .inner-block-6,
  .page-node-2674 #block-block-6 .block-6-container .inner-block-6:link,
  .page-node-2674 #block-block-6 .block-6-container .inner-block-6:visited,
  .page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover {
    flex-basis: 30%;
    align-self: stretch;
    margin-bottom: 0;
    margin: 0;
    color: black;
    border: 1px solid white;
  }
  .page-node-2674 #block-block-6 .block-6-container .inner-block-6:hover {
    border-color: #2dbfcd;
  }
}

.page-node-2674 .region-bottom {
  margin-top: 0;
}

.node-article h2 {
  color: #e96803;
}

.node-article .field-name-field-resources-for .field-item {
  display: inline;
}

#block-block-23 {
  margin: 0 auto;
  max-width: 600px;
  background: white;
}

#block-block-23 iframe {
  margin: auto;
}

.vocabulary-resources main h1 {
  display: block;
  margin-bottom: 30px;
}

.vocabulary-resources main h1::before {
  content: 'Resources for';
  margin-right: .4em;
}

.vocabulary-resources main .view-resources-taxonomy .view-content {
  max-width: 100%;
  padding: 0 20px;
}

.vocabulary-resources main .view-resources-taxonomy .view-content a,
.vocabulary-resources main .view-resources-taxonomy .view-content a:link,
.vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover {
  display: block;
  color: black;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

@media all and (min-width: 850px) {
  .vocabulary-resources main .view-resources-taxonomy .view-content a,
  .vocabulary-resources main .view-resources-taxonomy .view-content a:link,
  .vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover {
    text-align: left;
  }
}

.vocabulary-resources main .view-resources-taxonomy .view-content a img,
.vocabulary-resources main .view-resources-taxonomy .view-content a:link img,
.vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover img {
  max-width: 350px;
  width: 100%;
  display: inline-block;
  margin: auto;
  vertical-align: top;
}

.vocabulary-resources main .view-resources-taxonomy .view-content a .resource-content,
.vocabulary-resources main .view-resources-taxonomy .view-content a:link .resource-content,
.vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover .resource-content {
  display: inline-block;
  text-align: left;
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
}

.vocabulary-resources main .view-resources-taxonomy .view-content a .resource-content p,
.vocabulary-resources main .view-resources-taxonomy .view-content a:link .resource-content p,
.vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover .resource-content p {
  margin: 0 0 10px;
}

.vocabulary-resources main .view-resources-taxonomy .view-content a .resource-content h2,
.vocabulary-resources main .view-resources-taxonomy .view-content a:link .resource-content h2,
.vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover .resource-content h2 {
  color: #e96803;
  margin: 0 0 5px;
}

@media all and (min-width: 850px) {
  .vocabulary-resources main .view-resources-taxonomy .view-content a .resource-content,
  .vocabulary-resources main .view-resources-taxonomy .view-content a:link .resource-content,
  .vocabulary-resources main .view-resources-taxonomy .view-content a:visited a:hover .resource-content {
    width: calc( 100% - 380px);
    margin-left: 25px;
    margin-top: 0;
  }
}

.vocabulary-resources main .view-resources-taxonomy .view-content a:hover {
  border-color: #2dbfcd;
}

.vocabulary-resources main .view-resources-taxonomy .view-content a:hover .resource-content h2 {
  color: #2dbfcd;
}

#block-views-resources-taxonomy-block-1,
#block-views-resources-taxonomy-block-2 {
  background: #f3f3f3;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy {
  max-width: 1200px;
  margin: auto;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .view-footer,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .view-footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row {
  margin: 0 auto 20px;
  max-width: 400px;
  background: white;
  border: 1px solid #f3f3f3;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:link,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:visited,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:hover,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:link,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:visited,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:hover {
  display: block;
  height: 100%;
  color: black;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a h2,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:link h2,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:visited h2,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:hover h2,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a h2,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:link h2,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:visited h2,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:hover h2 {
  color: #e96803;
  margin: 10px 0 5px;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a p,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:link p,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:visited p,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:hover p,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a p,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:link p,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:visited p,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:hover p {
  margin: 0 0 10px;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a .resource-content,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:link .resource-content,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:visited .resource-content,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:hover .resource-content,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a .resource-content,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:link .resource-content,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:visited .resource-content,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:hover .resource-content {
  padding: 0 10px;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a img,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:link img,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:visited img,
#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row a:hover img,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a img,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:link img,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:visited img,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row a:hover img {
  width: 100%;
  display: block;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row:hover,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row:hover {
  border-color: #2dbfcd;
}

#block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row:hover h2,
#block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row:hover h2 {
  color: #2dbfcd;
}

@media all and (min-width: 900px) {
  #block-views-resources-taxonomy-block-1 .view-resources-taxonomy .view-content,
  #block-views-resources-taxonomy-block-2 .view-resources-taxonomy .view-content {
    display: flex;
    align-items: self-start;
    justify-content: space-evenly;
  }
  #block-views-resources-taxonomy-block-1 .view-resources-taxonomy .views-row,
  #block-views-resources-taxonomy-block-2 .view-resources-taxonomy .views-row {
    flex-basis: 30%;
    align-self: stretch;
    margin: 0;
    max-width: 30%;
  }
}

.node-type-job-posting #block-block-16 {
  padding: 0;
}

.node-type-job-posting #block-block-16 p.centered {
  margin: 0 auto;
}

.node-type-job-posting #block-views-exp-nonprofit-jobs-index-page {
  top: 20px;
  margin-top: 0;
}

.node-type-job-posting #block-views-exp-nonprofit-jobs-index-page h2.block__title {
  display: none;
}

.node-type-job-posting .layout-center {
  margin-top: 160px;
}

.node-type-job-posting input#edit-keyword.form-text {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.node-type-job-posting input.form-text#edit-location {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media all and (min-width: 580px) {
  .node-type-job-posting .layout-center {
    margin-top: 100px;
  }
  .node-type-job-posting #block-views-exp-nonprofit-jobs-index-page input#edit-keyword.form-text {
    border-right: 0;
    border-bottom: 1px solid #ccc;
  }
  .node-type-job-posting #block-views-exp-nonprofit-jobs-index-page input.form-text#edit-location {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }
}

.view-company-info-for-job .field-name-field-website {
  margin-right: 5px;
}

.view-company-info-for-job .field-name-field-website .field-label,
.view-company-info-for-job .field-name-field-website .field-items {
  display: inline-block;
  padding-left: 0;
}

.view-company-info-for-job .field-name-field-website .field-label {
  padding-right: 10px;
}

.view-company-info-for-job .field-name-field-website .field-items a {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.stepsgraph {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 10px 40px;
}

.stepsgraph .step {
  background: #2dbfcd;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: inline-block;
  margin: 10px;
  position: relative;
  vertical-align: top;
}

.stepsgraph .step a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
}

.stepsgraph .step::before {
  content: '1';
  line-height: 120px;
  font-size: 120px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -60px;
  left: 0;
  display: block;
  width: 100%;
  height: 120px;
  color: white;
  opacity: .13;
}

.stepsgraph .step.graph2::before {
  content: '2';
}

.stepsgraph .step.graph3::before {
  content: '3';
}

.stepsgraph .step.graph4::before {
  content: '4';
}

.stepsgraph .step span {
  line-height: 1em;
  font-size: 1.3em;
  padding: 0 5px;
  font-weight: 700;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
  display: inline-block;
  color: white;
}

#edit-field-trial {
  display: none;
}

#block-block-28 {
  position: absolute;
  top: 120px;
  width: 100%;
}

@media all and (min-width: 580px) {
  #block-block-28 {
    top: 70px;
  }
}
